import React from 'react'
import { Typography, Paper } from '@material-ui/core'
import NumberFormat from 'react-number-format'

export default function ReportCard(props) {
    return (
        <Paper style={{ padding: 10, }}>
            <Typography component='div' variant='h6' align='center'>
                <NumberFormat value={props.value} displayType={'text'} thousandSeparator={true} />
            </Typography>
            <Typography component='div' variant='h6' align='center'>
                {props.title}
            </Typography>
        </Paper>
    )
}
