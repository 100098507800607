import React from 'react'
import { Table, TableContainer, TableCell, TableHead, TableRow, TableBody, Paper, TextField } from '@mui/material'
import { tableCellClasses } from '@mui/material/TableCell';
import { makeStyles } from '@mui/styles'
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.background.default,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});

export default function ReturnTableData(props) {
    const classes = useStyles();

    return (
        <div>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">ລຳດັບ</StyledTableCell>
                            <StyledTableCell align="center">ຊື່ສິນຄ້າ</StyledTableCell>
                            <StyledTableCell align="center">ຈຳນວນ</StyledTableCell>
                            <StyledTableCell align="center">ລາຄາຂາຍ</StyledTableCell>
                            <StyledTableCell align="center">ຄືນ</StyledTableCell>
                            <StyledTableCell align="center">ເສຍຫາຍ</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.billcodeList.map((row, index) =>
                            <StyledTableRow key={index}>
                                <StyledTableCell align="center">{index + 1}</StyledTableCell>
                                <StyledTableCell align="center">{row.product_name}</StyledTableCell>
                                <StyledTableCell align="center">{row.quantity}</StyledTableCell>
                                <StyledTableCell align="center">{row.price}</StyledTableCell>
                                <StyledTableCell align="center">
                                    <TextField
                                        name='return_qty'
                                        size='small'
                                        sx={{ width: '30%' }}
                                        value={row.return_qty || ""}
                                        onChange={(e) => props.handleTableChange(e, index)}
                                    />
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <TextField
                                        name='broken_qty'
                                        size='small'
                                        sx={{ width: '30%' }}
                                        value={row.broken_qty || ""}
                                        onChange={(e) => props.handleTableChange(e, index)}
                                    />
                                </StyledTableCell>

                            </StyledTableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
