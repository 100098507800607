import React, {useState } from 'react';
import { Button, Container, Typography, TextField, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SendIcon from '@material-ui/icons/Send';
import Axios from 'axios';

//import component
import Tables from './Tables'

const useStyles = makeStyles((theme) => ({
    button: {
        height: "145%", 
        width: "100%",
    },
}));

export default function RetrunMoney(props) {
    const classes = useStyles();
    const [tableRow, setTableRow] = useState({});
    const [tableData, setTableData] = useState([]);
    const [disableQtyToMove, setDisableQtyToMove] = useState(true)

    const handleBarcode = (e) => {
        setTableRow({...tableRow, barcode:e.target.value});
    };

    const handleSubQty = (e) => {
        if(Number(e.target.value) > Number(tableRow.qty)){
            alert("ຈຳນວນບໍ່ພຽງພໍ");
        }else{
            setTableRow({...tableRow, 
                qtyAfterMove: Number(tableRow.qty)-Number(e.target.value), 
                subQtyAfterMove: (Number(e.target.value) * Number(tableRow.sub_qty_per_one)) + Number(tableRow.sub_product_qty),
                qty_to_move: e.target.value
            });    
        }
    };

    const PressEnterBarcode = (e) =>{
        if(e.key === 'Enter'){
            if(tableRow.barcode){
            const options = {
                url: window.$api + "/selectproductmovesinfo"   ,
                method: "POST",
                headers:{
                "Accept": "application/json",
                //"content-Type": "application/json;charset-UTF-8"
                },
                data: {"barcode": tableRow.barcode},
            };
            Axios(options).then(res =>{
                if(res.data.product){
                    setTableRow({...tableRow,
                        //id: res.data.product.id,
                        product_name: res.data.product.product_name,
                        qty: res.data.product.quantity,
                        sub_qty_per_one: res.data.product.sub_qty_per_one,
                        //sub_product_id: res.data.sub_product.id,
                        sub_product_barcode: res.data.sub_product.product_barcode,
                        sub_product_name: res.data.sub_product.product_name,
                        sub_product_qty: res.data.sub_product.quantity,
                        qtyAfterMove: Number(res.data.product.quantity) - Number(tableRow.qty_to_move),
                        subQtyAfterMove: Number(tableRow.qty_to_move) * Number(res.data.product.sub_qty_per_one) + Number(res.data.sub_product.quantity)
                    })
                    setDisableQtyToMove(false)
                }else{
                    alert(res.data);
                }
                
            });
            }else{
                alert("Empty field")
            }
        }
    };

    const hasName = (arr, name) => arr.some(el => el.barcode === name);

    const AddToArrayTable = () => {
        if(tableRow.barcode && tableRow.sub_product_barcode){
            if(hasName(tableData, tableRow.barcode)){
                alert("ບາໂຄດສິນຄ້າແມ່ຊ້ຳ");
                setTableRow({});
            }else{
                let newArray = [...tableData, tableRow];
                setTableData(newArray);
                setTableRow({});
                setDisableQtyToMove(true)
            }
        }else{
            alert("No data")
        }
    };

    const Submit = () => {
        if(tableData.length > 0){
            const options = {
                url: window.$api + "/updatemoveproduct",
                method: "POST",
                headers:{
                    "Accept": "application/json",
                    //"content-Type": "application/json;charset-UTF-8"
                },
                data: {
                    tableData: tableData,
                    token: props.accessToken
                }
            };
            Axios(options).then((res)=> {
                alert(res.data)
                setTableData([]);
                setDisableQtyToMove(true)
            })
        }else{
            alert("ລາຍການວ່າງເປົ່າ")
        }
    };

    const handleDelete = (barcode) => {
        let newArray = [...tableData]
        const del = newArray.filter(update => update.barcode !== barcode);
        setTableData(del)
    }

    return (
        <div>
            <Container maxWidth="xl">
                <Paper style={{padding: "2%", marginTop: "3vh", marginBottom: "5vh"}}>
                    <Grid container spacing={2} style={{justifyContent: "center"}}>
                        <Grid item xs={12}>
                            <Typography variant="h5" align='center'>
                                ໂອນຍ້າຍສິນຄ້າຈາກແພັກໄປຂາຍຍ່ອຍ
                            </Typography>
                        </Grid>
                        <Grid item md={5} sm={12} xs={12}>
                            <Grid container spacing={2}>
                                <Grid item sm={12} xs={12}>
                                <Typography variant="h5" align='center'>
                                    ຂໍ້ມູນສິນຄ້າແມ່
                                </Typography>
                                </Grid>
                                <Grid item sm={12} xs={12}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        name="ບາໂຄດສິນຄ້າແມ່"
                                        label="ບາໂຄດສິນຄ້າແມ່"
                                        value={tableRow.barcode || ""}
                                        onChange={handleBarcode}
                                        onKeyPress={PressEnterBarcode}
                                    />
                                </Grid>
                                <Grid item sm={12} xs={12}>
                                    <TextField
                                    variant="outlined"
                                    fullWidth
                                    label="ຊື່ສິນຄ້າ"
                                    name="່ຊື່ສິນຄ້າ"
                                    value={tableRow.product_name || ""}
                                    disabled
                                    />
                                </Grid>
                                <Grid item sm={12} xs={12}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        name="່ຈຳນວນສິນຄ້າ"
                                        label="ຈຳນວນສິນຄ້າທີ່ມີ"
                                        value={tableRow.qty || ""}
                                        disabled
                                    />
                                </Grid>
                                <Grid item sm={12} xs={12}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        name="ຈ/ນສິນຄ້າຫຼັງຍ້າຍ"
                                        label="ຈ/ນສິນຄ້າຫຼັງຍ້າຍ"
                                        value={tableRow.qtyAfterMove || ""}
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={2} sm={12} xs={12} style={{marginBottom: 20}}>
                            <Grid container spacing={2}>
                                <Grid item sm={12} xs={12}>
                                    <Typography variant="h5" align='center'>
                                        -
                                    </Typography>
                                </Grid>
                                <Grid item sm={12} xs={12}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        name="ຈ/ນທີຈະຍ້າຍ"
                                        label="ຈ/ນທີຈະຍ້າຍ"
                                        value={tableRow.qty_to_move || ""}
                                        disabled={disableQtyToMove}
                                        onChange={handleSubQty}
                                        
                                    />
                                </Grid>
                                <Grid item sm={12} xs={12}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className={classes.button}
                                        endIcon={<SendIcon/>}
                                        onClick={AddToArrayTable}
                                    >
                                        ຍ້າຍ
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={5} sm={12} xs={12} >
                            <Grid container spacing={2}>
                                <Grid item sm={12} xs={12}>
                                    <Typography variant="h5" align='center'>
                                        ຂໍ້ມູນສິນຄ້າຍ່ອຍ
                                    </Typography>
                                </Grid>
                                <Grid item sm={12} xs={12}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        name="ບາໂຄດສິນຄ້າຍ່ອຍ"
                                        label="ບາໂຄດສິນຄ້າຍ່ອຍ"
                                        value={tableRow.sub_product_barcode || ""}
                                        disabled
                                    />
                                </Grid>
                                <Grid item sm={12} xs={12}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        label="ຊື່ສິນຄ້າ"
                                        name="່ຊື່ສິນຄ້າ"
                                        value={tableRow.sub_product_name || ""}
                                        disabled
                                    />
                                </Grid>
                                <Grid item sm={12} xs={12}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        name="່ຈຳນວນສິນຄ້າ"
                                        label="ຈຳນວນສິນຄ້າທີ່ມີ"
                                        value={tableRow.sub_product_qty || ""}
                                        disabled
                                    />   
                                </Grid>
                                <Grid item sm={12} xs={12}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        name="ຈ/ນສິນຄ້າຫຼັງຍ້າຍ"
                                        label="ຈ/ນສິນຄ້າຫຼັງຍ້າຍ"
                                        value={tableRow.subQtyAfterMove || ""}
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {tableData.length > 0 && 
                            <Grid item xs={12}>
                                <Tables tableData={tableData} handleDelete={handleDelete}/>
                            </Grid>
                        }
                        {tableData.length > 0 && 
                            <Grid item sm={2} xs={12}>
                                <Button 
                                    variant="contained" 
                                    color="secondary" 
                                    style={{width: "100%"}}
                                    onClick={Submit}
                                >
                                    ບັນທຶກ
                                </Button>
                            </Grid>
                        }
                    </Grid>
                </Paper>
            </Container>
        </div>
    );
}
