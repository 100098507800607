import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Axios from 'axios';
import EditMaterial from '../AddNewProduct/EditMaterial';
import Backdrop from '../Backdrop/Backdrop'

export default function EditMaterialDiag(props) {
  const [category, setCategory] = useState([]);
  const [unit, setUnit] = useState([]);
  const [productInfo, setproductInfo] = useState({})
  const [addedCusPriceList, setaddedCusPriceList] = useState([])
  const [selectedPriceCatName, setselectedPriceCatName] = useState("")
  const [selectedPriceCatId, setselectedPriceCatId] = useState('')
  const [customerPriceList, setcustomerPriceList] = useState([])
  const [img, setimg] = useState("")
  const [openAddCusPriceCatDiag, setopenAddCusPriceCatDiag] = useState(false)
  const [openBackdrop, setopenBackdrop] = useState(false)
  const [priceCat, setpriceCat] = useState()
  // const [autoBarcode, setAutoBarcode] = useState(false)

  useEffect(() => {
    Axios
      .get(window.$api + "/selectedcategories")
      .then((res) => {
        setUnit(res.data.unit)
        setCategory(res.data.cate)
        setcustomerPriceList(res.data.priceCat)
      }
      );
  }, []);

  const handleChangePriceCat = (e) => {
    setpriceCat(e.target.value)
  }

  const handleCloseCusPrceCatDiag = () => {
    setpriceCat('')
    setopenAddCusPriceCatDiag(false)
  }

  const handleChange = (e) => {
    setproductInfo({
      ...productInfo,
      [e.target.name]: e.target.value
    })
  }

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleSelectCate = (id, catename) => {
    setproductInfo({
      ...productInfo,
      selectCateId: id,
      selectCateName: catename,
      selectSubCateId: "",
      selectSubCateName: ""
    })
  }

  const handleSelectUnit = (unitId, unitName) => {
    props.setSelectedMaterial({
      ...props.selectedMaterial,
      unit_id: unitId,
      unit_name: unitName
    })
  }

  const handleAuto = () => {
    //setAutoBarcode(true);
    const options = {
      url: window.$api + "/generateAutobarcode",
      method: "GET",
      headers: {
        "Accept": "application/json",
      },

    };
    Axios(options).then(Response => {
      props.setSelectedMaterial({ ...props.selectedMaterial, product_barcode: Number(Response.data.maxbarcode) + 1, auto_barcode: "1" });
    });
  };

  const handleResetAuto = () => {
      //setAutoBarcode(false);
      props.setSelectedMaterial({ ...props.selectedMaterial, product_barcode: "", auto_barcode: "0"});
  };

  const updateData = (id, barcode, auto, name, qty, unit_id, unit, price, cost) => {
    let update = [...props.data];
    update.forEach((row) => {
        if(row.id === id) {
            row.product_barcode = barcode;
            row.auto_barcode = auto;
            row.product_name = name;
            row.price_kip = price;
            row.cost = cost;
            row.quantity = qty;
            row.unit_id = unit_id;
            row.unit_name = unit;
        }
    })
    props.setData(update);
    //console.log(update);
}

  const Submit = () => {
    setopenBackdrop(true)
    const options = {
      url: window.$api + "/updateproduct",
      method: "POST",
      headers: {
        "Accept": "application/json",
      },
      data: {
        "id": props.selectedMaterial.id || null,
        "barcode": props.selectedMaterial.product_barcode || null,
        "auto": props.selectedMaterial.auto_barcode || null,
        "productName": props.selectedMaterial.product_name || null,
        "quantity": props.selectedMaterial.quantity || 0,
        "category_id": props.selectedMaterial.category_id,
        "unit_id": props.selectedMaterial.unit_id || null,
        "price": props.selectedMaterial.price_kip || 0,
        "subPrice": props.selectedMaterial.subPrice || 0,
        "cost": props.selectedMaterial.cost || 0,
        "subBarcode": props.selectedMaterial.subBarcode || null,
        "subNameProduct": props.selectedMaterial.subNameProduct || null,
        "subQty": props.selectedMaterial.subQty || 0,
        "addedCusPriceList": addedCusPriceList,
        "img": img
      },
    };
    Axios(options).then(Response => {
      if (Response.data.status === 1) {
        // var arr = [...props.data]
        // var data = arr.forEach((row) => {
        //   if(row.id === props.selectedMaterial.id) {
        //     row.id = props.selectedMaterial.id,
        //     row.product_barcode = props.selectedMaterial.product_barcode;
        //     row.product_name = props.selectedMaterial.product_name;
        //     row.price_kip = props.selectedMaterial.price_kip;
        //     row.cost = props.selectedMaterial.cost;
        //     row.quantity = props.selectedMaterial.quantity;
        //     row.unit_name = props.selectedMaterial.unit_name;
        //   }
        // })
        //props.setData(data)
        // setproductInfo({})
        // setaddedCusPriceList([])
        //props.updateData(props.selectedMaterial.id, props.selectedMaterial.product_barcode, props.selectedMaterial.product_name, props.selectedMaterial.quantity, props.selectedMaterial.unit_name, props.selectedMaterial.price_kip, props.selectedMaterial.cost);
        updateData(props.selectedMaterial.id, props.selectedMaterial.product_barcode, props.selectedMaterial.auto_barcode, props.selectedMaterial.product_name, props.selectedMaterial.quantity, props.selectedMaterial.unit_id, props.selectedMaterial.unit_name, props.selectedMaterial.price_kip, props.selectedMaterial.cost);
        props.setOpen(false);
        setopenBackdrop(false)
      } else {
        alert(Response.data.mesg)
        setopenBackdrop(false)
      }
    });
  };

  const validateData = () => {
    if (!props.selectedMaterial.product_barcode) {
      alert("ກະລຸນາຕື່ມຂໍ້ມູນລະຫັດບາໂຄດ");
    } else {
      if (!props.selectedMaterial.product_name) {
        alert("ກະລຸນາຕື່ມຂໍ້ມູນຊື່ຂອງສິນຄ້າ");
      } else {
        // if (!productInfo.selectCateId) {
        //   alert("ກະລຸນາຕື່ມຂໍ້ມູນປະເພດສິນຄ້າ");
        // } else {
          if (!props.selectedMaterial.cost) {
            alert("ກະລຸນາຕື່ມຂໍ້ມູນລາຄາຕົ້ນທຶນ");
          } else {
            if (!props.selectedMaterial.price_kip) {
              alert("ກະລຸນາຕື່ມຂໍ້ມູນລາຄາທົ່ວໄປ");
            } else {
              // if ((productInfo.subBarcode && productInfo.subPrice && productInfo.subNameProduct && productInfo.subQty) || (!productInfo.subBarcode && !productInfo.subPrice && !productInfo.subNameProduct && !productInfo.subQty)) {
                Submit()
              // }
              // else if (productInfo.subBarcode || productInfo.subPrice || productInfo.subNameProduct || productInfo.subQty) {
              //   alert("ຂໍ້ມູນສິນຄ້າຍ່ອຍບໍ່ຄົບຖ້ວນ")
              // }
            }
          }
        //}
      }
    }
  }

  const handleAddCusPriceList = () => {
    var arr = [...addedCusPriceList]

    const checkExist = (arr, id) => arr.some(el => el.id === id)

    if (checkExist(arr, selectedPriceCatId)) {
      arr.forEach((el, index) => {
        if (el.id === selectedPriceCatId) {
          el.value = priceCat
        }
      });
    } else {
      var data = {
        id: selectedPriceCatId,
        value: priceCat,
        name: selectedPriceCatName
      }

      arr.push(data)
    }

    setaddedCusPriceList(arr)
    setselectedPriceCatId('')
    setselectedPriceCatName("")
    setopenAddCusPriceCatDiag(false)
  }

  const handleChangeAddedCusPriceList = (e) => {
    var arr = [...addedCusPriceList]

    arr.forEach(el => {
      if (el.id === Number(e.target.id)) {
        el.value = e.target.value
      }
    });

    setaddedCusPriceList(arr)
  }

  const handleSelectCustomerCat = (id, name) => {
    setselectedPriceCatId(id)
    setselectedPriceCatName(name)
  }

  const handleOpenCusPriceCatDiag = () => {
    setopenAddCusPriceCatDiag(true)
  }

  const onChangeImg = (e) => {
    if (e.target.files[0]) {
      var fileReader = new FileReader();
      fileReader.onload = function (fileLoadedEvent) {
        var srcData = fileLoadedEvent.target.result; // <--- data: base64
        setimg(srcData)
      }
      fileReader.readAsDataURL(e.target.files[0]);
    }
  }

  return (
    <div>
      <Dialog
        maxWidth='xl'
        fullWidth={true}
        open={props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <EditMaterial
            text="test"
            category={category}
            setCategory={setCategory}
            unit={unit}
            setUnit={setUnit}
            handleSelectCate={handleSelectCate}
            handleAuto={handleAuto}
            handleResetAuto={handleResetAuto}
            //autoBarcode={autoBarcode}
            productInfo={productInfo}
            handleChange={handleChange}
            handleSelectUnit={handleSelectUnit}
            addedCusPriceList={addedCusPriceList}
            selectedPriceCatName={selectedPriceCatName}
            handleAddCusPriceList={handleAddCusPriceList}
            openAddCusPriceCatDiag={openAddCusPriceCatDiag}
            handleCloseCusPrceCatDiag={handleCloseCusPrceCatDiag}
            handleOpenCusPriceCatDiag={handleOpenCusPriceCatDiag}
            handleChangeAddedCusPriceList={handleChangeAddedCusPriceList}
            handleSelectCustomerCat={handleSelectCustomerCat}
            customerPriceList={customerPriceList}
            onChangeImg={onChangeImg}
            img={img}
            priceCat={priceCat}
            handleChangePriceCat={handleChangePriceCat}
            selectedMaterial={props.selectedMaterial}
            handleChangeEdit={props.handleChangeEdit}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={validateData}
            autoFocus
          >
            ບັນທຶກ
          </Button>
          <Button
            variant="contained"
            style={{ backgroundColor: "#FF0000", color: "#FFFFFF" }}
            onClick={handleClose}
          >
            ຍົກເລີກ
          </Button>
        </DialogActions>
        <Backdrop open={openBackdrop} />
      </Dialog>
    </div>
  );
}