import React from 'react'
import Grid from '@material-ui/core/Grid';
import Barcode from 'react-barcode';
import NumberFormat from 'react-number-format';

export default function PrintPricePage(props) {
    // const arr = [
    //     {id: 1, name: "ເຫຼັກໜີບເຈ້ຍ", price: "10000", barcode: "8851907264888", qty: 3},
    //     {id: 2, name: "ເຫຼັກໜີບເຈ້ຍ", price: "10000", barcode: "8851907264888", qty: 2},
    // ]
    return (
        <div>
            <Grid
                container
                //direction="row"
                //justifyContent="flex-start"
                //alignItems="flex-start"
                spacing={2}
            >
                {props.tableData.map(row => 
                    new Array(row.qty).fill(
                    <Grid item xs={3} id={row.id}>
                            <p style={{textAlign: "center", fontSize: 10}}>Best Mart</p>
                            <div style={{lineHeight: 0.1}}>
                                <p style={{textAlign: "center", fontSize: 10}}>{row.name}</p>
                                <p style={{textAlign: "center", fontSize: 20}}>
                                    <NumberFormat value={row.price} displayType={'text'} thousandSeparator={true} suffix={' ກີບ'}/>
                                </p>
                            </div>
                            
                            <div style={{textAlign: "center"}}>
                            <Barcode 
                                value={row.barcode} 
                                width={1} 
                                height={20} 
                                margin={2}
                                fontSize={12}
                            />
                            </div>
                    </Grid>
                    )
                )}
            </Grid>
        </div>
    )
}
