import React, { useState } from 'react';
import { IconButton, Container, Paper, MenuItem, Typography, Grid, TextField, Button } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DialogAddChannelType from './DialogAddChannelType';
import DialogAddChannelProvince from './DialogAddChannelProvince';
import DialogAddChannelDistrict from './DialogAddChannelDistrict';
import DialogAddChannelVillage from './DialogAddChannelVillage';

export default function EditChannel(props) {
  const [open, setOpen] = useState(false);
  const [openProvince, setOpenProvince] = useState(false);
  const [openDistrict, setOpenDistrict] = useState(false);
  const [openVillage, setOpenVillage] = useState(false);

  //dialog
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenProvince = () => {
    setOpenProvince(true);
  };

  const handleClickOpenDistrict = () => {
    setOpenDistrict(true);
  };

  const handleClickOpenVillage = () => {
    setOpenVillage(true);
  };

  return (
    <div>
      <Container maxWidth='xl'>
        <Paper style={{ padding: "2%", marginTop: "2vh", marginBottom: "2vh" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" component="h6">ແກ້ໄຂຂໍ້ມູນ ສາຂາ/ຕົວແທນ/ລູກຄ້າ</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={6} sm={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item sm={10} xs={10}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    label="ປະເພດ"
                    select
                    fullWidth
                    value={props.channelInfo.selectChannelTypeName || ""}
                  >
                    {props.channelType.map((row, index) => (
                      <MenuItem
                        key={index}
                        value={row.name || ""}
                        onClick={() => props.handleSelectChannelType(row.id, row.name)}
                      >
                        {row.name}
                      </MenuItem>
                    ))}
                  </TextField>

                </Grid>
                <Grid item sm={2} xs={2}>
                  <IconButton
                    variant="contained"
                    color="primary"
                    onClick={handleClickOpen}
                  >
                    <AddCircleIcon style={{ color: '#009900' }} />
                  </IconButton>
                </Grid>
                <Grid item sm={10} xs={12}>
                  <TextField
                    id="outlined-basic"
                    name='username'
                    label="ຊື່ເຂົ້າໃຊ້ລະບົບ"
                    variant="outlined"
                    fullWidth
                    value={props.channelInfo.username || ""}
                    onChange={props.handleChange}
                  />
                </Grid>
                <Grid item sm={10} xs={12}>
                  <TextField
                    id="outlined-basic"
                    name='password'
                    label="ລະຫັດຜ່ານ"
                    variant="outlined"
                    fullWidth
                    value={props.channelInfo.password || ""}
                    onChange={props.handleChange}
                  />
                </Grid>
                <Grid item sm={10} xs={12}>
                  <TextField
                    id="outlined-basic"
                    name='name'
                    label="ຊື່"
                    variant="outlined"
                    fullWidth
                    value={props.channelInfo.name || ""}
                    onChange={props.handleChange}
                  />
                </Grid>
                <Grid item sm={10} xs={12}>
                  <TextField
                    id="outlined-basic"
                    name='firstname'
                    label="ຊື່ຜູ້ຮັບຜິດຊອບ"
                    variant="outlined"
                    fullWidth
                    value={props.channelInfo.firstname || ""}
                    onChange={props.handleChange}
                  />
                </Grid>
                <Grid item sm={10} xs={12}>
                  <TextField
                    id="outlined-basic"
                    name='lastname'
                    label="ນາມສະກຸນ"
                    variant="outlined"
                    fullWidth
                    value={props.channelInfo.lastname || ""}
                    onChange={props.handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={6} sm={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item sm={10} xs={12}>
                  <TextField
                    id="outlined-basic"
                    //name='customer_price'
                    label="ປະເພດລາຄາ"
                    variant="outlined"
                    fullWidth
                    select
                    value={props.channelInfo.selectChannelCustomerPriceName || ""}
                  >
                    {props.customerPrice.map((row, index) => (
                      <MenuItem
                        key={index}
                        value={row.name || ""}
                        onClick={() => props.handleSelectChannelCustomerPrice(row.id, row.name)}
                      >
                        {row.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item sm={10} xs={12}>
                  <TextField
                    id="outlined-basic"
                    name='phone'
                    label="ເບີໂທ"
                    variant="outlined"
                    fullWidth
                    value={props.channelInfo.phone || ""}
                    onChange={props.handleChange}
                  />
                </Grid>
                <Grid item sm={10} xs={12}>
                  <TextField
                    id="outlined-basic"
                    //name='province'
                    label="ແຂວງ"
                    variant="outlined"
                    select
                    fullWidth
                    //value={props.channelInfo.province || ""}
                    value={props.channelInfo.selectChannelProvinceName || ""}
                  >
                    {props.province.map((row, index) => (
                      <MenuItem
                        key={index}
                        value={row.province || ""}
                        onClick={() => props.handleSelectChannelProvince(row.id, row.province)}
                      >
                        {row.province}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item sm={2} xs={2}>
                  <IconButton
                    variant="contained"
                    color="primary"
                    onClick={handleClickOpenProvince}
                  >
                    <AddCircleIcon style={{ color: '#009900' }} />
                  </IconButton>
                </Grid>
                <Grid item sm={10} xs={12}>
                  <TextField
                    id="outlined-basic"
                    //name='district'
                    label="ເມືອງ"
                    variant="outlined"
                    select
                    fullWidth
                    value={props.channelInfo.selectChannelDistrictName || ""}
                  //onChange={props.handleChange}
                  >
                    {props.district.map((row, index) => (

                      <MenuItem
                        key={index}
                        value={row.district || ""}
                        onClick={() => props.handleSelectChannelDistrict(row.id, row.district)}
                      >
                        {/* {props.channelInfo.selectChannelProvinceId === row.id_province &&
                          <>
                            {row.district}
                          </>
                        } */}
                        {row.district}
                      </MenuItem>

                    ))}
                  </TextField>
                </Grid>
                <Grid item sm={2} xs={2}>
                  <IconButton
                    variant="contained"
                    color="primary"
                    onClick={handleClickOpenDistrict}
                  >
                    <AddCircleIcon style={{ color: '#009900' }} />
                  </IconButton>
                </Grid>
                <Grid item sm={10} xs={12}>
                  <TextField
                    id="outlined-basic"
                    //name='village'
                    label="ບ້ານ"
                    variant="outlined"
                    select
                    fullWidth
                    value={props.channelInfo.selectChannelVillageName || ""}
                  >
                    {props.village.map((row, index) => (
                      <MenuItem
                        key={index}
                        value={row.village || ""}
                        onClick={() => props.handleSelectChannelVillage(row.id, row.village)}
                      >
                        {/* {props.channelInfo.selectChannelDistrictId === row.id_district &&
                            <>
                              {row.village}
                            </>
                          } */}
                        {row.village}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item sm={2} xs={2}>
                  <IconButton
                    variant="contained"
                    color="primary"
                    onClick={handleClickOpenVillage}
                  >
                    <AddCircleIcon style={{ color: '#009900' }} />
                  </IconButton>
                </Grid>

              </Grid>
            </Grid>
          </Grid>
          {props.showSubmitButton &&
            <Grid item xs={12} style={{ textAlign: 'center', marginTop: "2%" }}>
              <Button variant="contained" color="secondary" onClick={props.Submit}>
                ບັນທຶກ
              </Button>
            </Grid>
          }
        </Paper>
      </Container>

      <DialogAddChannelType 
        open={open} 
        setOpen={setOpen} 
        setChannelType={props.setChannelType} 
        channelType={props.channelType} 
      />

      <DialogAddChannelProvince 
        openProvince={openProvince} 
        setOpenProvince={setOpenProvince} 
        setProvince={props.setProvince} 
      />

      <DialogAddChannelDistrict 
        openDistrict={openDistrict} 
        setOpenDistrict={setOpenDistrict} 
        province={props.province}
        setDistrict={props.setDistrict}
      />

      <DialogAddChannelVillage 
        openVillage={openVillage} 
        setOpenVillage={setOpenVillage}
        district={props.district}
        setVillage={props.setVillage}
      />
    </div>
  );
}