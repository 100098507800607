import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
//import Img from '../../Img/img_menu/product.png
// import Img from '../../Img/img_menu/product.png'
// import Image2 from '../../Img/Bestmart_Icon.png'

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.background.dark,
        color: theme.palette.background.default,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);


// const titles = [
//     { id: '1', name: ['ລຳດັບ', 'ຊື່ຜູ້ສົ່ງ', 'ຮູບແບບຊຳລະ', 'ລາຄາລວມ', 'ສະຖານະ', 'ວັນທີສົ່ງ', 'Action'] },
// ];

// const datas = [
//     { id: '1', name: 'ຕຸກໜ້ວຍ', img: Img, Wholesale: '99999', subsale: '999000', unit: '10000', barcode: '8851907264888' },
//     { id: '2', name: 'ນ້ຳດື່ມກາງ', img: Image2, Wholesale: '2000', subsale: '3000', unit: '1000', barcode: '8851907264888' },
//     { id: '3', name: 'ນ້ຳດື່ມໃຫຍ່', img: Img, Wholesale: '3000', subsale: '5000', unit: '1000', barcode: '8851907264888' },
// ];


const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});

export default function TableReportStock(props) {
    const classes = useStyles();

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
                <TableHead>
                    <TableRow >
                        <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ລຳດັບ</StyledTableCell>
                        <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ຊື່ສາຂາ</StyledTableCell>
                        <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ເບີໂທ</StyledTableCell>
                        <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ບາໂຄດ</StyledTableCell>
                        <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ຈຳນວນ</StyledTableCell>
                        <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ວັນທີ</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.data.map((row, index) => (
                        <StyledTableRow key={index}>

                            <StyledTableCell align="center" component="th" scope="row">
                                {index + 1}
                            </StyledTableCell>
                            <StyledTableCell align="center">{row.name}</StyledTableCell>
                            <StyledTableCell align="center">{row.phone}</StyledTableCell>
                            <StyledTableCell align="center">{row.barcode}</StyledTableCell>
                            <StyledTableCell align="center">{row.qty}</StyledTableCell>
                            <StyledTableCell align="center">{row.date} {row.time}</StyledTableCell>
                            
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

//TableOrders