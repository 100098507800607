import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Grid, TextField, Button, Typography, DialogActions, DialogContent } from '@material-ui/core';
import Axios from 'axios';

export default function DialogAddCategory(props) {
    const [unit, setUnit] = useState("");

    const handleClose = () => {
        props.setOpen1(false);
    };

    const handleUnit = (e) => {
        setUnit(e.target.value);
    };

    const Submit = () =>{
        const options = {
            url: window.$api + "/addunit"   ,
            method: "POST",
            headers:{
                "Accept": "application/json",
                ////"content-Type": "application/json;charset-UTF-8"
            },
            data: {
                "unit" : unit
            },
            
        };
        Axios(options).then(Response =>{
            const options = {
                url: window.$api + "/selectedcategories"   ,
                method: "get",
                headers:{
                    "Accept": "application/json",
                    ////"content-Type": "application/json;charset-UTF-8"
                },
                
            };
            Axios(options).then(Response =>{
                props.setUnit(Response.data.unit);    
                props.setOpen1(false);  
            });
        });
    };

    return (
        <Dialog maxWidth = 'xl' open={props.open}>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography>
                            ເພີ່ມຫົວໜ່ວຍນັບໃຫມ່
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="ຫົວໜ່ວຍນັບ"
                            variant="outlined"
                            fullWidth
                            onChange={handleUnit}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={Submit}>
                    ບັນທຶກ
                </Button>
                <Button onClick={handleClose}>ຍົກເລີກ</Button>
            </DialogActions>
        </Dialog>
    )
}