// import { Container, Typography, Divider, Grid } from '@mui/material';
// import React from 'react'
// import { makeStyles } from '@material-ui/core/styles';
// import GoogleMap from 'Bt_modules/GoogleMap/GoogleMap';
// import TextInput from 'Bt_modules/TextInput/TextInput';

// const useStyles = makeStyles((theme) => ({

//   text1: {
//     //color: theme.palette.primary.main,
//     //textAlign: 'center',
//     fontWeight: '700',
//     fontSize: '0.5rem',
//     [theme.breakpoints.up('sm')]: {
//       fontSize: '1rem',
//     },
//   },
//   text2: {
//     //color: theme.palette.secondary.main,
//     fontWeight: '400',
//     fontSize: '0.5rem',
//     [theme.breakpoints.up('sm')]: {
//       fontSize: '1rem',
//     },
//   },
//   topic: {
//     color: theme.palette.secondary.dark,
//   },
//   underLine: {
//     width: '12.5vw',
//     backgroundColor: theme.palette.primary.main,
//   },
//   underLine2: {
//     backgroundColor: theme.palette.primary.main,
//   },
// }));

// export default function ContactPage(props) {
//   const classes = useStyles();

//   return (
//     <Container maxWidth='false' style={{ padding: '5%' }}>
//       <div style={{ display: 'flex' }}>
//         <div style={{ flex: 1 }}>
//           <Typography component={'div'} style={{ fontSize: '2.5vw' }} className={classes.topic}>{props.t("title.3")}<Divider sx={{ borderBottomWidth: '0.5vw' }} className={classes.underLine} /></Typography>
//           <Divider sx={{ borderBottomWidth: 3 }} className={classes.underLine2} />
//           <br />
//         </div>
//       </div>
//       <br /><br />
      // <Grid container>
      //   <Grid item container xs={12} sm={12} md={6} lg={6} xl={6} >
      //     <GoogleMap />
      //   </Grid>
      //   <Grid item container xs={12} sm={12} md={6} lg={6} xl={6} >
      //     <Grid container>
      //       <Grid item container xs={6} sm={6} md={6} lg={6} xl={6} style={{ justifyContent: 'center', }}>
      //         <div >
      //           <div style={{ textAlign: '' }}>
      //             <Typography variant='h4' className={classes.text1}>{props.t("info.3")}</Typography>&nbsp;
      //           </div>

      //           <div>
      //             <Typography variant='h6' className={classes.text2}>{props.t('contact.1')}</Typography>
      //             <Typography variant='h6' className={classes.text2}>{props.t('contact.2')}</Typography>
      //             <Typography variant='h6' className={classes.text2}>{props.t('contact.3')}</Typography>
      //           </div>
      //         </div>
      //       </Grid>
      //       <Grid item container xs={6} sm={6} md={6} lg={6} xl={6} style={{ justifyContent: 'center', }}>
      //         <div >
      //           <div style={{ textAlign: '' }}>
      //             <Typography variant='h4' className={classes.text1}>{props.t("info.2")}</Typography>&nbsp;&nbsp;&nbsp;
      //           </div>

      //           <div >
      //             <Typography variant='h6' className={classes.text2} style={{ whiteSpace: "pre-wrap" }} >{props.t("address.1")}</Typography>
      //           </div>
      //         </div>
      //       </Grid>
      //     </Grid>
      //   </Grid>
      // </Grid>
//       <br /><br /><br />
//       <Grid container style={{ justifyContent: 'center' }}>
//         <div style={{ textAlign: 'center' }}>
//           <Typography variant='h6' className={classes.text1} component={'div'}>{props.t('contact.4')} :</Typography>
//           {/* <Typography variant='h6' className={classes.text1} component={'div'}>{props.t('contact.5')}</Typography> */}
//         </div>
//       </Grid>
//       <br />
//       <TextInput t={props.t} />
//     </Container >

//   )
// }



import {Typography, Grid} from '@material-ui/core';
import React from 'react'
import GoogleMap from 'Bt_modules/GoogleMap/GoogleMap';
import { makeStyles } from '@material-ui/core/styles';
import TextInput from 'Bt_modules/TextInput/TextInput';

const useStyles = makeStyles((theme) => ({
  topic: {
    fontWeight: 'bold',
    //color: theme.palette.secondary.dark,
    fontSize: '18px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '25px',

    },
    textIndent: '40px'
  },
}))

  export default function ContactPage(props) {
  const classes = useStyles();


  return (
    <div>
      <br /> ​<br />
      <Typography component={'div'}>
        <div className={classes.topic}>{props.t('title.3')}</div>
      </Typography>
      <br />
      <div style={{padding: '2%'}}>
      <Grid container>
        <Grid item container xs={12} sm={12} md={6} lg={6} xl={6} >
          <GoogleMap />
        </Grid>
        <Grid item container xs={12} sm={12} md={6} lg={6} xl={6} >
          <Grid container>
            <Grid item container xs={6} sm={6} md={6} lg={6} xl={6} style={{ justifyContent: 'center', }}>
              <div >
                <div style={{ textAlign: '' }}>
                  <Typography variant='h6' className={classes.text1}>{props.t("info.3")}</Typography>&nbsp;
                </div>

                <div>
                  <Typography variant='h6' className={classes.text2}>{props.t('contact.1')}</Typography>
                  <Typography variant='h6' className={classes.text2}>{props.t('contact.2')}</Typography>
                  <Typography variant='h6' className={classes.text2}>{props.t('contact.3')}</Typography>
                </div>
              </div>
            </Grid>
            <Grid item container xs={6} sm={6} md={6} lg={6} xl={6} style={{ justifyContent: 'center', }}>
              <div >
                <div style={{ textAlign: '' }}>
                  <Typography variant='h6' className={classes.text1}>{props.t("info.2")}</Typography>&nbsp;&nbsp;&nbsp;
                </div>

                <div >
                  <Typography variant='h6' className={classes.text2} style={{ whiteSpace: "pre-wrap" }} >{props.t("address.1")}</Typography>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <br /><br /><br />
      <Grid container style={{ justifyContent: 'center' }}>
        <div style={{ textAlign: 'center' }}>
          <Typography variant='h6' className={classes.text1} component={'div'}>{props.t('contact.4')} :</Typography>
          {/* <Typography variant='h6' className={classes.text1} component={'div'}>{props.t('contact.5')}</Typography> */}
        </div>
      </Grid>
      <br />
      <TextInput t={props.t} />
      </div>
      <br /><br /><br />
    </div>
  )
}
