import React, { useEffect, useState } from 'react'
import ProductInReportTable from 'Bt_modules/Tables/ProductInReportTable'
import { Container, Typography, Grid, TextField, MenuItem, Button } from '@material-ui/core'
import axios from 'axios'
import ProductInReportDiag from 'Bt_modules/Dialog/ProductInReportDiag'
import ReportCard from 'Bt_modules/Card/ReportCard'
import { Redirect } from "react-router-dom";

export default function ProductInReport(props) {
    const [productInList, setproductInList] = useState([])
    const [productInByDateList, setproductInByDateList] = useState([])
    const [openProductInDiag, setopenProductInDiag] = useState(false)
    const [selectedBillcode, setselectedBillcode] = useState('')
    const [allReportInfo, setallReportInfo] = useState({})
    const [selectedMonth, setselectedMonth] = useState("")
    const [selectedYear, setselectedYear] = useState("")

    const startedYear = 2010
    const year = (new Date()).getFullYear();
    const years = []
    const months = []

    for (var i = startedYear; i <= year; i++) {
        years.push(i)
    }

    for (var j = 1; j <= 12; j++) {
        months.push(j)
    }

    useEffect(() => {
        axios({
            url: window.$api + "/getProductInReport",
            method: 'GET',
            headers: {
                "Accept": "application/json"
            }
        }).then(res => {
            var arr = res.data.productInReport
            var result = [];

            // arr.forEach(function (a) {
            //     if (!this[a.date]) {
            //         this[a.date] = {
            //             id: a.id,
            //             date: a.date,
            //             totalQty: 0
            //         };
            //         result.push(this[a.date]);
            //     }
            //     this[a.date].totalQty += a.qty
            // }, Object.create(null));

            arr.forEach(function (a) {
                if (!this[a.billcode]) {
                    this[a.billcode] = {
                        id: a.id,
                        billcode: a.billcode,
                        date: a.date,
                        time: a.time,
                        totalQty: 0,
                        username: a.username
                    };
                    result.push(this[a.billcode]);
                }
                this[a.billcode].totalQty += a.qty
            }, Object.create(null));
            setproductInList(result)
            setallReportInfo({
                productInByCurrentDate: res.data.productInByCurrentDate,
                materialInByCurrentDate: res.data.materialInByCurrentDate,
                productInByCurrentMonth: res.data.productInByCurrentMonth,
                materialInByCurrentMonth: res.data.materialInByCurrentMonth
            })
        })
    }, [])

    const handleViewProductInDetail = (billcode) => {
        axios({
            url: window.$api + "/getProductInReportByBillcode",
            method: 'POST',
            headers: {
                "Accept": "application/json"
            },
            data: {
                billcode: billcode
            }
        }).then(res => {
            setproductInByDateList(res.data)
            setselectedBillcode(billcode)
            setopenProductInDiag(true)
        })
    }

    const handleCloseProductInDiag = () => {
        setopenProductInDiag(false)
        setselectedBillcode('')
    }

    const handleSelectMonth = (month) => {
        setselectedMonth(month)
    }

    const handleSelectYear = (year) => {
        setselectedYear(year)
    }

    const handleFilterByMonAndYear = () => {
        axios({
            url: window.$api + "/getProductInReportByMonAndYear",
            method: "POST",
            headers: {
                "Accept": "application/json"
            },
            data: {
                month: selectedMonth,
                year: selectedYear
            }
        }).then(res => {
            var arr = res.data
            var result = [];

            arr.forEach(function (a) {
                if (!this[a.billcode]) {
                    this[a.billcode] = {
                        id: a.id,
                        billcode: a.billcode,
                        date: a.date,
                        time: a.time,
                        totalQty: 0,
                        username: a.username
                    };
                    result.push(this[a.billcode]);
                }
                this[a.billcode].totalQty += a.qty
            }, Object.create(null));
            setproductInList(result)
        })
    }

    if (props.authenticated) {
        return (
            <Container style={{ paddingTop: 20 }}>
                <Typography variant='h2' align='center'>ປະຫວັດການນຳເຂົ້າ</Typography>

                <Grid container spacing={2} justifyContent='center'>
                    <Grid item xs={12} sm={6} md={3} >
                        <ReportCard title={'ສິນຄ້ານຳເຂົ້າມື້ນີ້'} value={allReportInfo.productInByCurrentDate || 0} />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} >
                        <ReportCard title={'ວັດຖຸດິບນຳເຂົ້າມື້ນີ້'} value={allReportInfo.materialInByCurrentDate || 0} />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} >
                        <ReportCard title={'ສິນຄ້ານຳເຂົ້າເດືອນນີ້'} value={allReportInfo.productInByCurrentMonth || 0} />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} >
                        <ReportCard title={'ວັດຖຸດິບນຳເຂົ້າເດືອນນີ້'} value={allReportInfo.materialInByCurrentMonth || 0} />
                    </Grid>
                </Grid>
                <br /><br />

                <Typography component='div' variant='h5'>
                    ປະຫວັດການນຳເຂົ້າ
                </Typography>
                <br />
                <div style={{ display: 'flex' }}>
                    <TextField
                        label="ເດຶອນ"
                        variant='outlined'
                        value={selectedMonth || ""}
                        select
                        style={{ width: "30%" }}
                    >
                        {months.map(row =>
                            <MenuItem key={row} value={row || ""} onClick={() => handleSelectMonth(row)}>
                                {row}
                            </MenuItem>
                        )}
                    </TextField>
                    <TextField
                        variant="outlined"
                        label='ປີ'
                        value={selectedYear || ""}
                        select
                        style={{ width: "30%" }}
                    >
                        {years.map(row =>
                            <MenuItem key={row} value={row || ""} onClick={() => handleSelectYear(row)}>
                                {row}
                            </MenuItem>
                        )}
                    </TextField>
                    <Button variant='contained' onClick={handleFilterByMonAndYear}>
                        ເບິ່ງຂໍ້ມູນ
                    </Button>
                </div>
                <br />
                <ProductInReportTable productInList={productInList} handleViewProductInDetail={handleViewProductInDetail} />

                <ProductInReportDiag
                    open={openProductInDiag}
                    billcode={selectedBillcode}
                    handleClose={handleCloseProductInDiag}
                    productInByDateList={productInByDateList}
                />
            </Container>
        )
    } else {
        return (
            <div>
                <Redirect
                    to={{
                        pathname: "/admin/sign-in",
                    }}
                />
            </div>
        )
    }
}
