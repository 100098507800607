import React, { useEffect, useState } from 'react'
import PromoManageTable from '../../../Bt_modules/Storage/PromoManageTable'
import Axios from 'axios'
import AddNewUser from '../../../Bt_modules/Storage/AddNewUserDiag';
import { Redirect } from "react-router-dom";
import DialogWarning from '../../../Bt_modules/Dialog/DialogWarning';
import EditUserDiag from '../../../Bt_modules/Storage/EditUserDiag';

export default function ProductPage(props) {
    const [village, setVillage] = useState([]);
    const [district, setDistrict] = useState([]);
    const [province, setProvince] = useState([]);
    const [search, setSearch] = useState("");
    const [channel_id, setChannel_id] = useState("")
    //const [productBarcode, setProductBarcode] = useState("")
    const [data, setData] = useState([]);
    const [selectName, setSelectName] = useState();
    const [openEdit, setOpenEdit] = useState(false)
    const [openAddNewProductDiag, setopenAddNewProductDiag] = useState(false)
    //const [openWarning, setOpenWarning] = useState(false)
    //const [idChannel, setIdChannel] = useState()
    const [channelType, setChannelType] = useState([]);
    const [customerPrice, setCustomerPrice] = useState([]);
    const [channelInfo, setChannelInfo] = useState({});
    const [selectedUser, setSelectedUser] = useState({})

    useEffect(() => {
        let isMounted = true;
        Axios
            .get(window.$api + "/getPromoData")
            .then((res) => {
                if (isMounted) {
                    setData(res.data.allPromo)
                }
            });
        return () => { isMounted = false };
    }, []);

    const handleSearch = (e) => {
        setSearch(e.target.value);
    }

    const HandleEnter = (e) => {
        if (e.key === 'Enter') {
            const options = {
                url: window.$api + "/search",
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    ////"content-Type": "application/json;charset-UTF-8"
                },
                data: {
                    "search": search,
                }

            }
            Axios(options).then(Response => {
                setData(Response.data);
                setSelectName("")
                //setSelectPartner("")
            });
        }
    }

    const handleSelectName = (e) => {
        setSearch("")
        const options = {
            url: window.$api + "/selectname",
            method: "POST",
            headers: {
                "Accept": "application/json",
                ////"content-Type": "application/json;charset-UTF-8"
            },
            data: {
                "name": e,
            }

        }
        Axios(options).then(Response => {
            setData(Response.data);

        });

    }

    const handleEditUser = (id) => {
        setChannel_id(id)
        setOpenEdit(true)
        let useData = [...data];
        let [rowData] = useData.filter(row => row.id === id);
        setSelectedUser(rowData);
    }

    const handleChange = (e) => {
        setSelectedUser({
          ...selectedUser,
          [e.target.name]: e.target.value
        })
      }

    const handleDelete = (id) => {
        if (id) {
            const options = {
                url: window.$api + "/delUser",
                method: "POST",
                headers: {
                    "Accept": "application/json",
                },
                data: {
                    "user_id": id,
                }

            }
            Axios(options).then(Response => {
                if(Response.data === "success") {
                    alert("ສໍາເລັດ");
                    let update = data.filter(row => row.id !== id);
                    setData(update);
                }
            });
        }
    }

    return (
        //<div>{JSON.stringify(data)}</div>
        <div>
            {props.authenticated ?
                <>
                    <PromoManageTable
                        //company={company} 
                        //name={name}
                        search={search}
                        //productBarcode={productBarcode}
                        channel_id={channel_id}
                        //setProductBarcode={setProductBarcode}
                        data={data}
                        setData={setData}
                        selectName={selectName}
                        openEdit={openEdit}
                        setOpenEdit={setOpenEdit}
                        setSelectName={setSelectName}
                        handleSelectName={handleSelectName}
                        //handleSelectPartner={handleSelectPartner}
                        handleEditUser={handleEditUser}
                        handleSearch={handleSearch}
                        HandleEnter={HandleEnter}
                        setopenAddNewProductDiag={setopenAddNewProductDiag}
                        handleDelete={handleDelete}
                        //OpenWarning={OpenWarning}
                    />
                    <EditUserDiag
                        open={openEdit}
                        setOpen={setOpenEdit}
                        channelID={channel_id}
                        setData={setData}
                        data={data}
                        selectedUser={selectedUser}
                        setSelectedUser={setSelectedUser}
                        handleChange={handleChange}
                    />

                    <AddNewUser
                        open={openAddNewProductDiag}
                        setOpen={setopenAddNewProductDiag}
                        setData={setData}
                        data={data}
                        village={village}
                        district={district}
                        province={province}
                        setVillage={setVillage}
                        setDistrict={setDistrict}
                        setProvince={setProvince}
                        channelType={channelType}
                        setChannelType={setChannelType}
                        customerPrice={customerPrice}
                        setCustomerPrice={setCustomerPrice}
                        setChannelInfo={setChannelInfo}
                        channelInfo={channelInfo}

                    />
                    {/* <DialogWarning
                        open={openWarning}
                        close={CloseWarning}
                        handleupdate={handleDelete}
                    /> */}
                </>
                :
                <Redirect
                    to={{
                        pathname: "/admin/sign-in",
                    }}
                />
            }
        </div>
    )
}
