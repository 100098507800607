import React, { useState } from 'react';
import { IconButton, Container, Paper, MenuItem, Typography, Grid, TextField, Button } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';

export default function EditUser(props) {
  // const [open, setOpen] = useState(false);
  // const [openProvince, setOpenProvince] = useState(false);
  // const [openDistrict, setOpenDistrict] = useState(false);
  // const [openVillage, setOpenVillage] = useState(false);

  // //dialog
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  // const handleClickOpenProvince = () => {
  //   setOpenProvince(true);
  // };
  // const handleClickOpenDistrict = () => {
  //   setOpenDistrict(true);
  // };
  // const handleClickOpenVillage = () => {
  //   setOpenVillage(true);
  // };

  return (
    <div>
      {/* {JSON.stringify(props.selectedUser)} */}
      <Container maxWidth='xl'>
        <Paper style={{ padding: "2%", marginTop: "2vh", marginBottom: "2vh" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" component="h6">ຂໍ້ມູນຜູ້ໃຊ້</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={6} sm={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item sm={10} xs={12}>
                  <TextField
                    id="outlined-basic"
                    name='firstname'
                    label="ຊື່"
                    variant="outlined"
                    fullWidth
                    value={props.selectedUser.firstname || ""}
                    onChange={props.handleChange}
                  />
                </Grid>
                <Grid item sm={10} xs={12}>
                  <TextField
                    id="outlined-basic"
                    name='username'
                    label="ຊື່ເຂົ້າລະບົບ"
                    variant="outlined"
                    fullWidth
                    value={props.selectedUser.username || ""}
                    onChange={props.handleChange}
                  />
                </Grid>
                <Grid item sm={10} xs={12}>
                  <TextField
                    id="outlined-basic"
                    name='position'
                    label="ຕໍາແໜ່ງ"
                    variant="outlined"
                    fullWidth
                    value={props.selectedUser.position || ""}
                    onChange={props.handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={6} sm={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item sm={10} xs={12}>
                  <TextField
                    id="outlined-basic"
                    name='lastname'
                    label="ນາມສະກຸນ"
                    variant="outlined"
                    fullWidth
                    value={props.selectedUser.lastname || ""}
                    onChange={props.handleChange}
                  />
                </Grid>
                <Grid item sm={10} xs={12}>
                  <TextField
                    id="outlined-basic"
                    name='password'
                    label="ລະຫັດຜ່ານ"
                    variant="outlined"
                    fullWidth
                    value={props.selectedUser.password || ""}
                    onChange={props.handleChange}
                  />
                </Grid>
                <Grid item sm={10} xs={12}>
                  <TextField
                    id="outlined-basic"
                    name='permission'
                    label="ລະດັບສິດ"
                    variant="outlined"
                    fullWidth
                    value={props.selectedUser.permission || ""}
                    onChange={props.handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>

          </Grid>
          {props.showSubmitButton &&
            <Grid item xs={12} style={{ textAlign: 'center', marginTop: "2%" }}>
              <Button variant="contained" color="secondary" onClick={props.Submit}>
                ບັນທຶກ
              </Button>
            </Grid>
          }
        </Paper>
      </Container>
    </div>
  );
}