import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Grid, TextField, Typography, Paper } from '@material-ui/core';
import { Box } from '@mui/material'
import Chart from 'Bt_modules/Chart/ChartData';
import Circle from 'Bt_modules/Circle/Circle';
//import TableReport from 'Bt_modules/Tables/TableReport';
import Axios from 'axios';
import { Redirect } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '1%',
    },
    textColor: {
        color: theme.palette.background.light
    },
    text: {
        fontSize: '30px',
        [theme.breakpoints.up('md')]: {
            fontSize: '50px',
        },
    },
    paper: {
        //padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

export default function ReportProductpage(props) {
    const classes = useStyles();
    // const [chartData, setChartData] = useState([
    //     { id: '1', count: '2000', name: 'koun', title: 'test1' },
    //     { id: '2', count: '100', name: 'koun2', title: 'test2' },
    //     { id: '3', count: '200', name: 'koun3', title: 'test3' },
    //     { id: '4', count: '300', name: 'koun4', title: 'test4' },
    //     { id: '5', count: '400', name: 'koun5', title: 'test5' },
    //     { id: '6', count: '500', name: 'koun6', title: 'test6' },
    //     { id: '7', count: '1000', name: 'koun7', title: 'test7' },
    // ]);
    const [chartData, setChartData] = useState([])
    const [newChartData, setNewChartData] = useState([]);
    //const [loading, setLoading] = React.useState(false)
    const [materialCount, setMaterialCount] = React.useState()
    const [number, setnumber] = useState(0)
    const handleChange = (e) => {
        setnumber(e.target.value)
    }


    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         setLoading(false)
    //         let arr = [...chartData];
    //         let newArr = [];
    //         arr.forEach(function (newData) {
    //             newData.count = Number(newData.count);
    //             newArr.push(newData);
    //             //console.log(newData)
    //         });
    //         setLoading(true)
    //         setChartData(newArr)
    //         setChartData2(newArr)

    //     }, 1000)
    //     return () => clearTimeout(timer);
    // }, []);

    useEffect(() => {
        let isMounted = true;
        const options = {
            url: window.$api + "/getMaterial",
            method: "GET",
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                //"content-Type": "application/json;charset-UTF-8"
            },
            // data: {
            //     token: props.accessToken
            // }
        };
        Axios(options).then(Response => {
            if (isMounted) {
                setMaterialCount(Response.data.length)
                let arr = [...Response.data];


                arr.forEach(function (newData) {
                    arr.push(newData);

                });
                let newArr = arr.slice(0, 6);
                setChartData(newArr)
                setNewChartData(newArr)
            }
        });
        return () => { isMounted = false };
    }, []);

    const Refresh = () => {
        //setChartData("")
        let arr = [...newChartData];
        let newArr = [];

        arr.forEach(function (newData) {
            newData.count = Number(newData.count);
            //newArr.push(newData);
            arr.push(newData);
        });
        if (number <= arr.length) {
            for (let i = 0; i < number; i++) {
                let show1 = arr[i];
                //console.log(show1)
                newArr.push(show1)
            }
        } else {
            alert('no')
        }
        //console.log(newArr)
        setChartData(newArr)

    }
    //console.log(number)

    if (props.authenticated) {
        return (
            <div>
                {/* {loading ? */}
                <div>
                    <br />
                    <Grid container>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ backgroundColor: '', textAlign: 'center', padding: '1% 2%' }}>
                            <Typography component='div'>
                                <div>ລາຍງານຂໍ້ມູນວັດຖຸດິບ</div>
                            </Typography>
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2} style={{ padding: '0 2%' }}>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ backgroundColor: '', }}>
                            <Grid container spacing={1}>
                                <Grid item xl={4} lg={4} md={4} sm={12} xs={12} style={{ backgroundColor: '', }}>
                                    <Paper className={classes.paper}>
                                        <Typography component='div'>
                                            <div>ລາຍການວັດຖຸດິບທັງໝົດ</div>
                                        </Typography>
                                        <br />
                                        <div style={{ display: 'flex', justifyContent: 'center', padding: '2%' }}>
                                            <Circle number={materialCount} />
                                        </div>
                                    </Paper>
                                </Grid>

                                <Grid item xl={8} lg={8} md={8} sm={12} xs={12} style={{ backgroundColor: '' }}>
                                    <Paper className={classes.paper} >
                                        <Grid container spacing={1}>
                                            <Grid item xl={4} lg={4} md={4} sm={4} xs={12} style={{ backgroundColor: '', }}>
                                                <Typography component='div'>
                                                    <div>ວັດຖຸດິບຍັງຫຼາຍ</div>
                                                </Typography>
                                            </Grid>
                                            <Grid item xl={4} lg={4} md={4} sm={4} xs={6} style={{ backgroundColor: '', }}>
                                                <TextField
                                                    label="ຈຳນວນຊາດທີຕ້ອງການ"
                                                    variant='outlined'
                                                    value={number}
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xl={4} lg={4} md={4} sm={4} xs={6} style={{ backgroundColor: '', }}>
                                                <Box
                                                    sx={{
                                                        display: { xl: 'flex', lg: 'flex', md: 'flex', sm: 'flex', xs: 'flex' },
                                                        justifyContent: { xl: 'flex-end', lg: 'flex-end', md: 'flex-end', sm: '', xs: '' },
                                                        padding: { xl: '2% 10%', lg: '2% 10%', md: '1% 1%', sm: '2% 1%', xs: '0 0' },
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            display: { xl: 'flex', lg: 'flex', md: 'flex', sm: 'flex', xs: 'flex' },
                                                            justifyContent: { xl: 'space-around', lg: 'flex-end', md: 'flex-end', sm: '', xs: '' },
                                                            padding: { xl: '1.8% 8%', lg: '2.2% 8%', md: '2.5% 6%', sm: '4% 6%', xs: '1.3% 6%' },
                                                            backgroundColor: '#57D65B',
                                                            borderRadius: 3,
                                                            color: '#FFFFFF',
                                                            cursor: 'pointer',
                                                            ":active": {
                                                                backgroundColor: '#BDBBB9',
                                                            },
                                                        }}>
                                                        <Typography component='div' onClick={Refresh}>
                                                            <div style={{ backgroundColor: '' }}>ດຶງຂໍ້ມູນ</div>
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>

                                        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }} >
                                            <Chart chartData={chartData} setChartData={setChartData} />
                                        </div>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <br />
                    {/* <Grid container>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6} style={{ backgroundColor: '', textAlign: 'center' }}>
                        <Box sx={{ display: { xl: 'flex', lg: 'flex', md: 'flex', sm: 'flex', xs: 'flex' }, padding: { xl: '2.5% 4%', lg: '5% 0', md: '4% 0', sm: '3% 0', xs: '' } }}>
                            <Typography component='div' >
                                <div>ວັດຖຸດິບໃນສາງທັງໝົດ</div>
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6} style={{ backgroundColor: '', textAlign: 'center' }}>
                        <Box
                            sx={{
                                display: { xl: 'flex', lg: 'flex', md: 'flex', sm: 'flex', xs: 'flex' },
                                justifyContent: { xl: 'flex-end', lg: 'flex-end', md: 'flex-end', sm: 'flex-end', xs: 'flex-end' },
                                padding: '1% 4.3%'
                            }}
                        >
                            <Box
                                sx={{
                                    display: { xl: 'flex', lg: 'flex', md: 'flex', sm: 'flex', xs: 'flex' },
                                    justifyContent: { xl: 'space-around', lg: 'space-around', md: 'space-around', sm: 'space-around', xs: 'space-around' },
                                    padding: { xl: '1.8% 8%', lg: '2.2% 8%', md: '2.5% 6%', sm: '4% 6%', xs: '1.3 % 6%' },
                                    backgroundColor: '#57D65B',
                                    borderRadius: 3,
                                    color: '#FFFFFF',
                                    cursor: 'pointer',
                                    ":active": {
                                        backgroundColor: '#BDBBB9',
                                    },
                                }}>
                                <Typography component='div' >
                                    <div style={{ backgroundColor: '' }}>ດຶງຂໍ້ມູນ</div>
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <br />
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ backgroundColor: '', textAlign: 'center', padding: '0 2%' }}>
                        <TableReport />
                    </Grid>
                </Grid> */}
                    <br /><br />
                </div>
                {/* :
                <div>
                    Loading
                </div>}  */}
            </div>
        )
    } else {
        return (
            <div>
                <Redirect
                    to={{
                        pathname: "/admin/sign-in",
                    }}
                />
            </div>
        )
    }
}