import React from 'react';
import {TextField, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, IconButton, Button } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
//import EditMaterialDiag from 'Bt_modules/Storage/EditMaterialDiag';
//import EditProductDialog from './EditProductDialog';

const columns = [
    { id: '1', label: 'ລຳດັບ', minWidth: 10 },
    { id: '2', label: 'ບາໂຄດ', minWidth: 120 },
    { id: '3', label: 'ຊື່ວັດຖຸດິບ', minWidth: 250 },
    { id: '4', label: 'ຈຳນວນ', minWidth: 50 },
    { id: '5', label: 'ຫົວໜ່ວຍ', minWidth: 150 },
    { id: '6', label: 'ຕົ້ນທຶນ', minWidth: 150 },
    { id: '7', label: 'ລາຄາ', minWidth: 100 },
    { id: '8', label: '', minWidth: 100 },
];

export default function MaterialManage(props) {
    //const [selectPartner, setSelectPartner] = useState();
    
    return (
        <div style={{padding: '2% 1%'}}>
            <Container maxWidth='xl'>
                <Paper style={{padding: "2%"}} elevation={3}>
                    <Grid container spacing={2}>
                        <Grid item sm={4} xs={12}>
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                label="ປ້ອນບາໂຄດ:"
                                fullWidth
                                value={props.search || ""}
                                onChange = {props.handleSearch}
                                onKeyPress = {props.HandleEnter}
                            /> 
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <Autocomplete
                                freeSolo
                                id="free-solo-2-demo"
                                disableClearable
                                value={props.selectName || ""}
                                onChange={(event, newValue) => {
                                    newValue &&
                                    props.setSelectName(newValue);
                                    props.handleSelectName(newValue);
                                    //setSearch("")
                                    //setSelectPartner("")
                                }}
                                options={props.data.map((option) => option.product_name ) }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="ປ້ອນຊື່ວັດຖຸດິບ:"
                                        fullWidth
                                        variant="outlined"
                                        InputProps={{ ...params.InputProps, type: 'search' }}
                                        
                                    />
                                )}
                            /> 
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <Button variant='contained' color='secondary' onClick={()=> props.setopenAddNewProductDiag(true)}>ເພີ່ມວັດຖຸດິບ</Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper style={{height: "75vh"}}>
                                <TableContainer style={{maxHeight: "75vh", borderRadius: 5}}>
                                    <Table aria-label="customized table" stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                {columns.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        align="center"
                                                        style={{ color: "white", backgroundColor: "#15023a" }}
                                                    >
                                                        {column.label}
                                                    </TableCell>  
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {props.data.map((row, index) => (
                                            <TableRow key={row.id}>
                                                <TableCell align="center">{index + 1}</TableCell>
                                                <TableCell align="center">{row.product_barcode}</TableCell>
                                                <TableCell align="center">{row.product_name}</TableCell>
                                                <TableCell align="center">{row.quantity}</TableCell>
                                                <TableCell align="center">{row.unit_name}</TableCell>    
                                                <TableCell align="center">{row.cost}</TableCell>    
                                                <TableCell align="center">{row.price_kip}</TableCell>  
                                                <TableCell>
                                                    <IconButton onClick={()=> props.handleEditProduct(row.id)}>
                                                        <EditIcon/>
                                                    </IconButton>
                                                    <IconButton onClick={()=> props.handleDelete(row.id)}>
                                                        <DeleteIcon/>
                                                    </IconButton>    
                                                </TableCell>                                               
                                            </TableRow>
                                        ))} 
                                        </TableBody> 
                                    </Table>
                                </TableContainer>                      
                            </Paper>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
            {/* <EditProductDialog 
                open={props.openEdit} 
                setOpen={props.setOpenEdit} 
                product_barcode={props.productBarcode} 
                setProductBarcode={props.setProductBarcode}
                productId={props.productId}
                data={props.data}
                setData={props.setData}
            /> */}
        </div>
    )
}
