import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { CardMedia } from "@material-ui/core";
import Appbar_Bg from 'Img/appbar_bg.jpeg'
import Banner from 'Img/banner_owt.png'
import Menu from 'Apps/Public/Menu/Menu';


const useStyles = makeStyles((theme) => ({
    root: {
        //backgroundColor: theme.palette.background.light,
        padding: '0.3% 0',
        backgroundImage: `url(${Appbar_Bg})`,
        width: '100%',
        backgroundColor: 'red',
    },
    scopBTN: {
        flex: 22,
        justifyContent: 'flex-end',
        display: 'flex',
        cursor: 'pointer'
    },
    btn: {
        backgroundColor: theme.palette.primary.main,
        padding: '0.2% 2%',
        borderRadius: 4,
        color: theme.palette.background.main
    }
}));

export default function Appbar(props) {
    const classes = useStyles();
    return (
        <div>
            <div className={classes.root}>
                <div style={{ display: 'flex', padding: '0.5% 0' }}>
                    <div style={{ flex: 0.5 }} />
                    <div className={classes.scopBTN}>
                        <div className={classes.btn}>
                            {props.t('auth.1')}
                        </div>
                    </div>
                    <div style={{ flex: 0.5 }} />
                </div>
            </div>
            <div style={{ padding: '0.2%', }} />
            <div style={{ padding: '0 2%' }}>
                <CardMedia
                    component="img"
                    image={Banner}
                    style={{ width: '100%', height: '' }}
                />
            </div>
            <div style={{ padding: '0.2%', }} />
            <div style={{ padding: '0 2%', }}>
                <Menu t={props.t} i18n={props.i18n}/>
            </div>
            <div style={{ padding: '0.2%', }} />
        </div>
    )
}
