import React from 'react'
import ProductOut from 'Bt_modules/Sale/ProductOut'

import { Redirect } from "react-router-dom";

export default function Productout(props) {
    return (
        <div >
            {props.authenticated ?
                <ProductOut accessToken={props.accessToken} userInfo={props.userInfo}/>
                :
                <Redirect
                    to={{
                        pathname: "/admin/sign-in",
                    }}
                />
            }
        </div>
    )
}

