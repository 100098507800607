import React, {useState, useEffect} from 'react';
import { Dialog, DialogContent, Typography, Grid, TextField, Button, DialogActions } from '@material-ui/core';
import Axios from 'axios';

export default function AlertDialog(props) {
  const [companyName, setCompanyName] = useState("");
  const [companyPhone, setCompanyPhone] = useState("");
  const [personal, setPersonal] = useState("");
  const [personPhone, setPersonPhone] = useState("");
  //const [type, setType] = useState("");
  const [partner, setPartner] = useState([]);

  const handleClose = () => {
    props.setOpen1(false);
  };

  const handleComapanyName = (e) =>{
    setCompanyName(e.target.value);
  };

  const handleComapanyPhone = (e) =>{
    setCompanyPhone(e.target.value);
  };

  const handlePersonal = (e) =>{
    setPersonal(e.target.value);
  };

  const handlePersonPhone = (e) =>{
    setPersonPhone(e.target.value);
  };

  // const handleType = (e) =>{
  //   setType(e.target.value);
  // };

  useEffect(()=> {
    Axios
    .get(window.$api + "/getBuyPageInfo")
    .then((res) => setPartner(res.data.partner));
  }, []);

  const hasName = (arr, name) => arr.some(el => el.companyname === name);

  const handleAddPartner = () =>{
    if(hasName(partner, companyName) === false){
      const options = {
        url: window.$api + "/insertpartner",
        method: "POST",
        headers:{
          "Accept": "application/json",
          "content-Type": "application/json;charset-UTF-8"
        },
        data: {
          "companyName":  companyName,
          "companyPhone": companyPhone,
          "personal":  personal,
          "personPhone": personPhone
        }
      };
      Axios(options).then(Response =>{
        alert(Response.data.mesg);
        var arr = [...props.dealer]
        var data = {
          "id": Response.data.id,
          "companyname":  companyName,
          "companyphone": companyPhone,
          "contactperson":  personal,
          "phoneperson": personPhone
        }
        arr.push(data)
        props.setDealer(arr)
        // const options = {
        //   url: window.$api + "/tabledatapartner"   ,
        //   method: "GET",
        //   headers:{
        //       "Accept": "application/json",
        //       "content-Type": "application/json;charset-UTF-8"
        //   },
        // };
        // Axios(options).then(Response =>{
        //   props.setDealer(Response.data);
        // });
      });
    }else{
      alert("ມີຂໍ້ມູນຢູ່ແລ້ວ");
    }
  }

  return (
    <div>
      <Dialog
        open={props.open1}
        onClose={handleClose}
      >
        <DialogContent>
        {/* {JSON.stringify(props.dealer)} */}
            <Grid container spacing={2}> 
              <Grid item xs={12} >
                <Typography variant="h5">ເພີ່ມຜູ້ສະໜອງ</Typography>
              </Grid>

            <Grid item xs={12}>
              <TextField 
                id="outlined-basic" 
                label="ຊື່ບໍລິສັດ" 
                variant="outlined"
                fullWidth
                onChange={handleComapanyName}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField 
                id="outlined-basic" 
                label="ເບີໂທ" 
                variant="outlined"
                fullWidth
                onChange={handleComapanyPhone}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField 
                id="outlined-basic" 
                label="ຊື່ຜູ້ຕິດຕໍ່" 
                variant="outlined"
                fullWidth
                onChange={handlePersonal}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField 
                id="outlined-basic" 
                label="ເບີໂທຜູ້ຕິດຕໍ່" 
                variant="outlined"
                fullWidth
                onChange={handlePersonPhone}
              />
            </Grid>

            {/* <Grid item xs={12}>
              <TextField 
                id="outlined-basic" 
                label="ປະເພດ" 
                variant="outlined"
                fullWidth
                onChange={handleType}
              />
            </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions >
          <Button onClick={()=>{handleAddPartner(); handleClose()}}>ບັນທິກ</Button>
          <Button onClick={handleClose}>ຍົກເລີກ</Button> 
        </DialogActions>
      </Dialog>
    </div>
  );
}