import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Card, TextField, Typography } from '@material-ui/core';
// import Logo from '../../../Img/logo_owt.jpeg'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import NumberFormat from 'react-number-format';
import { Redirect } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '1%',
    },
    textColor: {
        color: theme.palette.background.light
    },
    paper: {
        padding: '2%'
    },
    text: {
        fontSize: '30px',
        [theme.breakpoints.up('md')]: {
            fontSize: '50px',
        },
    }
}));

export default function Home(props) {
    const classes = useStyles();

    const cards = [
        { id: '1', name: 'ຜະລິດຕະພັນທັງໝົດ', count: '3' },
        { id: '2', name: 'ຂໍ້ມູນວັດຖຸດິບທັງໝົດ', count: '12' },
        { id: '3', name: 'ສັ່ງຊື້ທັງໝົດ', count: '35' },
        { id: '4', name: 'ສາຂາທັງໝົດ', count: '4' },
        { id: '5', name: 'ຜູ້ສະໜອງທັງໝົດ', count: '5' },
        { id: '6', name: 'ສາງທັງໝົດ', count: '4' }
    ]

    const reportsales = [
        { id: '1', name: 'ລາຍຮັບທັງໝົດ', count: '0', color: '#B3D3E5' },
        { id: '2', name: 'ລາຍຈ່າຍທັງໝົດ', count: '200,356,201,000', color: '#E5B3B3' }
    ]

    return (
        <div >
            {props.authenticated ? 
            <div className={classes.root}>
                <br />
                <Grid container spacing={2}>
                    {cards.map((row) => (
                        <Grid item xl={2} lg={2} md={2} sm={4} xs={6} key={row.id}>
                            <Card style={{ padding: '2% 5%', textAlign: 'center' }}>
                                <div className={classes.text}>
                                    {row.count}
                                </div>
                                <br />
                                <Typography component='div'>
                                    <div>{row.name}</div>
                                </Typography>

                            </Card>
                        </Grid>
                    ))}
                </Grid>

                <br /><br />
                <Grid container spacing={3}>
                    {reportsales.map((row) => (
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12} key={row.id}>
                            <Card style={{ padding: '2% 5%', textAlign: 'center', backgroundColor: row.color, color: 'black' }}>
                                <div className={classes.text}>
                                    <NumberFormat value={row.count} displayType={'text'} thousandSeparator={true} suffix={' '} />
                                </div>
                                <br />
                                <Typography component='div'>
                                    <div>{row.name} (ກີບ)</div>
                                </Typography>
                            </Card>
                        </Grid>
                    ))}
                </Grid>

                <br /><br />
                <Grid container spacing={3}>

                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} >
                        <Card style={{ padding: '2% 1%' }}>
                            <Grid container >
                                <Grid item xl={10} lg={10} md={8} sm={8} xs={12} style={{ backgroundColor: '', }}>
                                    ປະຫວັດລາຍຮັບ / ລາຍຈ່າຍປະຈຳວັນ
                                </Grid>
                                <Grid item xl={2} lg={2} md={4} sm={4} xs={12} style={{ backgroundColor: '' }}>
                                    <div style={{ backgroundColor: '', display: 'flex', justifyContent: 'flex-end', }}>
                                        <TextField
                                            id="date"
                                            //select
                                            label="ວັນທິ"
                                            type="date"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </div>
                                    <br />
                                    <div style={{ backgroundColor: '', display: 'flex', justifyContent: 'flex-end' }}>
                                        <div >
                                            <FiberManualRecordIcon style={{ color: '#A7474F' }} /> ລາຍຈ່າຍ(ກີບ)
                                        </div>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <div>
                                            <FiberManualRecordIcon style={{ color: '#599067' }} /> ລາຍຮັບ(ກີບ)
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ backgroundColor: 'pink', }}>
                                    main
                                </Grid>
                            </Grid>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ backgroundColor: '', }}>
                                main
                            </Grid>
                        </Card>
                    </Grid>


                </Grid>

            </div >
            :
            <Redirect
                    to={{
                    pathname: "/admin/sign-in",
                    }}
                />
            }
        </div >
    )
}


