import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Axios from 'axios';
import AddNewMaterial from '../AddNewProduct/AddnewMaterial';
import Backdrop from '../Backdrop/Backdrop'

export default function AddNewMaterialDiag(props) {
  const [category, setCategory] = useState([]);
  const [unit, setUnit] = useState([]);
  const [productInfo, setproductInfo] = useState({auto:"0"})
  const [addedCusPriceList, setaddedCusPriceList] = useState([])
  const [selectedPriceCatName, setselectedPriceCatName] = useState("")
  const [selectedPriceCatId, setselectedPriceCatId] = useState('')
  const [customerPriceList, setcustomerPriceList] = useState([])
  const [img, setimg] = useState("")
  const [openAddCusPriceCatDiag, setopenAddCusPriceCatDiag] = useState(false)
  const [openBackdrop, setopenBackdrop] = useState(false)
  const [priceCat, setpriceCat] = useState()
  const [autoBarcode, setAutoBarcode] = useState(false)

  useEffect(() => {
    Axios
      .get(window.$api + "/selectedcategories")
      .then((res) => {
        setUnit(res.data.unit)
        setCategory(res.data.cate)
        setcustomerPriceList(res.data.priceCat)
      }
      );
  }, []);

  const handleChangePriceCat = (e) => {
    setpriceCat(e.target.value)
  }

  const handleCloseCusPrceCatDiag = () => {
    setpriceCat('')
    setopenAddCusPriceCatDiag(false)
  }

  const handleChange = (e) => {
    setproductInfo({
      ...productInfo,
      [e.target.name]: e.target.value
    })
    //console.log(e.target.value);
  }

  const handleClose = () => {
    props.setOpen(false);
    setproductInfo({auto:"0"});
    setAutoBarcode(false);

  };

  const handleSelectCate = (id, catename) => {
    setproductInfo({
      ...productInfo,
      selectCateId: id,
      selectCateName: catename,
      selectSubCateId: "",
      selectSubCateName: ""
    })
  }

  const handleSelectUnit = (unitId, unitName) => {
    setproductInfo({
      ...productInfo,
      selectUnitId: unitId,
      selectUnitName: unitName
    })
  }

  const handleAuto = () => {
    setAutoBarcode(true);
    const options = {
      url: window.$api + "/generateAutobarcode",
      method: "GET",
      headers: {
        "Accept": "application/json",
      },

    };
    Axios(options).then(Response => {
      setproductInfo({ ...productInfo, barcode: Number(Response.data.maxbarcode) + 1, auto: "1" });
    });
  };

  const handleResetAuto = () => {
      setAutoBarcode(false);
      setproductInfo({ ...productInfo, barcode: "", auto: "0"});
  };

  const Submit = () => {
    setopenBackdrop(true)
    const options = {
      url: window.$api + "/insernewproduct",
      method: "POST",
      headers: {
        "Accept": "application/json",
      },
      data: {
        "barcode": productInfo.barcode || null,
        "auto": productInfo.auto || null,
        "productName": productInfo.productName || null,
        "category_id": "37",
        "unit_id": productInfo.selectUnitId || null,
        "price": productInfo.price || 0,
        "subPrice": productInfo.subPrice || 0,
        "cost": productInfo.cost || 0,
        "subBarcode": productInfo.subBarcode || null,
        "subNameProduct": productInfo.subNameProduct || null,
        "subQty": productInfo.subQty || 0,
        "addedCusPriceList": addedCusPriceList,
        "img": img
      },
    };
    Axios(options).then(Response => {
      if (Response.data.status === 1) {
        var arr = [...props.data]
        var data = {
          id: Response.data.product_id,
          product_barcode: productInfo.barcode,
          auto_barcode: productInfo.auto,
          product_name: productInfo.productName,
          price_kip: productInfo.price,
          quantity: 0,
          unit_id: productInfo.selectUnitId,
          unit_name: productInfo.selectUnitName,
          cost: productInfo.cost,
          category_id: "37"
        }
        arr.push(data)
        props.setData(arr)
        setproductInfo({auto:"0"})
        setaddedCusPriceList([])
        props.setOpen(false);
        setopenBackdrop(false)
      } else {
        alert(Response.data.mesg)
        setopenBackdrop(false)
      }
      // console.log(autoBarcode);
      // console.log(Response.data);
    });
  };

  const validateData = () => {
    // setproductInfo({
    //   ...productInfo,
    //   selectCateId: "37",
    //   selectCateName: "ວັດຖຸດິບ",
    //   selectSubCateId: "",
    //   selectSubCateName: ""
    // })
    // if(autoBarcode) {
    //   setproductInfo({...productInfo, auto: "1"});
    // }else{
    //   setproductInfo({...productInfo, auto: "0"});
    // }
    if (!productInfo.barcode) {
      alert("ກະລຸນາຕື່ມຂໍ້ມູນລະຫັດບາໂຄດ");
    } else {
      if (!productInfo.productName) {
        alert("ກະລຸນາຕື່ມຂໍ້ມູນຊື່ຂອງສິນຄ້າ");
      } else {
        // if (!productInfo.selectCateId) {
        //   alert("ກະລຸນາຕື່ມຂໍ້ມູນປະເພດສິນຄ້າ");
        // } else {
          if (!productInfo.cost) {
            alert("ກະລຸນາຕື່ມຂໍ້ມູນລາຄາຕົ້ນທຶນ");
          } else {
            if (!productInfo.price) {
              alert("ກະລຸນາຕື່ມຂໍ້ມູນລາຄາທົ່ວໄປ");
            } else {
              if ((productInfo.subBarcode && productInfo.subPrice && productInfo.subNameProduct && productInfo.subQty) || (!productInfo.subBarcode && !productInfo.subPrice && !productInfo.subNameProduct && !productInfo.subQty)) {
                Submit()
              }
              else if (productInfo.subBarcode || productInfo.subPrice || productInfo.subNameProduct || productInfo.subQty) {
                alert("ຂໍ້ມູນສິນຄ້າຍ່ອຍບໍ່ຄົບຖ້ວນ")
              }
            }
          }
        //}
      }
    }
  }

  const handleAddCusPriceList = () => {
    var arr = [...addedCusPriceList]

    const checkExist = (arr, id) => arr.some(el => el.id === id)

    if (checkExist(arr, selectedPriceCatId)) {
      arr.forEach((el, index) => {
        if (el.id === selectedPriceCatId) {
          el.value = priceCat
        }
      });
    } else {
      var data = {
        id: selectedPriceCatId,
        value: priceCat,
        name: selectedPriceCatName
      }

      arr.push(data)
    }

    setaddedCusPriceList(arr)
    setselectedPriceCatId('')
    setselectedPriceCatName("")
    setopenAddCusPriceCatDiag(false)
  }

  const handleChangeAddedCusPriceList = (e) => {
    var arr = [...addedCusPriceList]

    arr.forEach(el => {
      if (el.id === Number(e.target.id)) {
        el.value = e.target.value
      }
    });

    setaddedCusPriceList(arr)
  }

  const handleSelectCustomerCat = (id, name) => {
    setselectedPriceCatId(id)
    setselectedPriceCatName(name)
  }

  const handleOpenCusPriceCatDiag = () => {
    setopenAddCusPriceCatDiag(true)
  }

  const onChangeImg = (e) => {
    if (e.target.files[0]) {
      var fileReader = new FileReader();
      fileReader.onload = function (fileLoadedEvent) {
        var srcData = fileLoadedEvent.target.result; // <--- data: base64
        setimg(srcData)
      }
      fileReader.readAsDataURL(e.target.files[0]);
    }
  }

  return (
    <div>
      <Dialog
        maxWidth='xl'
        fullWidth={true}
        open={props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <AddNewMaterial
            text="test"
            category={category}
            setCategory={setCategory}
            unit={unit}
            setUnit={setUnit}
            handleSelectCate={handleSelectCate}
            handleAuto={handleAuto}
            handleResetAuto={handleResetAuto}
            autoBarcode={autoBarcode}
            productInfo={productInfo}
            handleChange={handleChange}
            handleSelectUnit={handleSelectUnit}
            addedCusPriceList={addedCusPriceList}
            selectedPriceCatName={selectedPriceCatName}
            handleAddCusPriceList={handleAddCusPriceList}
            openAddCusPriceCatDiag={openAddCusPriceCatDiag}
            handleCloseCusPrceCatDiag={handleCloseCusPrceCatDiag}
            handleOpenCusPriceCatDiag={handleOpenCusPriceCatDiag}
            handleChangeAddedCusPriceList={handleChangeAddedCusPriceList}
            handleSelectCustomerCat={handleSelectCustomerCat}
            customerPriceList={customerPriceList}
            onChangeImg={onChangeImg}
            img={img}
            priceCat={priceCat}
            handleChangePriceCat={handleChangePriceCat}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={validateData}
            autoFocus
          >
            ບັນທຶກ
          </Button>
          <Button
            variant="contained"
            style={{ backgroundColor: "#FF0000", color: "#FFFFFF" }}
            onClick={handleClose}
          >
            ຍົກເລີກ
          </Button>
        </DialogActions>
        <Backdrop open={openBackdrop} />
      </Dialog>
    </div>
  );
}