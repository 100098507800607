import React, { useState, useRef, useEffect } from 'react'
import {Grid, Typography, TextField, Button, Paper, Fab} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Axios from 'axios'
import SaleList from 'Bt_modules/Sale/SaleList'
import NumberFormat from 'react-number-format';
import SalePrint from 'Bt_modules/Sale/ProductOutPrint';
import ReactToPrint from 'react-to-print';
import PrintIcon from '@material-ui/icons/Print';

const useStyles = makeStyles((theme) => ({
    root: {
        //height: "100vh"
    }
  }));

export default function Productout(props) {
    const classes = useStyles();

    const [selectedRetailer, setSelectedRetailer] = useState()
    const [retailerList, setRetailerList] = useState([])
    const barcodeAutoFocus = useRef();
    const cumtomerAutoFocus = useRef();
    const [billcode, setBillcode] = useState("")
    //const [customerPriceList, setcustomerPriceList] = useState([])
    const [promotionList, setpromotionList] = useState([])
    const [selectedPriceCatId, setselectedPriceCatId] = useState('999')
    const [tableData, setTableData] = useState([]);
    const [cash, setCash] = useState();
    //const [cashF, setCashF] = useState(0);
    const [change, setChange] = useState("");
    const [name, setName] = useState();
    const [barcode, setBarcode] = useState();
    const [allQty, setAllQty] = useState(0);
    const [total, setTotal] = useState(0);
    //const [qty, setQty] = useState(0);
    const [discount, setdiscount] = useState(0)
    const [billDiscRate, setBillDiscRate] = useState(0)
    const [billAddQty, setBillAddQty] = useState(0)
    const [billAddProduct, setBillAddProduct] = useState(0)
    const [billAddProductBarcode, setBillAddProductBarcode] = useState("")
    //const [price, setPrice] = useState(0);
    const [newQty, setNewQty] = useState();
    const [isEditable, setEditable] = useState();
    const componentRef = useRef();
    const [selectedStaf, setSelectedStaf] = useState();
    const [stafList, setStafList] = useState([])

    useEffect(() => {
        cumtomerAutoFocus.current.focus()
        if (props.accessToken) {
            const options = {
                url: window.$api + "/getSaleInfo",
                method: "GET",
                headers: {
                    "Accept": "application/json",
                    //"content-Type": "application/json;charset-UTF-8"
                },
            }
            Axios(options).then(res => {
                if(res.data.billcode) {
                    setBillcode(Number(res.data.billcode) + 1);
                } else {
                    setBillcode(1);
                }
                // if(res.data.promotionList) {
                //     setpromotionList(res.data.promotionList);
                // }
                //setcustomerPriceList(res.data.priceCatList)
                //setpromotionCatList(res.data.promotionCatList)
                //setRetailerList(res.data.retailerList)
                //console.log(res.data.promotionList);
            })
        }
    }, [props.accessToken])

    // const handleType = (e) => {
    //     let text = e.target.value;
    //     if(text){
    //         const options = {
    //             url: window.$api + "/searchRetailer",
    //             method: "POST",
    //             withCredentials: true,
    //             headers:{
    //                 "Accept": "application/json",
    //             },
    //             data: {
    //                 key: e.target.value
    //             }
    //         };
    //         Axios(options).then(res => {
    //             if(res.data.retailerList) {
    //                 setRetailerList(res.data.retailerList);
    //             } else {
    //                 setRetailerList([]);
    //             }
                
    //         })
    //     }else{
    //         setRetailerList([]);
    //     }
    // };

    const handleType = (e) => {
        let text = e.target.value;
        if(text){
            const options = {
                url: window.$api + "/searchStaf",
                method: "POST",
                withCredentials: true,
                headers:{
                    "Accept": "application/json",
                },
                data: {
                    key: e.target.value
                }
            };
            Axios(options).then(res => {
                if(res.data.retailerList) {
                    setStafList(res.data.retailerList);
                } else {
                    setStafList([]);
                }
                
            })
        }else{
            setStafList([]);
        }
    };

    const handleSelectPriceCat = (priceId, name, id, cat, phone) => {
        const options = {
            url: window.$api + "/getPromo",
            method: "POST",
            withCredentials: true,
            headers:{
                "Accept": "application/json",
            }
        };
        Axios(options).then(res => {
            if(res.data.promotionList) {
                setpromotionList(res.data.promotionList);
            } 
        })
        setSelectedRetailer({"name": name, "id": id, "cat": cat, "phone": phone})
        setselectedPriceCatId(priceId)
        setTableData([])
        setRetailerList([])
        setCash("")
        setChange("")
        setName('')
        setBarcode("")
        setAllQty('')
        setTotal('')
    }
    const ResetStates = () => {
        setSelectedRetailer("")
        setselectedPriceCatId("")
        setRetailerList([])
        setTableData([])
        setCash("")
        setChange("")
        setName('')
        setBarcode("")
        setAllQty('')
        setTotal('')
        setBillDiscRate("");
        setdiscount(0);
        setBillAddProduct("");
        setpromotionList([]);
        setBillAddQty(0);
    }

    const SelectProduct = (code, name) => {
        barcodeAutoFocus.current.focus();
        setBarcode(code);
        setName(name);
    }
    const ResetSelectedStaf = () => {
        setSelectedStaf("");
    }
    const handleSelectStaf = (name, id, phone) => {
        setSelectedStaf({"name": name, "id": id, "phone": phone})
    }
    const handleBarcode = (e) => {
        setBarcode(e.target.value)
    }
    // const addCommas = (num) =>
    //     num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // const removeNonNumeric = (num) =>
    //     num.toString().replace(/[^0-9]/g, "");
    const handleCash = (e) => {
        //setCash(addCommas(removeNonNumeric(e.target.value)));
        setCash(e.target.value);
    }
    const HandleChange = (e) => {
        if(e.key === 'Enter') {
            setChange(parseInt(cash.replace(/,/g, '')) - (total - (discount + ((total * billDiscRate)/100))));
        }
    }

    const hasBarcode = (arr, barcode) => arr.some(el => el.barcode === barcode);

    const CalculateProductPromo = (productBarcode, productQty, price) => {
        let toAddQty = 0
        let productToAdd = ""
        let productToAddBarcode = ""
        let discount = 0
        let discount_rate = 0
        if(promotionList.length > 0) {
            promotionList.map((promo) => {
                switch(promo.type_id) {
                    case 7:
                        if (promo.product_barcode === productBarcode && productQty >= promo.sold_qty) {
                            let cal = productQty / promo.sold_qty
                            let added_qty = Math.trunc(cal) * promo.free_qty
                            toAddQty = added_qty
                            switch(promo.free_barcode) {
                                case "99000001":
                                    productToAdd = "ນໍ້າດື່ມ 230ml";
                                    break;
                                case "99000002":
                                    productToAdd = "ນໍ້າດື່ມ 350ml";
                                    break;
                                case "99000003":
                                    productToAdd = "ນໍ້າດື່ມ 600ml";
                                    break;
                                case "99000004":
                                    productToAdd = "ນໍ້າດື່ມ 1500ml";
                                    break;
                                default:
                                    break;
                            }
                            //productToAdd = promo.free_barcode
                            productToAddBarcode = promo.free_barcode
                        }
                        break;
                    case 9:
                        if (promo.product_barcode === productBarcode && productQty >= promo.sold_qty) {
                            let cal = productQty / promo.sold_qty
                            discount = Math.trunc(cal) * promo.discount_amount;
                        }
                        break;
                    case 11:
                        if (promo.product_barcode === productBarcode && productQty >= promo.sold_qty) {
                            discount_rate = promo.discount_rate;
                            discount = ((price * productQty) * discount_rate) / 100;
                        }
                        break;
                    default:
                        break;
                }
                return {toAddQty, productToAdd, productToAddBarcode, discount, discount_rate}
            }
            )
            return {toAddQty, productToAdd, productToAddBarcode, discount, discount_rate}
        } else {
            return {toAddQty, productToAdd, productToAddBarcode, discount, discount_rate}
        }
    }

    const CalculateBillPromo = (totalAmount) => {
        let billDiscount = 0;
        let billDiscountRate = 0;
        let billToAddQty = 0;
        let billProductToAdd = 0;
        let billProductToAddBarcode = "";
        if(promotionList.length > 0) {
            promotionList.map((promo) => {
                switch(promo.type_id) {
                    case 8:
                        if (totalAmount >= promo.total_bill_price) {
                            let cal = totalAmount / promo.total_bill_price
                            let added_qty = Math.trunc(cal) * promo.free_qty
                            billToAddQty = added_qty
                            switch(promo.free_barcode) {
                                case "99000001":
                                    billProductToAdd = "ນໍ້າດື່ມ 230ml";
                                    break;
                                case "99000002":
                                    billProductToAdd = "ນໍ້າດື່ມ 350ml";
                                    break;
                                case "99000003":
                                    billProductToAdd = "ນໍ້າດື່ມ 600ml";
                                    break;
                                case "99000004":
                                    billProductToAdd = "ນໍ້າດື່ມ 1500ml";
                                    break;
                                default:
                                    break;
                            }
                            //billProductToAdd = promo.free_barcode
                            billProductToAddBarcode = promo.free_barcode
                        }
                        break;
                    case 10:
                        if (totalAmount >= promo.total_bill_price) {
                            let cal = totalAmount / promo.total_bill_price
                            billDiscount = Math.trunc(cal) * promo.discount_amount;
                        }
                        break;
                    case 12:
                        if (totalAmount >= promo.total_bill_price) {
                            billDiscountRate = promo.discount_rate;
                            //billDiscount = ((totalAmount) * billDiscountRate) / 100;
                        }
                        break;
                    default:
                        break;
                }
                return {billDiscount, billDiscountRate, billToAddQty, billProductToAdd, billProductToAddBarcode}
            }
            )
            return {billDiscount, billDiscountRate, billToAddQty, billProductToAdd, billProductToAddBarcode}
        } else {
            return {billDiscount, billDiscountRate, billToAddQty, billProductToAdd, billProductToAddBarcode}
        }
    }

    const HandleEnter = (e) => {
        if (e.key === 'Enter') {
            if (selectedPriceCatId) {
                if (barcode) {
                    const options = {
                        url: window.$api + "/getProductInfo",
                        method: "POST",
                        headers: {
                            "Accept": "application/json",
                        },
                        data: {
                            barcode: barcode,
                            price_id: "999"
                        },
                    }
                    Axios(options).then(Response => {
                        switch (Response.data.status) {
                            case 0:
                                alert(Response.data.mesg);
                                setBarcode("");
                                break;
                            case 1:
                                alert(Response.data.mesg);
                                setBarcode("");
                                break;

                            case 2:
                                let product_barcode = Response.data.product.product_barcode;
                                let price = Number(Response.data.product.price_kip);
                                let current_qty = Number(Response.data.product.quantity);
                                let product_name = Response.data.product.product_name
                                let product_id = Response.data.product.id

                                //let total_bill_price = 0

                                if (hasBarcode(tableData, product_barcode)) {
                                    let newArr = tableData.map((obj) => {
                                        if (obj.barcode && obj.barcode === product_barcode) {
                                            if (obj.qty < current_qty) {
                                                obj.qty = obj.qty + 1
                                                let { toAddQty, productToAdd, productToAddBarcode, discount, discount_rate } = CalculateProductPromo(product_barcode, obj.qty, price);
                                                obj.added_qty = toAddQty
                                                obj.add_product_name = productToAdd
                                                obj.add_product_barcode = productToAddBarcode
                                                obj.discount = discount
                                                obj.discount_rate = discount_rate
                                                obj.total = (price * obj.qty) - discount
                                                //setQty(obj.qty);
                                            }
                                            else {
                                                alert('ສິນຄ້າບໍ່ພໍ');
                                            }
                                        }
                                        return obj;
                                    })
                                    setTableData(newArr);
                                    //setName(product_name);
                                    let billTotal = newArr.reduce((prev, next) => prev + next.total, 0);
                                    setTotal(billTotal);
                                    let {billDiscount, billDiscountRate, billToAddQty, billProductToAdd, billProductToAddBarcode} = CalculateBillPromo(billTotal);
                                    setdiscount(billDiscount);
                                    setBillAddQty(billToAddQty);
                                    setBillAddProduct(billProductToAdd);
                                    setBillAddProductBarcode(billProductToAddBarcode);
                                    setBillDiscRate(billDiscountRate);
                                    setCash("");
                                    setChange("");
                                    //setBarcode("");
                                    setAllQty(newArr.reduce((prev, next) => prev + next.qty, 0));
                                } else {
                                    if (current_qty > 0) {
                                        let newArray = [...tableData];
                                        let price = Number(Response.data.product.price_kip);
                                        let { toAddQty, productToAdd, productToAddBarcode, discount, discount_rate } = CalculateProductPromo(product_barcode, 1, price);
                                        var tempdata = {
                                            id: product_id,
                                            barcode: product_barcode,
                                            name: product_name,
                                            qty: 1,
                                            current_qty: current_qty,
                                            price: price,
                                            total: price - discount,
                                            //sold_qty: 0,
                                            //add_qty: toAddQty,
                                            added_qty: toAddQty,
                                            discount: discount,
                                            total_sale: 0,
                                            discount_rate: discount_rate,
                                            //add_product_qty: 0,
                                            add_product_name: productToAdd,
                                            add_product_barcode: productToAddBarcode,
                                            bill_status: "3"
                                        }
                                        newArray.push(tempdata);
                                        setTableData(newArray);
                                        //setBarcode("");
                                        //setPrice(price);
                                        //setQty(1);
                                        setAllQty(newArray.reduce((prev, next) => prev + next.qty, 0))
                                        let billTotal = newArray.reduce((prev, next) => prev + next.total, 0);
                                        setTotal(billTotal);
                                        //setTotal(newArray.reduce((prev, next) => prev + next.total, 0));
                                        let {billDiscount, billDiscountRate, billToAddQty, billProductToAdd, billProductToAddBarcode} = CalculateBillPromo(billTotal);
                                        setdiscount(billDiscount);
                                        setBillAddQty(billToAddQty);
                                        setBillAddProduct(billProductToAdd);
                                        setBillAddProductBarcode(billProductToAddBarcode);
                                        setBillDiscRate(billDiscountRate);
                                        //setName(Response.data.product.product_name);
                                        setCash("");
                                        setChange("");
                                    } else {
                                        alert('ສິນຄ້າບໍ່ພໍ');
                                        setBarcode("");
                                    }
                                }
                                break;
                            default:
                                break;
                        }
                    });
                } else {
                    alert('ກະລຸນາປ້ອນບາໂຄດ')
                }
            } else {
                alert('ກະລຸນາເລືອກປະເພດລາຄາ')
            }
        }
    }

    const handleDelete = (id, qty) => {
        let newArr = [...tableData]
        newArr = newArr.filter(update => update.id !== id);
        setTableData(newArr);

        // if (selectedSubPromoCatId === 4) {
        //     let promoDetail = promotionDetailList[0]

        //     let total_bill_price = newArr.reduce((prev, next) => prev + next.total, 0)

        //     if (total_bill_price >= promoDetail.total_bill_price) {
        //         var dis = (promoDetail.discount_rate * total_bill_price) / 100
        //         setdiscount(dis)
        //         setTotal(total_bill_price)
        //     } else {
        //         setdiscount(0)
        //         setTotal(total_bill_price)
        //     }
        // } else {
        //     setTotal(newArr.reduce((prev, next) => prev + next.total, 0));
        // }

        setName("");
        //setQty(0);
        //setPrice(0);
        setChange("");
        setCash("");
        setAllQty(Number(allQty) - Number(qty));
    }

    const handleInput = (e, index) => {
        if (e.key === 'Enter') {

            let newArr = [...tableData]
            //let total_bill_price = 0

            if (newArr[index].current_qty >= Number(newQty)) {
                //let { toAddQty, productToAdd, productToAddBarcode } = addPromotion(Number(newQty), newArr[index].barcode)
                //let { total, discount } = discountPromotion(newArr[index].price * Number(newQty), newArr[index].barcode)

                //newArr[index].qty = Number(newQty)
                //newArr[index].total = newArr[index].price * Number(newQty)
                // newArr[index].total = total
                // newArr[index].discount = discount
                // newArr[index].added_qty = toAddQty
                // newArr[index].add_product_name = productToAdd
                // newArr[index].add_product_barcode = productToAddBarcode

                // if (selectedSubPromoCatId === 4) {
                //     let promoDetail = promotionDetailList[0]

                //     total_bill_price = newArr.reduce((prev, next) => prev + next.total, 0)

                //     if (total_bill_price >= promoDetail.total_bill_price) {
                //         var dis = (promoDetail.discount_rate * total_bill_price) / 100
                //         setdiscount(dis)
                //         setTotal(total_bill_price)
                //     } else {
                //         setdiscount(0)
                //         setTotal(total_bill_price)
                //     }
                // } else {
                //     setTotal(newArr.reduce((prev, next) => prev + next.total, 0));
                // }

                // if (selectedSubPromoCatId === 2) {
                //     let promoDetail = promotionDetailList[0]
                //     total_bill_price = newArr.reduce((prev, next) => prev + next.total, 0)

                //     if (total_bill_price >= promoDetail.total_bill_price) {
                //         var cal = total_bill_price / promoDetail.total_bill_price
                //         var added_qty = Math.trunc(cal) * promoDetail.add_qty

                //         const hasAddedProduct = newArr.some(el => el.add_product_barcode === promoDetail.add_product_barcode);

                //         if (hasAddedProduct) {
                //             newArr.forEach(el => {
                //                 if (el.add_product_barcode === promoDetail.add_product_barcode) {
                //                     el.added_qty = added_qty
                //                 }
                //             })
                //         } else {
                //             var data = {
                //                 barcode: "",
                //                 name: "",
                //                 added_qty: added_qty,
                //                 qty: 0,
                //                 current_qty: 0,
                //                 price: 0,
                //                 total: 0,
                //                 sold_qty: 0,
                //                 add_qty: 0,
                //                 discount: 0,
                //                 total_sale: 0,
                //                 discount_rate: 0,
                //                 add_product_qty: 0,
                //                 add_product_name: promoDetail.product_name,
                //                 add_product_barcode: promoDetail.add_product_barcode
                //             }
                //             newArr.push(data)
                //         }
                //     } else {
                //         newArr = newArr.filter(el => el.barcode !== '')
                //     }
                // }

                setTableData(newArr)
                setEditable("");
                setName(newArr[index].product_name);
                setNewQty("");
                setAllQty(newArr.reduce((prev, next) => prev + next.qty, 0))

                //setTotal(props.tableData.reduce((prev, next) => prev + next.total, 0));
            } else {
                alert('ສິນຄ້າບໍ່ພຽງພໍ')
            }
        }
    }

    const handleSale = () => {
        const options = {
            url: window.$api + "/sale",
            method: "POST",
            headers: {
                "Accept": "application/json",
            },
            data: {
                tableData: tableData,
                price_id: selectedPriceCatId,
                total: total,
                discount: discount,
                billAddProduct: billAddProductBarcode,
                billAddQty: billAddQty,
                billDiscRate: billDiscRate,
                customer_id: "",
                billcode: billcode,
                billSatus: "3",
                staffId: selectedStaf.id,
                token: props.accessToken
            },
        }
        Axios(options).then(res => {
            if(res.data.status === 200){
                alert(res.data.mesg);
                setTableData([]);
                setCash("");
                setChange("");
                setTotal("");
                setAllQty("");
                setName("");
                setBillDiscRate("");
                setSelectedRetailer("");
                setBarcode("");
                setdiscount(0);
                setBillAddProduct("");
                setBillAddQty(0);
                setpromotionList([])
                setBillcode(billcode + 1)
            }
        });
    }

  return (
    <Grid container className={classes.root}>
        <Grid container item xs={2} style={{alignItems: "flex-start"}}>
            <Grid container item xs={12} style={{marginTop: 5}}>
                <Grid container item xs={6} style={{height: '35%', justifyContent: 'center'}} onClick={() => SelectProduct("99000001", "ນໍ້າດື່ມ 230ml")}>
                    <Paper style={{textAlign: "center", margin: 2}}>
                        <img src={window.$img + 'odw230ml.jpeg'} width='90%' height='80%' alt="#"/>
                        <Typography>230ml</Typography>
                    </Paper>
                </Grid>
                <Grid container item xs={6} style={{height: '35%', justifyContent: 'center'}} onClick={() => SelectProduct("99000002", "ນໍ້າດື່ມ 350ml")}>
                    <Paper style={{textAlign: "center", margin: 2}}>
                        <img src={window.$img + 'odw350ml.jpeg'} width='90%' height='80%' alt="#"/>
                        <Typography>350ml</Typography>
                    </Paper>
                </Grid>
                <Grid container item xs={6} style={{height: '35%', justifyContent: 'center'}} onClick={() => SelectProduct("99000003", "ນໍ້າດື່ມ 600ml")}>
                    <Paper style={{textAlign: "center", margin: 2}}>
                        <img src={window.$img + 'odw600ml.jpeg'} width='90%' height='80%' alt="#"/>
                        <Typography>600ml</Typography>
                    </Paper>
                </Grid>
                <Grid container item xs={6} style={{height: '35%', justifyContent: 'center'}} onClick={() => SelectProduct("99000004", "ນໍ້າດື່ມ 1500ml")}>
                    <Paper style={{textAlign: "center", margin: 2}}>
                        <img src={window.$img + 'odw1500ml.jpeg'} width='90%' height='80%' alt="#"/>
                        <Typography>1500ml</Typography>
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
        <Grid container item xs={10} direction="row" style={{padding: 15, alignItems: "flex-start"}}>
            <Grid container>
                {/* <Grid item xs={6}>
                    {selectedRetailer ?
                        <> 
                            <TextField
                            variant="outlined"
                            color="secondary"
                            label='ລູກຄ້າ'
                            value={selectedRetailer.id + " " + selectedRetailer.name + " - " + selectedRetailer.cat + " - " + selectedRetailer.phone}
                            disabled
                            style={{width: '70%', marginRight: 5}}
                            inputRef={cumtomerAutoFocus}
                            >
                            </TextField>
                            <Button variant="contained" style={{height: '100%', backgroundColor: "#ADD8E6"}} onClick={ResetStates}>ເລີ່ມໃໝ່</Button>
                        </>
                    :
                        <Autocomplete
                        freeSolo
                        disableClearable
                        onChange={(event, newValue) => {
                            handleSelectPriceCat(newValue.cust_price_cat_id, newValue.name, newValue.id, newValue.price_cat_name, newValue.phone);
                        }}
                        options={retailerList}
                        getOptionLabel={option => (option.id + ' ' + option.name + ' ' + option.firstname + ' ' + option.lastname + ' ' + option.phone)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="ຄົ້ນຫາລູກຄ້າ"
                                style={{width: '98%'}}
                                variant="outlined"
                                color="secondary"
                                InputProps={{ ...params.InputProps, type: 'search' }}
                                onChange={(event) => {handleType(event)}}
                                inputRef={cumtomerAutoFocus}
                            />
                        )}
                        />
                    }
                </Grid> */}
                <Grid item xs={4}>
                        {selectedStaf ?
                            <> 
                                <TextField
                                variant="outlined"
                                color="secondary"
                                label='ພະນັກງານ'
                                value={selectedStaf.id + " " + selectedStaf.name + " - " + selectedStaf.phone}
                                disabled
                                style={{width: '70%', marginRight: 5}}
                                inputRef={cumtomerAutoFocus}
                                >
                                </TextField>
                                <Button variant="contained" style={{height: '100%', backgroundColor: "#ADD8E6"}} onClick={ResetSelectedStaf}>ເລືອກໃໝ່</Button>
                            </>
                        :
                            <Autocomplete
                            freeSolo
                            disableClearable
                            onChange={(event, newValue) => {
                                handleSelectStaf(newValue.firstname, newValue.id, newValue.phone);
                            }}
                            options={stafList}
                            getOptionLabel={option => (option.id + ' ' + option.firstname + ' ' + option.lastname + ' ' + option.phone)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="ພະນັກງານ"
                                    style={{width: '98%'}}
                                    variant="outlined"
                                    color="secondary"
                                    InputProps={{ ...params.InputProps, type: 'search' }}
                                    onChange={(event) => {handleType(event)}}
                                    inputRef={cumtomerAutoFocus}
                                />
                            )}
                            />
                        }
                    </Grid>
                <Grid item xs={3}>
                    <TextField
                        label="ບາໂຄດ:"
                        variant="outlined"
                        color="secondary"
                        style={{width: '98%'}}
                        inputRef={barcodeAutoFocus}
                        value={barcode || ""}
                        onKeyPress={HandleEnter}
                        onChange={handleBarcode}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        label="ຊື່ສິນຄ້າ:"
                        variant="outlined"
                        color="secondary"
                        value={name || ""}
                        style={{width: '98%'}}
                    />
                </Grid>
                <Grid item xs={1}>
                    <TextField
                        label="ເລກບິນ"
                        variant="outlined"
                        color="secondary"
                        value={billcode || ""}
                        style={{width: '98%'}}
                    />
                </Grid>
            </Grid>
            <Grid container direction="row" style={{alignItems: "flex-start"}}>
                <Grid container item xs={9} style={{marginTop: 5, alignItems: "flex-start"}}>
                    {tableData.length > 0 ?
                        <Grid item lg={12} md={12} xs={12} sm={12}>
                            <SaleList
                                tableData={tableData}
                                setTableData={setTableData}
                                setTotal={setTotal}
                                total={total}
                                setName={setName}
                                //setQty={setQty}
                                //setPrice={setPrice}
                                setAllQty={setAllQty}
                                allQty={allQty}
                                setCash={setCash}
                                setChange={setChange}
                                setNewQty={setNewQty}
                                isEditable={isEditable}
                                setEditable={setEditable}
                                handleInput={handleInput}
                                handleDelete={handleDelete}
                            />
                        </Grid>
                        :
                        null
                    }
                    {tableData.length > 0 && selectedPriceCatId ?
                    <Grid container >
                        <Grid container item xs={12} style={{height: 30}}></Grid>
                        <Grid container item xs={10} ></Grid>
                        <Grid container item xs={2} style={{justifyContent: "flex-end"}}>
                            <Grid item>
                                <ReactToPrint
                                    trigger={() => { return <Fab color="secondary" className={classes.fab}><PrintIcon /></Fab> }}
                                    content={() => componentRef.current}
                                    onAfterPrint={handleSale}
                                />
                                <div hidden={true}>
                                    <SalePrint tableData={tableData} total={total} discount={discount} change={change} cash={cash} billDiscRate={billDiscRate} billAddQty={billAddQty} billAddProduct={billAddProduct} selectedRetailer={selectedRetailer} sellerName={props.userInfo.username} billcode={billcode} componentRef={componentRef} selectedStaf={selectedStaf}/>
                                </div>
                                {/* <Button variant="contained" style={{height: '100%', backgroundColor: "#ADD8E6"}} onClick={handleSale}>ສ້າງຄໍາສັ່ງຊື້</Button> */}
                            </Grid>
                        </Grid>
                    </Grid>
                    :
                    null
                }
                </Grid>
                <Grid container item xs={3} style={{alignItems: "flex-start", padding: 5}}>
                    {promotionList.length > 0 &&
                        <Grid container item direction='row' style={{alignItems: "flex-start"}}>
                            <Grid item xs={12} style={{ backgroundColor: "#ADD8E6"}}>
                                <Typography style={{textAlign: "center"}}>ໂປໂມຊັນ</Typography>
                            </Grid>
                            {promotionList.map((promo, index) =>
                                <Grid item xs={12} key = {promo.id} style={{padding: 5}}>
                                    <Typography style={{color: "blue"}}>{(index + 1) + ". " + promo.promo_name}</Typography>
                                </Grid> 
                            )}
                        </Grid>
                    }
                    {/* <Grid container item>
                        <Grid item xs={12} style={{ backgroundColor: "#ADD8E6", marginTop: 30}}>
                            <Typography style={{textAlign: "center"}}>ສະຫຼຸບ</Typography>
                        </Grid>
                        <Grid item xs={6} style={{ padding: "5%"}}>
                            <Typography>ຍອດບິນ</Typography>
                        </Grid>
                        <Grid item xs={6} style={{ paddingTop: "5%", paddingLeft: "1%"}}>
                            <Typography style={{textAlign: "end"}}>
                                <NumberFormat value={total || 0} displayType={'text'} thousandSeparator={true} suffix={' ກີບ'} />
                            </Typography>
                        </Grid>
                        {billDiscRate > 0 &&
                        <>
                            <Grid item xs={4} style={{ padding: "5%"}}>
                                <Typography>ສ່ວນຫຼຸດ%</Typography>
                            </Grid>
                            <Grid item xs={2} style={{ paddingTop: "5%", paddingLeft: "1%"}}>
                                <Typography style={{textAlign: "end"}}>
                                    <NumberFormat value={billDiscRate || 0} displayType={'text'} thousandSeparator={true} suffix={' %'} />
                                </Typography>
                            </Grid>
                            <Grid item xs={6} style={{ paddingTop: "5%", paddingLeft: "1%"}}>
                                <Typography style={{textAlign: "end"}}>
                                    <NumberFormat value={(total * billDiscRate)/100 || 0} displayType={'text'} thousandSeparator={true} suffix={' ກີບ'} />
                                </Typography>
                            </Grid>
                        </>
                        }
                        {discount > 0 &&
                        <>
                            <Grid item xs={6} style={{ padding: "5%"}}>
                                <Typography>ສ່ວນຫຼຸດ</Typography>
                            </Grid>
                            <Grid item xs={6} style={{ paddingTop: "5%", paddingLeft: "1%"}}>
                                <Typography style={{textAlign: "end"}}>
                                    <NumberFormat value={discount || 0} displayType={'text'} thousandSeparator={true} suffix={' ກີບ'} />
                                </Typography>
                            </Grid>
                        </>
                        }
                        {billAddQty > 0 &&
                        <>
                            <Grid item xs={3} style={{ padding: "5%"}}>
                                <Typography>ແຖມ</Typography>
                            </Grid>
                            <Grid item xs={9} style={{ paddingTop: "5%", paddingLeft: "1%"}}>
                                <Typography style={{textAlign: "end"}}>
                                    {billAddProduct + " x " + billAddQty}
                                </Typography>
                            </Grid>
                        </>
                        }
                        <Grid item xs={6} style={{ padding: "5%"}}>
                            <Typography>ລາຄາລວມ</Typography>
                        </Grid>
                        <Grid item xs={6} style={{ paddingTop: "5%", paddingLeft: "1%"}}>
                            <Typography style={{textAlign: "end"}}>
                                <NumberFormat value={total - (discount + ((total * billDiscRate)/100))} displayType={'text'} thousandSeparator={true} suffix={' ກີບ'} />
                            </Typography>
                        </Grid>
                        <Grid item xs={6} style={{ padding: "5%"}}>
                            <Typography>ຮັບເງິນ</Typography>
                        </Grid>
                        <Grid item xs={6} style={{ paddingTop: "5%", paddingLeft: "1%"}}>
                            <Typography style={{textAlign: "end"}}>
                                <NumberFormat value={cash} displayType={'input'} thousandSeparator={true} suffix={' ກີບ'} onChange={handleCash} onKeyDown={HandleChange} style={{ width: "98%" }}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} style={{ padding: "5%"}}>
                            <Typography>ເງິນທອນ</Typography>
                        </Grid>
                        <Grid item xs={6} style={{ paddingTop: "5%", paddingLeft: "1%"}}>
                            <Typography style={{textAlign: "end"}}>
                                <NumberFormat value={change} displayType={'text'} thousandSeparator={true} suffix={' ກີບ'}/>
                            </Typography>
                        </Grid>
                    </Grid> */}
                </Grid>
            </Grid>
        </Grid>
    </Grid>
  )
}
