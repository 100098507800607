import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Axios from 'axios';

import AddNewProduct from 'Bt_modules/AddNewProduct/AddnewProduct';

export default function AlertDialog(props) {
  const [category, setCategory] = useState([]);
  const [unit, setUnit] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [barcode, setBarcode] = useState("");
  const [productName, setProductName] = useState("");
  const [selectCateId, setSelectCateId] = useState("");
  const [selectCateName, setSelectCateName] = useState("");
  const [selectUnitId, setSelectUnitId] = useState("");
  const [selectUnitName, setSelectUnitName] = useState("");
  const [selectSubCateId, setSelectSubCateId] = useState("");
  const [selectSubCateName, setSelectSubCateName] = useState("");
  const [cost, setCost] = useState("");
  const [subPrice, setSubPrice] = useState("");
  const [subBarcode, setSubBarcode] = useState("");
  const [subNameProduct, setSubNameProduct] = useState("");
  const [subQty, setSubQty] = useState("");

  useEffect(()=> {
    Axios
    .get(window.$api + "/selectedcategories")
    .then((res) => {
      setUnit(res.data.unit)
      setSubCategory(res.data.subcat)
      setCategory(res.data.cate)
    }
    );
  }, []);

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleSelectCate = (id, catename) =>{
    const options = {
      url: window.$api + "/selectedsubcate"   ,
      method: "POST",
      headers:{
        "Accept": "application/json",
        //"content-Type": "application/json;charset-UTF-8"
      },
      data: {
        "cat_id" : id
      },
    };
    Axios(options).then(Response =>{
      setSelectSubCateId("")
      setSelectSubCateName("")
      setSubCategory(Response.data);
      setSelectCateId(id)
      setSelectCateName(catename)
    });
  }

  const handleAuto = () =>{
    const options = {
      url: window.$api + "/inserautobarcode"   ,
      method: "GET",
      headers:{
        "Accept": "application/json",
        "content-Type": "application/json;charset-UTF-8"
      },
      
    };
    Axios(options).then(Response =>{
      setBarcode(Number(Response.data.maxbarcode) + 1);
    });                
  };

  const Submit = () => {
    const options = {
        url: window.$api + "/insernewproduct"   ,
        method: "POST",
        headers:{
            "Accept": "application/json",
            //"content-Type": "application/json;charset-UTF-8"
        },
        data: {
            "barcode": barcode || null,
            "productName": productName || null,
            "category_id": selectCateId || null,
            "unit_id": selectUnitId || null,
            "sub_cat_id": selectSubCateId || null,
            "cost"          : cost || 0,
            "subPrice"      : subPrice || 0,
            "subBarcode"    : subBarcode || null,
            "subNameProduct": subNameProduct || null,
            "subQty"        : subQty || 0
        },
    };
    Axios(options).then(Response =>{
      if(Response.data.status === 1){
        alert(Response.data.mesg);
        setBarcode("");
        setProductName("");
        setSelectCateId("");
        setSelectCateName("")
        setSelectUnitId("");
        setSelectUnitName("")
        setSelectSubCateId("");
        setSelectSubCateName("")
        setCost("");
        setSubPrice("");
        setSubBarcode("");
        setSubNameProduct("");
        setSubQty("");
        props.setOpen(false);
      }else{
          alert(Response.data.mesg)
      }
    });
  };

  const validateData = () => {
      if(!barcode){
          alert("ກະລຸນາຕື່ມຂໍ້ມູນລະຫັດບາໂຄດ");
      }else{
          if(!productName){
              alert("ກະລຸນາຕື່ມຂໍ້ມູນຊື່ຂອງສິນຄ້າ");
          }else{
              if(!selectCateId){
                  alert("ກະລຸນາຕື່ມຂໍ້ມູນປະເພດສິນຄ້າ");
              }else{
                  if(!cost){
                      alert("ກະລຸນາຕື່ມຂໍ້ມູນໝວດຕົ້ນທຶນ");
                  }else{
                      if((subBarcode && subPrice && subNameProduct && subQty ) || (!subBarcode && !subPrice && !subNameProduct && !subQty )){
                          Submit()
                      }
                      else if(subBarcode || subPrice || subNameProduct || subQty){
                          alert("ຂໍ້ມູນສິນຄ້າຍ່ອຍບໍ່ຄົບຖ້ວນ")
                      }
                  }
              }
          }
      }
  }

  return (
    <div>
      <Dialog
        maxWidth='xl'
        fullWidth={true}
        open={props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <AddNewProduct
            category={category}
            setCategory={setCategory}
            unit={unit}
            setUnit={setUnit}
            subCategory={subCategory}
            setSubCategory={setSubCategory}
            barcode={barcode}
            setBarcode={setBarcode}
            productName={productName}
            setProductName={setProductName}
            selectCateId={selectCateId}
            setSelectCateId={setSelectCateId}
            selectCateName={selectCateName}
            setSelectCateName={setSelectCateName}
            selectUnitId={selectUnitId}
            setSelectUnitId={setSelectUnitId}
            selectUnitName={selectUnitName}
            setSelectUnitName={setSelectUnitName}
            selectSubCateId={selectSubCateId}
            setSelectSubCateId={setSelectSubCateId}
            selectSubCateName={selectSubCateName}
            setSelectSubCateName={setSelectSubCateName}
            cost={cost}
            setCost={setCost}
            subPrice={subPrice}
            setSubPrice={setSubPrice}
            subBarcode={subBarcode}
            setSubBarcode={setSubBarcode}
            subNameProduct={subNameProduct}
            setSubNameProduct={setSubNameProduct}
            subQty={subQty}
            setSubQty={setSubQty}
            handleSelectCate={handleSelectCate}
            handleAuto={handleAuto}
            autoBarcode={true}
          />
        </DialogContent>
        <DialogActions>
          <Button 
          variant="contained" 
          color="secondary" 
          onClick={handleClose}
          >
            ຍົກເລີກ
          </Button>
          <Button 
          variant="contained" 
          color="primary" 
          onClick={validateData} 
          autoFocus 
          >
            ບັນທຶກ
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}