import React, {useState} from 'react'
import { Grid, TextField, Typography } from '@mui/material'
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    textField: {
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: 500
    },
    input: {
        color: 'white'
    },
    space: {
        padding: '0.2%'
    },
    send: {
        backgroundColor: "#EFEBEA",
        borderRadius: 3,
        padding: '1%',
        width: '15%',
        textAlign: 'center',
        color: "#00000",
        marginTop: '2%',
        cursor: 'pointer'
    }
}));

export default function TextInput(props) {
    const classes = useStyles();
    const [firstName, setFirstname] = useState("")
    const [lastName, setLastname] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [subject, setSubject] = useState("")
    const [description, setDescription] = useState("")

    // const handleUsername = (e) => {
    //     setBarcode(e.target.value)
    // }
    // const handleBarcode = (e) => {
    //     setBarcode(e.target.value)
    // }
    // const handleBarcode = (e) => {
    //     setBarcode(e.target.value)
    // }
    // const handleBarcode = (e) => {
    //     setBarcode(e.target.value)
    // }
    // const handleBarcode = (e) => {
    //     setBarcode(e.target.value)
    // }
    // const handleBarcode = (e) => {
    //     setBarcode(e.target.value)
    // }

    const handleSubmit = () => {
        if (firstName && lastName && email && phone && subject && description) {
            const options = {
                url: window.$api + "/contactus",
                method: "POST",
                withCredentials: true,
                headers: {
                    "Accept": "application/json",
                    //"content-Type": "application/json;charset-UTF-8"
                },
                data: {
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    phone: phone,
                    subject: subject,
                    description: description
                }
            };
            axios(options).then(res => {
                //console.log(res.data)
                // if (res.data.status) {
                //     switch (res.data.status) {
                //         case 1:
                //             alert("success")
                //             break;
                //         default:
                //             break;
                //     }
                // } else {
                //     alert("fail")
                // }

            })
        } else {
            alert("ກະລຸບາຕື່ມຂໍ້ມູນ")
        }
    }

    //console.log(firstName)

    return (
        <div>
            <Grid container>
                <Grid item xs={6} className={classes.space}>
                    <Typography>{props.t("info.4")}</Typography>
                    <TextField
                        id="name"
                        label={props.t("info.4")}
                        className={classes.textField}
                        margin="normal"
                        InputProps={{
                            className: classes.input,
                        }}
                        onChange={(e) => setFirstname(e.target.value)}
                    />
                </Grid>
                <Grid item xs={6} className={classes.space}>
                    <Typography>{props.t("info.5")}</Typography>
                    <TextField
                        id="name"
                        label={props.t("info.5")}
                        className={classes.textField}
                        margin="normal"
                        InputProps={{
                            className: classes.input,
                        }}
                        onChange={(e) => setLastname(e.target.value)}
                    />
                </Grid>
                <Grid item xs={6} className={classes.space}>
                    <Typography>{props.t("info.6")}</Typography>
                    <TextField
                        id="email"
                        label={props.t("info.6")}
                        className={classes.textField}
                        margin="normal"
                        InputProps={{
                            className: classes.input,
                        }}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </Grid>
                <Grid item xs={6} className={classes.space}>
                    <Typography>{props.t("info.7")}</Typography>
                    <TextField
                        id="phone"
                        label={props.t("info.7")}
                        className={classes.textField}
                        margin="normal"
                        InputProps={{
                            className: classes.input,
                        }}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} className={classes.space}>
                    <Typography>{props.t("info.8")}</Typography>
                    <TextField
                        id="subject"
                        label={props.t("info.8")}
                        className={classes.textField}
                        margin="normal"
                        InputProps={{
                            className: classes.input,
                        }}
                        onChange={(e) => setSubject(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} className={classes.space}>
                    <Typography>{props.t("info.9")}</Typography>
                    <TextField
                        id="des"
                        label={props.t("info.9")}
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={12}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </Grid>
                {/* <div className={classes.send}> */}
                <div >
                    <Button fullWidth variant='contained' onClick={handleSubmit}>
                        <Typography >
                            {props.t("send.1")}
                        </Typography>
                    </Button>
                </div>
                {/* </div> */}
            </Grid>

            {/* <div style={{ textAlign: 'center', backgroundColor: 'red', width: '15%', justifyContent: 'center'}}>
                <Button fullWidth variant='contained'>Send</Button>
            </div> */}
        </div>
    )
}
