// import React from 'react'
// import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend} from 'chart.js';
// import { Bar } from 'react-chartjs-2'
// import { Box } from '@mui/material'

// ChartJS.register(
//     CategoryScale,
//     LinearScale,
//     BarElement,
//     Title,
//     Tooltip,
//     Legend
// );


// export default function ChartData(props) {
//     const state = {
//         labels: ['January', 'February', 'March',
//                  'April', 'May'],
//         datasets: [
//           {
//             label: 'Rainfall',
//             backgroundColor: 'rgba(75,192,192,1)',
//             borderColor: 'rgba(0,0,0,1)',
//             borderWidth: 2,
//             data: [65, 59, 80, 81, 56]
//           }
//         ]
//       }

//     return (
//         <Box
//             sx={{
//                 display: 'flex',
//                 justifyContent:'center',
//                 //backgroundColor: { xl: 'red', lg: 'green', md: 'yellow', sm: 'pink', xs: 'purple' },
//                 height: { xl: '550px', lg: '450px', md: '350px', sm: '220px', xs: '180px' },
//                 width: '100%'
//             }}>
//                 {/* <Bar options={props.chartOption} data={props.data} style={{ width: '100%' }} /> */}
//                 <Bar
//           data={state}
//           options={{
//             title:{
//               display:true,
//               text:'Average Rainfall per month',
//               fontSize:20
//             },
//             legend:{
//               display:true,
//               position:'right'
//             }
//           }}
//         />
//         </Box>
//     )
// }

import React from 'react';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/material'
import {
  Chart,
  BarSeries,
  ArgumentAxis,
  ValueAxis,
  Tooltip
} from '@devexpress/dx-react-chart-material-ui';
import { Animation, EventTracker } from '@devexpress/dx-react-chart';

export default function ChartData(props) {

  // const [chartNumber, setChartNumber] = useState({
  //   datanumber: [],
  // });
  // const [chartName, setChartName] = useState({
  //   dataname: [],
  // })

  // useEffect(() => {
  //   let names = [...props.data];
  //   let newName = [];
  //   let newNumber = [];
  //   names.forEach(function (newData) {
  //     let name = newData.name;
  //     let number = newData.count;
  //     newName.push(name)
  //     newNumber.push(number)
  //     setChartName(name)
  //     setChartNumber(newName)
  //   });

  // }, [props.data]);

  //console.log(props.data)

  // const data = [
  //   { id: '1', count: 2000, name: 'koun' },
  //   { id: '2', count: 100, name: 'koun2' },
  //   { id: '3', count: 200, name: 'koun3' },
  //   { id: '4', count: 300, name: 'koun4' },
  //   { id: '5', count: 400, name: 'koun5' },
  //   { id: '6', count: 500, name: 'koun6' },
  //   { id: '7', count: 1000, name: 'koun7' },
  // ];

  // var nums = data[0].count;
  // console.log(nums)
  // var stringArray = ["1", "2", "3", "4", "5"];
  // var numberArray = stringArray.map(Number);
  //console.log(numberArray);

  //   useEffect(() => {
  //   let names = [...props.Chartdatas];
  //   let newArr = [];
  //   names.forEach(function (newData) {
  //     //let name = newData.name;
  //     let number = newData.count;
  //     //newArr.push(name)
  //     newArr.push(Number(number))
  //     //console.log(newNumber)
  //     //props.setChartData(newNumber)
  //   });
  //   console.log(newArr)
  //   props.setChartData(newArr)
  // }, [props.Chartdatas]);

  //console.log(props.chartData)

  return (
    <Paper style={{ width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          //backgroundColor: { xl: 'red', lg: 'green', md: 'yellow', sm: 'pink', xs: 'purple' },
          //height: { xl: '550px', lg: '450px', md: '350px', sm: '220px', xs: '180px' },
          width: '100%',
          height: '100%'
        }}>
        <div style={{ width: '100%' }}>
          <Chart
            data={props.chartData}
            sty={{ width: '100%', height: '100%' }}
          >
            <ArgumentAxis />
            <ValueAxis max={7}/>

            <BarSeries
              valueField="quantity"
              argumentField="product_name"
              //pointComponent={color='red'}
            />
            <Animation />
            <EventTracker />
            <Tooltip />
          </Chart>
        </div>
      </Box>
    </Paper>
  )
}

