import React, {useState} from 'react'
import { Dialog, DialogContent, DialogActions, Button, Typography, TextField, Grid } from '@material-ui/core'
import Axios from 'axios';

export default function AddPartnerDialog(props) {
    const [companyName, setCompanyName] = useState("")
    const [phone, setPhone] = useState("")
    const [staffName, setStaffName] = useState("")
    const [staffPhone, setStaffPhone] = useState("")
    const [rowId, setRowId] = useState("")

    React.useEffect(() => {
        {props.selectedPartner && props.selectedPartner.map((row) =>
            {
                setCompanyName(row.companyname);
                setPhone(row.companyphone);
                setStaffName(row.contactperson);
                setStaffPhone(row.phoneperson);
                setRowId(row.id);
            }
        )}
    }, [props.selectedPartner])

    return (
        <div>
            <Dialog maxWidth='sm' open={props.open}>
                <DialogContent>
                    <Grid container spacing={2} >
                        <Grid item xs={12} >
                            <Typography>ເພີ່ມຜູ້ສະໜອງ</Typography>
                        </Grid>
                        <Grid item xs={12} >
                            <TextField
                                label="ຊື່ບໍລິສັດ"
                                variant="outlined"
                                value={companyName}
                                onChange={(e)=> setCompanyName(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <TextField
                                label="ເບີໂທ"
                                variant="outlined"
                                value={phone}
                                onChange={(e)=> setPhone(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <TextField
                                label="ຊື່ຜູ້ຕິດຕໍ່"
                                variant="outlined"
                                value={staffName}
                                onChange={(e)=> setStaffName(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <TextField
                                label="ເບີໂທຜູ້ຕິດຕໍ່"
                                variant="outlined"
                                value={staffPhone}
                                onChange={(e)=> setStaffPhone(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions >
                    <Button onClick={()=> props.EditPartner(companyName, phone, staffName, staffPhone, rowId)}>ຕົກລົງ</Button>
                    <Button onClick={()=> props.setOpen(false)}>ຍົກເລິກ</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
