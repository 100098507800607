import Typography from '@material-ui/core/Typography';
import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
//import CardAboutUs from '../../../Bt_modules/Card/CardAboutUs'
import CardAboutUs from '../../../Bt_modules/Card/CardAboutUs'

const useStyles = makeStyles((theme) => ({

  topic: {
      fontWeight: 'bold',
      //color: theme.palette.secondary.dark,
      fontSize: '18px',
      [theme.breakpoints.up('sm')]: {
          fontSize: '25px',

      },
      textIndent: '40px'
  },
}));

export default function Aboutus(props) {
  const classes = useStyles();

  // const des1 = "ໂຮງງານນໍ້າດື່ມບໍລິສຸດແສງແກ້ວລິເລິ່ມ ການຜະລິດ ແລະ ຈຳໜ່າຍນໍ້າດື່ມບໍລິສຸດ ພາຍໃຕ້ຍີ່ຫໍ້ *ນ້ຳດື່ມວັນ*  ນັບແຕ່ເດືອນ ມີນາ 2018 ເປັນຕົ້ນມາ. ແລະ ເປັນໂຮງງານຜະລິດນ້ຳດື່ມທີ່ມີຄຸນນະພາບຜ່ານການກັ່ນຕອງດ້ວຍລະບົບຣີເວີດສອົດໂມຊີດສROແລະຂ້າເຊື້ອແສງUV, ໄດ້ຮັບໃບຢັ້ງຢືນຄຸນນະພາບ(ກອຢ/ຜນ) ແລະ ເປັນໂຮງງານແຫ່ງດຽວໃນແຂວງບໍລິຄຳໄຊ ທີ່ໄດ້ຮັບໃບຢັ້ງຢືນຄຸນນະພາບມາດຕະຖານ GHP ສາກົນ ແລະ ຈະກ້າວເຂົ້າເປັນໂຮງງານຕົວແບບຂອງແຂວງ ບໍລິຄໍາໄຊ ໃນໄວໄວນີ້. \n\n"
  // const des2 = "ຈຸດໂດດເດັ່ນຂອງໂຮງງານນໍ້າດື່ມຂອງພວກເຮົາແມ່ນ :"
  // const des3 = "-    ສະຖານທີ່ຕັ້ງຂອງໂຮງງານຢູ່ໃຈກາງຂອງບັນດາແຂວງທີ່ຢູ່ທາງ ພາກເໜືອ, ພາກກາງ ແລະ ພາກໃຕ້ ເຊິ່ງສ້າງຄວາມສະດວກໃນການຂົນສົ່ງສິນຄ້າໄປຫາບັນດາແຂວງຕ່າງໆໃນຂອບເຂດທົ່ວປະເທດສາມາດ ປະຢັດຕົ້ນທຶນໃນການຂົນສົ່ງ."
  // const des4 = "-    ນໍ້າດື່ມຂອງພວກເຮົາ ຍຶດຖືເອົາການຮັກສາຄຸນນະພາບ, ຄວາມປອດໄພ ແລະ ໄດ້ມາດຕະຖານສາກົນ ເປັນຫົວໃຈສໍາຄັນໃນການຜະລິດ."
  // const detailsLa = [
  //   {id : '1', des: des1},
  //   {id : '2', des: des2},
  //   {id : '3', des: des3},
  //   {id : '4', des: des4}
  // ]
  return (
    <div>
      ​<br/> ​<br/>
      <Typography component={'div'}>
        <div className={classes.topic}>{props.t('title.2')}</div>
      </Typography>
      <br/>
      <CardAboutUs />
      {/* {detailsLa.map((row) => (
        <div key={row.id}>
          <Typography component={'div'} style={{ whiteSpace: "pre-wrap", textAlign: 'justify', textIndent: '40px', lineHeight: '180%', }}>
            <div style={{fontSize: '16px'}}>{row.des}</div>
          </Typography>
        </div>
      ))} */}
      ​<br/> ​<br/>
    </div>
  )
}
