import React, { useEffect, useState } from 'react'
import ProductManagePage from 'Bt_modules/Storage/ProductManage'
import Axios from 'axios'
import AddNewProductDiag from 'Bt_modules/Storage/AddNewProductDiag';
import EditMaterialDiag from 'Bt_modules/Storage/EditMaterialDiag';
import { Redirect } from "react-router-dom";

export default function ProductPage(props) {
    //const [company, setCompany] = useState([]);
    //const [name, setName] = useState([]);
    const [search, setSearch] = useState("");
    const [productId, setproductId] = useState("")
    const [productBarcode, setProductBarcode] = useState("")
    const [data, setData] = useState([]);
    const [selectName, setSelectName] = useState();
    const [openEdit, setOpenEdit] = useState(false)
    const [openAddNewProductDiag, setopenAddNewProductDiag] = useState(false)
    const [selectedMaterial, setSelectedMaterial] = useState();

    useEffect(() => {
        let isMounted = true;
        Axios
            .get(window.$api + "/selectProductInfo")
            .then((res) => {
                if (isMounted) {
                    setData(res.data.allProduct)
                }
            });
        return () => { isMounted = false };
    }, []);

    const handleSearch = (e) => {
        setSearch(e.target.value);
    }

    const HandleEnter = (e) => {
        if (e.key === 'Enter') {
            const options = {
                url: window.$api + "/search",
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    ////"content-Type": "application/json;charset-UTF-8"
                },
                data: {
                    "search": search,
                }

            }
            Axios(options).then(Response => {
                setData(Response.data);
                setSelectName("")
                //setSelectPartner("")
            });
        }
    }

    const handleSelectName = (e) => {
        setSearch("")
        const options = {
            url: window.$api + "/selectname",
            method: "POST",
            headers: {
                "Accept": "application/json",
                ////"content-Type": "application/json;charset-UTF-8"
            },
            data: {
                "name": e,
            }

        }
        Axios(options).then(Response => {
            setData(Response.data);
        });

    }

    // const handleEditProduct = (product_barcode, id) => {
    //     setProductBarcode(product_barcode)
    //     setproductId(id)
    //     setOpenEdit(true)
    // }

    const handleEditProduct = (id) => {
        //setProductBarcode(product_barcode)
        let materialData = [...data];
        let [rowData] = materialData.filter(row => row.id === id);
        setSelectedMaterial(rowData)
        setOpenEdit(true)
        //console.log(rowData);
    }

    const handleChange = (e) => {
      setSelectedMaterial({
        ...selectedMaterial,
        [e.target.name]: e.target.value
      })
    }

    const handleDelete = (id) => {
        if (id) {
            const options = {
                url: window.$api + "/delProduct",
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    ////"content-Type": "application/json;charset-UTF-8"
                },
                data: {
                    "product_id": id,
                }

            }
            Axios(options).then(Response => {
                if(Response.data === "success") {
                    alert("ສໍາເລັດ");
                    let update = data.filter(row => row.id !== id);
                    setData(update);
                }
            });
        }
    }

    return (
        <div>
            {props.authenticated ?
                <>
                    <ProductManagePage
                        //company={company} 
                        //name={name}
                        search={search}
                        productBarcode={productBarcode}
                        productId={productId}
                        setProductBarcode={setProductBarcode}
                        data={data}
                        setData={setData}
                        selectName={selectName}
                        openEdit={openEdit}
                        setOpenEdit={setOpenEdit}
                        setSelectName={setSelectName}
                        handleSelectName={handleSelectName}
                        //handleSelectPartner={handleSelectPartner}
                        handleEditProduct={handleEditProduct}
                        handleSearch={handleSearch}
                        HandleEnter={HandleEnter}
                        setopenAddNewProductDiag={setopenAddNewProductDiag}
                        handleDelete={handleDelete}
                    />
                    {/* <EditProductPage
                openEdit={openEdit}
                setOpenEdit={setOpenEdit}
                data={data}
                handleEditProduct={handleEditProduct}
                handleDelete={handleDelete}
            /> */}

                    <AddNewProductDiag open={openAddNewProductDiag} setOpen={setopenAddNewProductDiag} setData={setData} data={data} />
                    <EditMaterialDiag open={openEdit} setOpen={setOpenEdit} selectedMaterial={selectedMaterial} setSelectedMaterial={setSelectedMaterial} handleChangeEdit={handleChange} setData={setData} data={data}/>
                </>
                :
                <Redirect
                    to={{
                        pathname: "/admin/sign-in",
                    }}
                />
            }
        </div>
    )
}
