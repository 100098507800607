import React, { useEffect, useState } from 'react'
import ChannelManageTable from '../../../Bt_modules/Storage/ChannelManageTable'
import Axios from 'axios'
import AddNewChannel from '../../../Bt_modules/Storage/AddNewChannelDiag';
import { Redirect } from "react-router-dom";
import DialogWarning from '../../../Bt_modules/Dialog/DialogWarning';
import EditChannelDialog from '../../../Bt_modules/Storage/EditChannelDialog';

export default function ProductPage(props) {
    const [village, setVillage] = useState([]);
    const [district, setDistrict] = useState([]);
    const [province, setProvince] = useState([]);
    const [search, setSearch] = useState("");
    const [channel_id, setChannel_id] = useState("")
    //const [productBarcode, setProductBarcode] = useState("")
    const [data, setData] = useState([]);
    const [selectName, setSelectName] = useState();
    const [openEdit, setOpenEdit] = useState(false)
    const [openAddNewProductDiag, setopenAddNewProductDiag] = useState(false)
    const [openWarning, setOpenWarning] = useState(false)
    const [idChannel, setIdChannel] = useState()
    const [channelType, setChannelType] = useState([]);
    const [customerPrice, setCustomerPrice] = useState([]);
    const [channelInfo, setChannelInfo] = useState({})

    useEffect(() => {
        let isMounted = true;
        Axios
            .get(window.$api + "/getChannelsData")
            .then((res) => {
                if (isMounted) {
                    setData(res.data.allChannel)
                }
            });
        return () => { isMounted = false };
    }, []);


    useEffect(() => {
        const options = {
            url: window.$api + "/getChannelsVDP",
            method: "GET",
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                ////"content-Type": "application/json;charset-UTF-8"
            },
        };
        Axios(options).then(Response => {
            setVillage(Response.data.village)
            setDistrict(Response.data.district)
            setProvince(Response.data.province)
        })
    }, []);

    const handleSearch = (e) => {
        setSearch(e.target.value);
    }

    const OpenWarning = (id) => {
        setOpenWarning(true)
        setIdChannel(id)
    }

    const CloseWarning = () => {
        setOpenWarning(false)
    }

    const HandleEnter = (e) => {
        if (e.key === 'Enter') {
            const options = {
                url: window.$api + "/search",
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    ////"content-Type": "application/json;charset-UTF-8"
                },
                data: {
                    "search": search,
                }

            }
            Axios(options).then(Response => {
                setData(Response.data);
                setSelectName("")
                //setSelectPartner("")
            });
        }
    }

    const handleSelectName = (e) => {
        setSearch("")
        const options = {
            url: window.$api + "/selectname",
            method: "POST",
            headers: {
                "Accept": "application/json",
                ////"content-Type": "application/json;charset-UTF-8"
            },
            data: {
                "name": e,
            }

        }
        Axios(options).then(Response => {
            setData(Response.data);

        });

    }

    const handleEditChannel = (id) => {
        setChannel_id(id)
        setOpenEdit(true)
    }

    const handleDelete = () => {
        if (idChannel) {
            const options = {
                url: window.$api + "/delChannel",
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    ////"content-Type": "application/json;charset-UTF-8"
                },
                data: {
                    "channel_id": idChannel,
                }

            }
            Axios(options).then(Response => {
                console.log(Response.data);
                Axios
                    .get(window.$api + "/getChannelsData")
                    .then((res) => {
                        setData(res.data.allChannel)
                    });
            });
        }
    }

    return (
        <div>
            {props.authenticated ?
                <>
                    <ChannelManageTable
                        //company={company} 
                        //name={name}
                        search={search}
                        //productBarcode={productBarcode}
                        channel_id={channel_id}
                        //setProductBarcode={setProductBarcode}
                        data={data}
                        setData={setData}
                        selectName={selectName}
                        openEdit={openEdit}
                        setOpenEdit={setOpenEdit}
                        setSelectName={setSelectName}
                        handleSelectName={handleSelectName}
                        //handleSelectPartner={handleSelectPartner}
                        handleEditChannel={handleEditChannel}
                        handleSearch={handleSearch}
                        HandleEnter={HandleEnter}
                        setopenAddNewProductDiag={setopenAddNewProductDiag}
                        handleDelete={handleDelete}
                        OpenWarning={OpenWarning}
                    />
                    <EditChannelDialog
                        openEdit={openEdit}
                        setOpenEdit={setOpenEdit}
                        channelID={channel_id}
                        setData={setData}
                        data={data}
                        village={village}
                        district={district}
                        province={province}
                        setVillage={setVillage}
                        setDistrict={setDistrict}
                        setProvince={setProvince}
                        channelType={channelType}
                        setChannelType={setChannelType}
                        setChannelInfo={setChannelInfo}
                        channelInfo={channelInfo}
                        customerPrice={customerPrice}
                        setCustomerPrice={setCustomerPrice}
                        //data={data}
                        // handleEditProduct={handleEditProduct}
                        // handleDelete={handleDelete}
                    />

                    <AddNewChannel
                        open={openAddNewProductDiag}
                        setOpen={setopenAddNewProductDiag}
                        setData={setData}
                        data={data}
                        village={village}
                        district={district}
                        province={province}
                        setVillage={setVillage}
                        setDistrict={setDistrict}
                        setProvince={setProvince}
                        channelType={channelType}
                        setChannelType={setChannelType}
                        customerPrice={customerPrice}
                        setCustomerPrice={setCustomerPrice}
                        setChannelInfo={setChannelInfo}
                        channelInfo={channelInfo}

                    />
                    <DialogWarning
                        open={openWarning}
                        close={CloseWarning}
                        handleupdate={handleDelete}
                    />
                </>
                :
                <Redirect
                    to={{
                        pathname: "/admin/sign-in",
                    }}
                />
            }
        </div>
    )
}
