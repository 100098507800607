import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core'
import CardMenu from 'Bt_modules/Card/CardMenu'
import ImgHome from 'Img/img_menu/1home.png'
import ImgAbout from 'Img/img_menu/Aboutus.png'
import ImgProduct from 'Img/img_menu/product.png'
import ImgNews from 'Img/img_menu/newsevents .png'
import ImgContact from 'Img/img_menu/contactus.png'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.light
    },
    scopBTN: {
        flex: 22,
        justifyContent: 'flex-end',
        display: 'flex'
    },
    btn: {
        backgroundColor: theme.palette.primary.main,
        padding: '0.2% 2%',
        borderRadius: 4,
        color: theme.palette.background.main
    }
}));

export default function Menu(props) {
    const classes = useStyles();
    const [active, setActive] = React.useState()

    const handleActive = (act) => {
        setActive(act)
    }

    const menu = [
        { id: '1', text: props.t('lng.1') },
        { id: '2', text: props.t('home.1'), img: ImgHome, path: '/' },
        { id: '3', text: props.t('title.2'), img: ImgAbout, path: '/aboutus' },
        { id: '4', text: props.t('title.1'), img: ImgProduct, path: '/ourproducts' },
        { id: '5', text: props.t('title.4'), img: ImgNews, path: '/newsevents' },
        { id: '6', text: props.t('title.3'), img: ImgContact, path: '/contactus' },
    ]

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item container xs={1} sm={1} md={1} lg={1} xl={1} style={{ backgroundColor: '', }} />
                {/* {menu.map((row) => (
                    <Grid key={row.id} item container xs={4} sm={4} md={1} lg={2} xl={8} style={{ padding: '0.5% 0', backgroundColor: 'red', }}>
                        <CardMenu id={row.id} text={row.text} img={row.img} />
                    </Grid>
                ))} */}
                <Grid item container xs={10} sm={10} md={10} lg={10} xl={10} style={{ backgroundColor: '', }}>
                    {menu.map((row) => (
                        <Grid key={row.id} item container xs={12} sm={3} md={2} lg={2} xl={2} style={{ padding: '0.5% 0', justifyContent: 'center' }}>
                            <CardMenu id={row.id} text={row.text} img={row.img} path={row.path} handleActive={handleActive} active={active} setActive={setActive} t={props.t} i18n={props.i18n} />
                        </Grid>
                    ))}
                </Grid>
                <Grid item container xs={1} sm={1} md={1} lg={1} xl={1} style={{ backgroundColor: '', }} />
            </Grid>
        </div>
    )
}
