import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
//import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import NumberFormat from 'react-number-format'
import { Button } from '@material-ui/core/';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.background.dark,
    color: theme.palette.background.default,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);


// const titles = [
//     { id: '1', name: ['ລຳດັບ', 'ຊື່ຜູ້ສົ່ງ', 'ຮູບແບບຊຳລະ', 'ລາຄາລວມ', 'ສະຖານະ', 'ວັນທີສົ່ງ', 'Action'] },
// ];

// const datas = [
//   { id: '1', name: 'koun', unit: '1000', price: '10000', suplier: 'ບໍລິສັດ ເບດເທັກຈຳກັດ', sender: 'ບໍລິສັດ ເບດເທັກຈຳກັດ', barcode: '8851907264888', date: '22.11.2022' },
//   { id: '2', name: 'jack', unit: '1000', price: '10000', suplier: 'ເບດມາກ', sender: 'ເບດມາກ', barcode: '8851907264888', date: '22.11.2022' },
//   { id: '3', name: 'mai', unit: '1000', price: '10000', suplier: 'Deliverly', sender: '#329035', barcode: '8851907264888', date: '22.11.2022' },
//   { id: '4', name: 'billy', unit: '1000', price: '10000', suplier: 'ບໍລິສັດ ເບດເທັກຈຳກັດ', sender: 'ບໍລິສັດ ເບດເທັກຈຳກັດ', barcode: '8851907264888', date: '22.11.2022' },
//   { id: '5', name: 'ai seua', unit: '1000', price: '10000', suplier: 'ບໍລິສັດ ເບດເທັກຈຳກັດ', sender: 'ບໍລິສັດ ເບດເທັກຈຳກັດ', barcode: '8851907264888', date: '22.11.2022' },
//   { id: '6', name: 'ai tueng', unit: '1000', price: '10000', suplier: 'ບໍລິສັດ ເບດເທັກຈຳກັດ', sender: 'ບໍລິສັດ ເບດເທັກຈຳກັດ', barcode: '8851907264888', date: '22.11.2022' },
//   { id: '7', name: 'najah', unit: '1000', price: '10000', suplier: 'ບໍລິສັດ ເບດເທັກຈຳກັດ', sender: 'ບໍລິສັດ ເບດເທັກຈຳກັດ', barcode: '8851907264888', date: '22.11.2022' },
// ];


const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function TableImpMaterial(props) {
  const classes = useStyles();

  const handleDelete = (id) => {
    const del = props.tableData.filter(update => update.id !== id);
    props.setTableData(del);
  }

  return (
    <Paper className={classes.root}>
      <TableContainer component={Paper} style={{ maxHeight: "40vh" }} >
        <Table aria-label="customized table" stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell align="right">ລຳດັບ</StyledTableCell>
              <StyledTableCell align="right">ບາໂຄດ</StyledTableCell>
              <StyledTableCell align="right">ຊື່ສິນຄ້າ</StyledTableCell>
              <StyledTableCell align="right">ຈ/ນ ທີ່ຢາກນຳເຂົ້າ</StyledTableCell>
              <StyledTableCell align="right">ຈ/ນ ໃນສະຕ໋ອກ</StyledTableCell>
              <StyledTableCell align="right">ແກ້ໄຂ</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.tableData.map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell align="right">{index + 1}</StyledTableCell>
                <StyledTableCell align="right">{row.barcode}</StyledTableCell>
                <StyledTableCell align="right">{row.nameproduct}</StyledTableCell>
                <StyledTableCell align="right">
                  <NumberFormat value={row.amount} displayType={'text'} thousandSeparator={true} suffix={' '} />
                </StyledTableCell>
                <StyledTableCell align="right">
                  <NumberFormat value={row.quantity || 0} displayType={'text'} thousandSeparator={true} suffix={' '} />
                </StyledTableCell>
                <StyledTableCell align="right">
                  <Button onClick={() => handleDelete(row.id)}>
                    <DeleteIcon />
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

//TableOrders