import React, { useState, useEffect } from 'react'
import { Container, Typography, MenuItem, TextField, Button, Grid, Paper } from '@material-ui/core';
//import Table from './Table'
import Dialog from 'Bt_modules/Dialog/Dialog';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DialogAddApart from 'Bt_modules/Dialog/DialogAddApart';
import Axios from 'axios';
import TableImpMaterial from 'Bt_modules/Tables/TableImpMaterial'
import { Redirect } from "react-router-dom";
// import AddCustomerPriceDiag from '../../../Bt_modules/AddNewProduct/AddCustomerPriceDiag';

export default function Buy(props) {
  const [dealer, setDealer] = useState([]);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [tableRow, setTableRow] = useState({
    id: "",
    barcode: "",
    amount: "",
    nameproduct: "",
    // sale: "",
    total: "",
    // cost: "",
    // pricePerUnit: "",
    payment: ""
  });
  const [tableData, setTableData] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState([])
  // const [billcode, setBillcode] = useState([]);


  useEffect(() => {
    Axios
      .get(window.$api + "/getBuyPageInfo")
      .then((res) => {
        setDealer(res.data.partner)
        setPaymentMethod(res.data.payment)
        // setBillcode(Number(res.data.autoBarcode.billcode) + 1)
      });
  }, []);

  const handleSelectPartner = (partner_id, partner_name) => {
    setTableRow({
      ...tableRow,
      selectSupplierId: partner_id,
      selectSupplierName: partner_name
    });
  }

  const handleSelectPaymentMethod = (payId, payType) => {
    setTableRow({
      ...tableRow,
      payment: payType,
      pay_id: payId
    });
  }

  const Amount = (e) => {
    let amount = e.target.value
    let total = Number(amount) * Number(tableRow.kip)
    let price_unit = Number(total) / Number(amount)
    setTableRow({
      ...tableRow,
      amount: amount,
      total: total,
      pricePerUnit: price_unit
    })
  };

  const handlePressEnterBarcode = (e) => {
    if (e.key === 'Enter') {
      const options = {
        url: window.$api + "/getMaterialByBarcode",
        method: "POST",
        headers: {
          "Accept": "application/json",
        },
        data: { "barcode": tableRow.barcode },
      };
      Axios(options).then(res => {
        if (res.data.buy) {
          setTableRow({
            ...tableRow,
            id: res.data.buy.id,
            nameproduct: res.data.buy.product_name,
            quantity: res.data.buy.quantity,
            // cost: res.data.buy.cost
          });
        } else {
          alert(res.data);
        }
      });
    }
  };

  // const sumAmount = tableData.reduce((accumulator, object) => {
  //   return Number(accumulator) + Number(object.amount);
  // }, 0);

  // const sumKip = tableData.reduce((accumulator, object) => {
  //   return Number(accumulator) + Number(object.price || 0);
  // }, 0);

  const AddarrayToTable = () => {
    if (tableRow.selectSupplierName && tableRow.payment && tableRow.barcode && tableRow.nameproduct && tableRow.amount) {

      const check = tableData.some(el => el.id === tableRow.id)

      if (check) {
        alert("ມີໃນລາຍການແລ້ວ")
      } else {
        let newArray = [...tableData, tableRow];
        setTableData(newArray);
        setTableRow({});
      }
    } else {
      alert("ກະລຸນາປ້ອນຂໍ້ມູນ")
    }
  };

  const BuyProduct = () => {
    if (tableData.length > 0) {
      const options = {
        url: window.$api + "/materialInStock",
        method: "POST",
        headers: {
          "Accept": "application/json",
          //"content-Type": "application/json;charset-UTF-8"
        },
        data: {
          "tableData": tableData,
          "token": props.accessToken
        }
      };
      Axios(options).then(Response => {
        alert(Response.data);
        setTableData([]);
      })
        .catch(e =>
          console.log(e)
        )
    } else {
      alert("ລາຍການວ່າງເປົ່າ")
    }
  };

  if (props.authenticated) {
    return (
      <div >
        <Container maxWidth='xl'>
          <Paper style={{ padding: "2%", marginTop: "2vh", marginBottom: "2vh" }}>
            <Grid container spacing={2} justifyContent='center'>
              <Grid item xs={12}>
                <Typography align='center' variant="h6" gutterBottom>
                  ຂໍ້ມູນການນໍາເຂົ້າ
                </Typography>
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  label="ຜູ້ສະໜອງ"
                  select
                  fullWidth
                  value={tableRow.selectSupplierName || ""}
                >
                  <MenuItem onClick={() => setOpen1(true)}>
                    <AddCircleIcon />
                    ເພີ່ມຜູ້ສະໜອງໃໝ່
                  </MenuItem>
                  {dealer.map((row) => (
                    <MenuItem key={row.id} value={row.companyname} onClick={() => handleSelectPartner(row.id, row.companyname)}>
                      {row.companyname}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  label="ການຈ່າຍເງິນ"
                  select
                  fullWidth
                  value={tableRow.payment || ""}
                >
                  {paymentMethod.map((option) => (
                    <MenuItem key={option.id} value={option.payment_type} onClick={() => handleSelectPaymentMethod(option.id, option.payment_type)}>
                      {option.payment_type}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <Typography align='center' variant="h6" gutterBottom >
                  ຂໍ້ມູນວັດຖຸດິບທີ່ນໍາເຂົ້າ
                </Typography>
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  id="outlined-basic"
                  label="ບາໂຄດ"
                  helperText='ພິມບາໂຄດແລ້ວກົດ Enter'
                  variant="outlined"
                  fullWidth
                  value={tableRow.barcode || ""}
                  onChange={(e) => setTableRow({ ...tableRow, barcode: e.target.value })}
                  onKeyPress={handlePressEnterBarcode}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  id="outlined-basic"
                  label="ຊື່ສິນຄ້າ"
                  variant="outlined"
                  fullWidth
                  value={tableRow.nameproduct || ""}
                  disabled
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  id="outlined-basic"
                  label="ຈ/ນ ທີ່ຢາກນຳເຂົ້າ"
                  variant="outlined"
                  fullWidth
                  value={tableRow.amount || ""}
                  onChange={Amount}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <TextField
                  id="outlined-basic"
                  label="ຈ/ນ ໃນສະຕ໋ອກ"
                  variant="outlined"
                  fullWidth
                  value={tableRow.quantity || 0}
                  disabled
                />
              </Grid>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={AddarrayToTable}
                >
                  ເພີ່ມ
                </Button>
              </Grid>
              {tableData.length > 0 &&
                <>
                  <Grid item xs={12}>
                    <Typography align='center' variant="h6" gutterBottom >
                      ລາຍການວັດຖຸດີບທີ່ນໍາເຂົ້າ
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <TableImpMaterial tableData={tableData} setTableData={setTableData} />
                  </Grid>

                  {/* <Grid item sm={6} xs={12}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    label="ຈ/ນ ລາຍການສິນຄ້າທັງໝົດ"
                    fullWidth
                    disabled
                    value={sumAmount}
                  />
                </Grid> */}
                  {/* <Grid item sm={4} xs={12}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    label="ລາຄາລວມ:"
                    fullWidth
                    disabled
                    value={sumKip}
                  />
                </Grid> */}

                  {/* <Grid item sm={6} xs={12}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    label="ເລກບິນທີ່ບັນທຶກ:"
                    fullWidth
                    disabled
                    value={billcode}
                  />
                </Grid> */}
                  <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={BuyProduct}
                    >
                      ບັນທຶກ
                    </Button>
                  </Grid>
                </>
              }
            </Grid>
            <Dialog open={open} setOpen={setOpen} />
            <DialogAddApart open1={open1} setOpen1={setOpen1} setDealer={setDealer} dealer={dealer}/>
          </Paper>
        </Container>
      </div>
    )
  } else {
    return (
      <div>
        <Redirect
          to={{
            pathname: "/admin/sign-in",
          }}
        />
      </div>
    )
  }
}