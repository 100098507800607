import React, { useState, useEffect } from 'react'
// import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    Typography,
    Paper,
    Container,
    TextField,
    MenuItem,
    Button
} from '@material-ui/core';
import TableReport from 'Bt_modules/Tables/TableReport';
import Axios from 'axios';
import { Redirect } from "react-router-dom";
import SaleReportDiag from 'Bt_modules/Dialog/SaleReportDiag';
import NumberFormat from 'react-number-format'

export default function ReportProductpage(props) {
    const [saleList, setsaleList] = useState([])
    const [selectedDate, setselectedDate] = useState('')
    const [openSaleReportDiag, setopenSaleReportDiag] = useState(false)
    const [saleReportList, setsaleReportList] = React.useState([])
    const [allReportInfo, setallReportInfo] = useState({})
    const [selectedMonth, setselectedMonth] = useState("")
    const [selectedYear, setselectedYear] = useState("")

    const startedYear = 2010
    const year = (new Date()).getFullYear();
    const years = []
    const months = []

    for (var i = startedYear; i <= year; i++) {
        years.push(i)
    }

    for (var j = 1; j <= 12; j++) {
        months.push(j)
    }

    useEffect(() => {

        let isMounted = true;
        const options = {
            url: window.$api + "/getSaleReport",
            method: "GET",
            headers: {
                "Accept": "application/json"
            }
        };
        Axios(options).then(res => {
            if (isMounted) {
                var dailyInfoList = [...res.data.dailySaleInfo]
                var monthlyInfoList = [...res.data.monthlySaleInfo]
                var dailySale = 0
                var dailyProfit = 0
                var monthlySale = 0
                var monthlyProfit = 0

                dailyInfoList.forEach(el => {
                    dailySale += el.price * el.quantity
                    dailyProfit += (el.price - (el.cost || 0)) * el.quantity
                });

                monthlyInfoList.forEach(el => {
                    monthlySale += el.price * el.quantity
                    monthlyProfit += (el.price - (el.cost || 0)) * el.quantity
                });

                setallReportInfo({
                    dailySale: dailySale,
                    dailyProfit: dailyProfit,
                    monthlySale: monthlySale,
                    monthlyProfit: monthlyProfit
                })

                var arr = res.data.saleReport
                var result = [];

                arr.forEach(function (a) {
                    if (!this[a.date]) {
                        this[a.date] = {
                            id: a.id,
                            total: 0,
                            profit: 0,
                            date: a.date
                        };
                        result.push(this[a.date]);
                    }
                    this[a.date].total += (a.price * a.quantity);
                    this[a.date].profit += ((a.price - (a.cost || 0)) * a.quantity)
                }, Object.create(null));

                setsaleList(result);


            }
        });
        return () => { isMounted = false };
    }, []);

    const handleViewSaleDetail = (date) => {
        const options = {
            url: window.$api + "/getSaleReportByDate",
            method: "POST",
            headers: {
                "Accept": "application/json"
            },
            data: {
                date: date
            }
        };
        Axios(options).then(res => {
            var arr = [...res.data]

            arr.forEach(el => {
                el.total = el.price * el.quantity
                el.profit = el.total - ((el.cost || 0) * el.quantity)
            });

            setsaleReportList(arr)
            setselectedDate(date)
            setopenSaleReportDiag(true)
        })
    }

    const handleCloseSaleReportDiag = () => {
        setselectedDate('')
        setsaleReportList([])
        setopenSaleReportDiag(false)
    }

    const handleSelectMonth = (month) => {
        setselectedMonth(month)
    }

    const handleSelectYear = (year) => {
        setselectedYear(year)
    }

    const handleFilterByMonAndYear = () => {
        Axios({
            url: window.$api + "/getSaleReportByMonAndYear",
            method: "POST",
            headers: {
                "Accept": "application/json"
            },
            data: {
                month: selectedMonth,
                year: selectedYear
            }
        }).then(res => {
            var arr = res.data
            var result = [];

            arr.forEach(function (a) {
                if (!this[a.date]) {
                    this[a.date] = {
                        id: a.id,
                        total: 0,
                        profit: 0,
                        date: a.date
                    };
                    result.push(this[a.date]);
                }
                this[a.date].total += (a.price * a.quantity);
                this[a.date].profit += ((a.price - (a.cost || 0)) * a.quantity)
            }, Object.create(null));

            setsaleList(result);
        })
    }

    return (
        <Container maxWidth='lg'>
            {props.authenticated ?
                <div>
                    <br />
                    <Grid container>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ backgroundColor: '', textAlign: 'center', padding: '1% 2%' }}>
                            <Typography component='div' variant='h2'>
                                <div>ລາຍງານການຂາຍ</div>
                            </Typography>
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2} justifyContent='center'>
                        <Grid item xs={12} sm={6} md={3} >
                            <Paper style={{ padding: 10, }}>
                                <Typography component='div' variant='h6' align='center'>
                                    <NumberFormat value={allReportInfo.dailySale || 0} displayType={'text'} thousandSeparator={true} suffix={' ກີບ'} />
                                </Typography>
                                <Typography component='div' variant='h6' align='center'>
                                    ຍອດຂາຍມື້ນີ້
                                </Typography>
                            </Paper>
                        </Grid>

                        <Grid item xs={12} sm={6} md={3} >
                            <Paper style={{ padding: 10, }}>
                                <Typography component='div' variant='h6' align='center'>
                                    <NumberFormat value={allReportInfo.dailyProfit || 0} displayType={'text'} thousandSeparator={true} suffix={' ກີບ'} />
                                </Typography>
                                <Typography component='div' variant='h6' align='center'>
                                    ກຳໄລມື້ນີ້
                                </Typography>
                            </Paper>
                        </Grid>

                        <Grid item xs={12} sm={6} md={3} >
                            <Paper style={{ padding: 10, }}>
                                <Typography component='div' variant='h6' align='center'>
                                    <NumberFormat value={allReportInfo.monthlySale || 0} displayType={'text'} thousandSeparator={true} suffix={' ກີບ'} />
                                </Typography>
                                <Typography component='div' variant='h6' align='center'>
                                    ຍອດຂາຍເດືອນນີ້
                                </Typography>
                            </Paper>
                        </Grid>

                        <Grid item xs={12} sm={6} md={3} >
                            <Paper style={{ padding: 10, }}>
                                <Typography component='div' variant='h6' align='center'>
                                    <NumberFormat value={allReportInfo.monthlyProfit || 0} displayType={'text'} thousandSeparator={true} suffix={' ກີບ'} />
                                </Typography>
                                <Typography component='div' variant='h6' align='center'>
                                    ກຳໄລເດືອນນີ້
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                    <br /><br />
                    <div>
                        <Typography component='div' variant='h5'>
                            ຍອດຂາຍລາຍວັນ
                        </Typography>
                        <div style={{ display: 'flex' }}>
                            <TextField
                                label="ເດຶອນ"
                                variant='outlined'
                                value={selectedMonth || ""}
                                select
                                style={{ width: "30%" }}
                            >
                                {months.map(row =>
                                    <MenuItem key={row} value={row || ""} onClick={() => handleSelectMonth(row)}>
                                        {row}
                                    </MenuItem>
                                )}
                            </TextField>
                            <TextField
                                variant="outlined"
                                label='ປີ'
                                value={selectedYear || ""}
                                select
                                style={{ width: "30%" }}
                            >
                                {years.map(row =>
                                    <MenuItem key={row} value={row || ""} onClick={() => handleSelectYear(row)}>
                                        {row}
                                    </MenuItem>
                                )}
                            </TextField>
                            <Button variant='contained' onClick={handleFilterByMonAndYear}>
                                ເບິ່ງຂໍ້ມູນ
                            </Button>
                        </div>
                    </div>

                    <br />
                    <TableReport
                        saleList={saleList}
                        handleViewSaleDetail={handleViewSaleDetail}
                    />
                    <br />
                </div>
                :
                <Redirect
                    to={{
                        pathname: "/admin/sign-in",
                    }}
                />
            }
            <SaleReportDiag
                open={openSaleReportDiag}
                saleReportList={saleReportList}
                date={selectedDate}
                handleClose={handleCloseSaleReportDiag}
            />
        </Container>
    )
}