import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Grid, TextField, Button, Typography, DialogContent, DialogActions, MenuItem } from '@material-ui/core';
import Axios from 'axios';

export default function DialogAddChannelVillage(props) {

    const [channelVillage, setChannelVillage] = useState("");
    const [selectChannelDistrictID, setSelectChannelDistrictID] = useState("");
    const [selectChannelDistrictName, setSelectChannelDistrictName] = useState("");

    const handleClose = () => {
        props.setOpenVillage(false);
    };

    const handleSelectChannelDistrict = (id, name) => {
        setSelectChannelDistrictID(id);
        setSelectChannelDistrictName(name)
    };

    const handleChannelVillage = (e) => {
        setChannelVillage(e.target.value);
    };

    const Submit = () =>{
        const options = {
            url: window.$api + "/addChannelVillage",
            method: "POST",
            headers:{
                "Accept": "application/json",
                ////"content-Type": "application/json;charset-UTF-8"
            },
            data: {
                "channelVillage" : channelVillage,
                "selectChannelDistrictID": selectChannelDistrictID
            },
        };
        Axios(options).then(Response =>{
            alert(Response.data.mesg);
            props.setVillage(Response.data.village)
        });
    };

    return (
        <Dialog       
            maxWidth = 'xl'
            open={props.openVillage}
        >
             <DialogContent>
                <Grid container spacing={2} >
                    <Grid item xs={12} >
                        <Typography>
                            ເພີ່ມບ້ານ
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="ຊື່ເມືອງ"
                            variant="outlined"
                            //onChange={handleChannelDistrict}
                            fullWidth
                            select
                            value={selectChannelDistrictName || ""}
                        >
                            {props.district.map((row, index) => (
                                <MenuItem
                                    key={index}
                                    value={row.district || ""}
                                    onClick={() => handleSelectChannelDistrict(row.id, row.district)}
                                >
                                    {row.district}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="ຊື່ບ້ານ"
                            variant="outlined"
                            onChange={handleChannelVillage}
                            fullWidth
                        />
                    </Grid>
                </Grid>
             </DialogContent>
             <DialogActions>
                <Button onClick={ () => {Submit(); handleClose()}}>
                    ບັນທຶກ
                </Button>
                <Button onClick={handleClose}>ຍົກເລີກ</Button>
            </DialogActions>
        </Dialog>
    )
}