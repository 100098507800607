import React from 'react';
import { Route } from "react-router-dom";
import Appbar from 'Bt_modules/Appbar/Appbar';
import Footer from 'Apps/Public/Footer/Footer';
import Homepage from 'Apps/Public/Home/Homepage'
import Aboutus from 'Apps/Public/Aboutus/Aboutus'
import Ourproducts from 'Apps/Public/Ourproducts/Ourproducts'
import NewsEvents from 'Apps/Public/News&Events/NewsandEvents'
import Contactus from 'Apps/Public/Contactus/Contactus'
import { useTranslation } from 'react-i18next';

export default function Index() {
    const { t, i18n } = useTranslation()

    return (
        <div>
            <Appbar t={t} i18n={i18n} />
            <div style={{ padding: '0.1% 2%'}}>
                <div style={{ backgroundColor: '#E9FBFF', height: '100%'}}>
                    <Route path="/contactus" exact component={() => <Contactus t={t} i18n={i18n} />} />
                    <Route path="/newsevents" exact component={() => <NewsEvents t={t} i18n={i18n} />} />
                    <Route path="/ourproducts" exact component={() => <Ourproducts t={t} i18n={i18n} />} />
                    <Route path="/aboutus" exact component={() => <Aboutus t={t} i18n={i18n} />} />
                    <Route path="/" exact component={() => <Homepage t={t} i18n={i18n} />} />
                </div>
            </div>
            <div style={{ padding: '0.2% 0' }} />
            <Footer t={t} i18n={i18n} />
        </div>
    )
}
