import { Card, Menu, MenuItem } from '@material-ui/core'
import React from 'react'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Box } from '@mui/material'
import { makeStyles } from '@material-ui/core/styles';
import { Link, useLocation } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({

    btnActive: {
        backgroundColor: theme.palette.primary.dark,
        textAlign: 'center',
        cursor: 'pointer',
        borderRadius: 5,
        padding: '1%',
        //backgroundColor: 'red',
        color: theme.palette.background.default,
        fontWeight: 'bold'
    },
    btn: {
        //backgroundColor: theme.palette.primary.main,
        textAlign: 'center',
        cursor: 'pointer',
        borderRadius: 5,
        padding: '1%'
    }
}));

export default function CardMenu(props) {
    const classes = useStyles();
    const location = useLocation();
    const [anchorEl, setAnchorEl] = React.useState(null);


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    React.useEffect(() => {
        if (location.pathname === '/') {
            props.setActive(location.pathname)
        } else if(location.pathname === '/aboutus'){
            props.setActive(location.pathname)
        }else if(location.pathname === '/ourproducts'){
            props.setActive(location.pathname)
        }else if(location.pathname === '/newsevents'){
            props.setActive(location.pathname)
        }else if(location.pathname === '/contactus'){
            props.setActive(location.pathname)
        }

    }, [props, location.pathname])

    //xs={10} sm={10} md={10} lg={10} xl={10}
    return (
        <Box sx={{ minWidth: { xl: '200px', lg: '150px', md: '125px', sm: '120px', xs: '200px' } }}>
            <Card sx={{ minWidth: { xl: '200px', lg: '150px', md: '125px', sm: '125px', xs: '200px' } }}>
                <Box sx={{ padding: { xl: '5%', lg: '5%', md: '5%', sm: '7%', xs: '10%' } }} >
                    {props.id === '1' ?
                        <Box sx={{ padding: { xl: '15% 20%', lg: '15% 20%', md: '15% 20%', sm: '15% 20%', xs: '15% 20%' }, height: { xl: '155px', lg: '120px', md: '105px', sm: '100px', xs: '120px' } }}>
                            <Box style={{ border: 'solid 0.1px', borderRadius: 8, backgroundColor: '', cursor: 'pointer', display: 'flex', justifyContent: 'center' }} onClick={handleClick}>

                                {props.text}
                                <ArrowDropDownIcon />

                            </Box>
                            <Menu
                                id="customized-menu"
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                elevation={2}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <MenuItem onClick={() => { props.i18n.changeLanguage("la")}}>ລາວ</MenuItem>
                                <MenuItem onClick={() => { props.i18n.changeLanguage("en")}}>English</MenuItem>
                            </Menu>
                        </Box>
                        :
                        <Link to={props.path} style={{ textDecoration: 'none', color: 'black' }} onClick={() => { props.handleActive(props.path) }}>
                            <div style={{ padding: '5% 5%' }}>
                                <img src={props.img} alt="" style={{ width: '100%' }} />
                            </div>
                            <div className={props.active === props.path ? classes.btnActive : classes.btn} >
                                {props.text}
                            </div>
                        </Link>
                    }
                </Box>
            </Card>
        </Box>
    )
}

