import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Grid, TextField, Button, Typography, DialogContent, DialogActions } from '@material-ui/core';
import Axios from 'axios';

export default function DialogAddChannelType(props) {

    const [channelType, setChannelType] = useState("");
    const handleClose = () => {
        props.setOpen(false);
    };
    const handleChannelType = (e) => {
        setChannelType(e.target.value);
    };
    const Submit = () =>{
        const options = {
            url: window.$api + "/addChannelType",
            method: "POST",
            headers:{
                "Accept": "application/json",
                ////"content-Type": "application/json;charset-UTF-8"
            },
            data: {
                "channelType" : channelType
            },
        };
        Axios(options).then(Response =>{
            alert(Response.data);
            const options = {
                url: window.$api + "/getchannelType",
                method: "POST",
                headers:{
                    "Accept": "application/json",
                    //"content-Type": "application/json;charset-UTF-8"
                },
            };
            Axios(options).then(Response =>{
                props.setChannelType(Response.data);
                setChannelType("");
            });
        });
    };

    return (
        <Dialog       
            maxWidth = 'xl'
            open={props.open}
        >
             <DialogContent>
                <Grid container spacing={2} >
                    <Grid item xs={12} >
                        <Typography>
                            ເພີ່ມປະເພດຊ່ອງທາງຈໍາໜ່າຍ
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="ຊື່ປະເພດ"
                            variant="outlined"
                            onChange={handleChannelType}
                            fullWidth
                        />
                    </Grid>
                </Grid>
             </DialogContent>
             <DialogActions>
                <Button onClick={ () => {Submit(); handleClose()}}>
                    ບັນທຶກ
                </Button>
                <Button onClick={handleClose}>ຍົກເລີກ</Button>
            </DialogActions>
        </Dialog>
    )
}