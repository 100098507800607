import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
//import BorderColorIcon from '@material-ui/icons/BorderColor';
import CheckIcon from '@material-ui/icons/Check';
import DoneAllIcon from '@material-ui/icons/DoneAll';
// import VisibilityIcon from '@material-ui/icons/Visibility';
// import DeleteIcon from '@material-ui/icons/Delete';
import NumberFormat from 'react-number-format'
//import DialogWarning from '../Dialog/DialogWarning';
import Axios from 'axios';
import Button from '@material-ui/core/Button';
import ReturnList from 'Bt_modules/Sale/ReturnList';
import { Typography, Grid, TextField } from '@mui/material';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ReactToPrint from 'react-to-print';
import PrintIcon from '@material-ui/icons/Print';
import OrderPrint from 'Bt_modules/Sale/OrderPrint';
import Fab from '@material-ui/core/Fab';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.background.dark,
        color: theme.palette.background.default,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);


const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});

export default function TableOrders(props) {
    const classes = useStyles();
    const [order, setOrder] = React.useState([]);
    const [sumData, setSumData] = React.useState([]);
    const [isEditable, setEditable] = React.useState(false);
    const [selectedStaf, setSelectedStaf] = React.useState();
    const stafAutoFocus = React.useRef();
    const [stafList, setStafList] = React.useState([])
    const componentRef = React.useRef();

    React.useEffect(() => {
        //stafAutoFocus.current.focus()
        let isMounted = true;
        const options = {
            url: window.$api + "/getReturnsByBillcode",
            method: "POST",
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                //"content-Type": "application/json;charset-UTF-8"
            },
            data: {
                "billcode": props.billcode,
                //"qty": props.qty,
                //"barcode": props.barcode
            },
        };
        Axios(options).then(Response => {

            if(Response.data.sumOrderData) {
                setSumData(Response.data.sumOrderData);
            }

            if(Response.data.orderData) {
                let orderData = [];
                Response.data.orderData.map((row) => {
                    {row.add_product_qty ? row.add_product_qty = row.add_product_qty : row.add_product_qty = 0}
                    {row.discount_rate ? row.discount_rate = row.discount_rate : row.discount_rate = 0}
                    {row.discount ? row.discount = row.discount : row.discount = 0}
                    orderData.push(
                        {
                            "id":row.id,
                            "name":row.product_name,
                            "barcode":row.product_barcode,
                            "billcode":row.billcode,
                            "qty":row.quantity,
                            "sale_qty":row.quantity,
                            "price":row.price,
                            "add_product_name":row.add_product_name,
                            "add_product_barcode":row.add_product_barcode,
                            "added_qty":row.add_product_qty,
                            "discount_rate":row.discount_rate,
                            "discount":row.discount,
                            "total":row.total
                        }
                    )
                })
                setOrder(orderData)
            }
        });
        return () => { isMounted = false };
    }, [props])

    const handleSelectStaf = (name, id, phone) => {
        setSelectedStaf({"name": name, "id": id, "phone": phone})
    }

    const ResetSelectedStaf = () => {
        setSelectedStaf("");
    }

    const handleType = (e) => {
        let text = e.target.value;
        if(text){
            const options = {
                url: window.$api + "/searchStaf",
                method: "POST",
                withCredentials: true,
                headers:{
                    "Accept": "application/json",
                },
                data: {
                    key: e.target.value
                }
            };
            Axios(options).then(res => {
                if(res.data.retailerList) {
                    setStafList(res.data.retailerList);
                } else {
                    setStafList([]);
                }
                
            })
        }else{
            setStafList([]);
        }
    };

    const handleSale = () => {
        const options = {
            url: window.$api + "/processReturn",
            method: "POST",
            headers: {
                "Accept": "application/json",
                //"content-Type": "application/json;charset-UTF-8"
            },
            data: {
                tableData: order,
                // cus_cat_id: selectedCustomerCatId,
                //price_id: selectedPriceCatId,
                //total: total,
                //discount: discount,
                //billAddProduct: sumData.billAddProductBarcode,
                //billAddQty: sumData.billAddQty,
                //billDiscRate: billDiscRate,
                //customer_id: selectedRetailer.id,
                billcode: sumData.billcode,
                total: sumData.total,
                staff_id: sumData.staff,
                //staffId: selectedStaf.id,
                //billSatus: "2",
                token: props.accessToken
            },
        }
        Axios(options).then(res => {
            //console.log(res.data);
            if(res.data.status === 200){
                alert(res.data.mesg);
                setOrder([]);
                setSumData([]);
                setSelectedStaf();
                props.removeReturn(props.selectedRowId);
                props.handleClose();
                //setChange("");
                //setCash("");
                //setTotal("");
                //setQty("");
                //setAllQty("");
                //setPrice("");
                //setName("");
                //setBillDiscRate("");
                //setSelectedRetailer("");
                //setBarcode("");
                //setdiscount(0);
                //setBillAddProduct("");
                //setBillAddQty(0);
                //setBillcode(billcode + 1)
            }
        });
    }


    return (
        <div>
            <Grid container>
                <Grid container item>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={1}>
                        <TextField
                            label="ເລກບິນ"
                            variant="outlined"
                            color="secondary"
                            disabled
                            value={sumData.billcode || ""}
                            style={{width: '98%'}}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="ໍພະນັກງານ"
                            variant="outlined"
                            color="secondary"
                            disabled
                            value={sumData.staff_id || ""}
                            style={{width: '98%'}}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <TextField
                            label="ໍສະຖານະ"
                            variant="outlined"
                            disabled
                            value={sumData.order_status ? sumData.order_status : "ໃໝ່"}
                            style={{width: '98%'}}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            label="ໍເວລາສັ່ງ"
                            variant="outlined"
                            disabled
                            value={sumData.date + " " + sumData.time}
                            style={{width: '98%'}}
                        />
                    </Grid>
                </Grid>
                <Grid container item style={{justifyContent: "center", marginTop: 10}}>
                    {/* {selectedStaf.id} */}
                    <Grid item xs={8}>
                        <Grid item>
                            <ReturnList
                                tableData={order}
                                setTableData={setOrder}
                                isEditable={isEditable}
                                sumData={sumData}
                                setSumData={setSumData}
                            />
                        </Grid>
                        
                        <Grid container >
                            <Grid container item xs={12} style={{height: 30}}></Grid>
                            <Grid container item xs={11} ></Grid>
                            <Grid container item xs={1} >
                                <Grid item>
                                    <ReactToPrint
                                        trigger={() => { return <Fab color="secondary" className={classes.fab}><PrintIcon /></Fab> }}
                                        content={() => componentRef.current}
                                        onAfterPrint={handleSale}
                                    />
                                    <div hidden={true}>
                                        <OrderPrint tableData={order} total={sumData.total} discount={sumData.discount} billDiscRate={sumData.discount_rate} billAddQty={sumData.free_qty} billAddProduct={sumData.free_product} customerName={sumData.name} customerPhone={sumData.phone} billcode={sumData.billcode} selectedStaf={selectedStaf} componentRef={componentRef} />
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>

                    <Grid item xs={2} style={{marginLeft: 20}}>
                        <Grid container item>
                            <Grid item xs={12} style={{ backgroundColor: "#ADD8E6"}}>
                                <Typography style={{textAlign: "center"}}>ສະຫຼຸບ</Typography>
                            </Grid>
                            <Grid item xs={6} style={{ padding: "5%"}}>
                                <Typography>ຍອດຂາຍລວມ</Typography>
                            </Grid>
                            <Grid item xs={6} style={{ paddingTop: "5%", paddingLeft: "1%"}}>
                                <Typography style={{textAlign: "end"}}>
                                    <NumberFormat value={sumData.total || 0} displayType={'text'} thousandSeparator={true} suffix={' ກີບ'} />
                                </Typography>
                            </Grid>
                            {sumData.discount_rate > 0 &&
                            <>
                                <Grid item xs={4} style={{ padding: "5%"}}>
                                    <Typography>ສ່ວນຫຼຸດ%</Typography>
                                </Grid>
                                <Grid item xs={2} style={{ paddingTop: "5%", paddingLeft: "1%"}}>
                                    <Typography style={{textAlign: "end"}}>
                                        <NumberFormat value={sumData.discount_rate || 0} displayType={'text'} thousandSeparator={true} suffix={' %'} />
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{ paddingTop: "5%", paddingLeft: "1%"}}>
                                    <Typography style={{textAlign: "end"}}>
                                        <NumberFormat value={(sumData.total * sumData.discount_rate)/100 || 0} displayType={'text'} thousandSeparator={true} suffix={' ກີບ'} />
                                    </Typography>
                                </Grid>
                            </>
                            }
                            {sumData.discount > 0 &&
                            <>
                                <Grid item xs={6} style={{ padding: "5%"}}>
                                    <Typography>ສ່ວນຫຼຸດ</Typography>
                                </Grid>
                                <Grid item xs={6} style={{ paddingTop: "5%", paddingLeft: "1%"}}>
                                    <Typography style={{textAlign: "end"}}>
                                        <NumberFormat value={sumData.discount || 0} displayType={'text'} thousandSeparator={true} suffix={' ກີບ'} />
                                    </Typography>
                                </Grid>
                            </>
                            }
                            {sumData.free_qty > 0 &&
                            <>
                                <Grid item xs={3} style={{ padding: "5%"}}>
                                    <Typography>ແຖມ</Typography>
                                </Grid>
                                <Grid item xs={9} style={{ paddingTop: "5%", paddingLeft: "1%"}}>
                                    <Typography style={{textAlign: "end"}}>
                                        {sumData.free_product + " x " + sumData.free_qty}
                                    </Typography>
                                </Grid>
                            </>
                            }
                            {sumData.discount > 0 &&
                            <>
                                <Grid item xs={6} style={{ padding: "5%"}}>
                                    <Typography>ລາຄາລວມ</Typography>
                                </Grid>
                                <Grid item xs={6} style={{ paddingTop: "5%", paddingLeft: "1%"}}>
                                    <Typography style={{textAlign: "end"}}>
                                        <NumberFormat value={Number(sumData.total) - (Number(sumData.discount) + ((Number(sumData.total) * Number(sumData.discount_rate))/100))} displayType={'text'} thousandSeparator={true} suffix={' ກີບ'} />
                                    </Typography>
                                </Grid>
                            </>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}