import React from 'react'
import SignIn from 'Bt_modules/Authen/Signin'
import { Redirect } from "react-router-dom";

export default function Signin(props) {
    return (
        <div >
            {!props.authenticated ?
                <SignIn setAuthenticated={props.setAuthenticated} setAccessToken={props.setAccessToken} setUserInfo={props.setUserInfo}/>
            :
                <Redirect
                    to={{
                    pathname: "/admin",
                    }}
                />
            }
        </div>
    )
}
