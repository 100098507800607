import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Axios from 'axios';
import EditUser from '../AddNewProduct/EditUser';
import Backdrop from '../Backdrop/Backdrop'

export default function EditUserDiag(props) {
  // const [channelType, setChannelType] = useState([]);
  // const [channelInfo, setChannelInfo] = useState({})
  const [openBackdrop, setopenBackdrop] = useState(false)
  // const [village, setVillage] = useState([]);
  // const [district, setDistrict] = useState([]);
  // const [province, setProvince] = useState([]);


  // useEffect(() => {
  //   let isMounted = true;
  //   const options = {
  //     url: window.$api + "/getChannelsVDP",
  //     method: "GET",
  //     withCredentials: true,
  //     headers: {
  //       "Accept": "application/json",
  //       ////"content-Type": "application/json;charset-UTF-8"
  //     },
  //   };
  //   Axios(options).then(Response => {
  //     if (isMounted) {
  //     props.setVillage(Response.data.village)
  //     props.setDistrict(Response.data.district)
  //     props.setProvince(Response.data.province)
  //     }
  //   })
  //   return () => { isMounted = false };
  // }, [props.setVillage, props.setDistrict, props.setProvince ]);

  // useEffect(() => {
  //   let isMounted = true;
  //   const options = {
  //     url: window.$api + "/getChannelInfo",
  //     method: "POST",
  //     headers: {
  //       "Accept": "application/json",
  //       ////"content-Type": "application/json;charset-UTF-8"
  //     }
  //   }
  //   Axios(options).then(Response => {
  //     if (isMounted) {
  //     //setChannelInfo(Response.data.channelInfo);
  //     props.setChannelType(Response.data.channelTypes);
  //     props.setCustomerPrice(Response.data.customer)
  //     }
  //   });
  //   return () => { isMounted = false };
  // }, [props.setChannelType, props.setCustomerPrice]);

  // const handleChange = (e) => {
  //   props.setChannelInfo({
  //     ...props.channelInfo,
  //     [e.target.name]: e.target.value
  //   })
  // }

  const handleClose = () => {
    props.setOpen(false);
  };

  // const handleSelectChannelCustomerPrice = (id, name) => {
  //   props.setChannelInfo({
  //     ...props.channelInfo,
  //     selectChannelCustomerPriceId: id,
  //     selectChannelCustomerPriceName: name
  //   })
  // }

  // const handleSelectChannelType = (id, name) => {
  //   props.setChannelInfo({
  //     ...props.channelInfo,
  //     selectChannelTypeId: id,
  //     selectChannelTypeName: name
  //   })
  // }

  // const handleSelectChannelVillage = (id, name) => {
  //   props.setChannelInfo({
  //     ...props.channelInfo,
  //     selectChannelVillageId: id,
  //     selectChannelVillageName: name
  //   })
  // }

  // const handleSelectChannelDistrict = (id, name) => {
  //   props.setChannelInfo({
  //     ...props.channelInfo,
  //     selectChannelDistrictId: id,
  //     selectChannelDistrictName: name
  //   })
  // }

  // const handleSelectChannelProvince = (id, name) => {
  //   props.setChannelInfo({
  //     ...props.channelInfo,
  //     selectChannelProvinceId: id,
  //     selectChannelProvinceName: name
  //   })
  // }

  const Submit = () => {
    setopenBackdrop(true)
    const options = {
      url: window.$api + "/updateUser",
      method: "POST",
      headers: {
        "Accept": "application/json",
      },
      data: {
        "id": props.selectedUser.id || null,
        "username": props.selectedUser.username || null,
        "password": props.selectedUser.password || null,
        "firstname": props.selectedUser.firstname || null,
        "lastname": props.selectedUser.lastname || null,
        "position": props.selectedUser.position || null,
        "permission": props.selectedUser.permission || null,
      },
    };
    Axios(options).then(Response => {
      if (Response.data.status === 1) {
        alert(Response.data.mesg)
        //console.log(Response.data)
        setopenBackdrop(false)
        props.setOpen(false)
        var newArr = [...props.data]
        newArr.forEach(el => {
          if (el.id  === props.selectedUser.id) {
              el.username = props.selectedUser.username
              el.password = props.selectedUser.password
              el.firstname = props.selectedUser.firstname
              el.lastname = props.selectedUser.lastname
              el.position = props.selectedUser.position
              el.permission = props.selectedUser.permission
          }
      })
        props.setData(newArr)
      }
      //console.log(Response.data)
    });
  };

  const validateData = () => {
    if (!props.selectedUser.firstname) {
      alert("ກະລຸນາຕື່ມຂໍ້ມູນຊື່");
    } else {
      if (!props.selectedUser.username) {
        alert("ກະລຸນາຕື່ມຂໍ້ມູນຊື່ເຂົ້າໃຊ້ລະບົບ");
      } else {
        if (!props.selectedUser.password) {
          alert("ກະລຸນາຕື່ມຂໍ້ມູນລະຫັດ");
        } else {
          if (!props.selectedUser.lastname) {
            alert("ກະລຸນາຕື່ມຂໍ້ມູນນາມສະກຸນ");
          } else {
            if (!props.selectedUser.position) {
              alert("ກະລຸນາຕື່ມຂໍ້ມູນຕໍາແໜ່ງ");
            } else {
              if (!props.selectedUser.permission) {
                alert("ກະລຸນາຕື່ມຂໍ້ມູນລະດັບສິດ");
              } else {
                Submit()
              }
            }
          }
        }
      }

    }
  }

  return (
    <div>
      <Dialog
        maxWidth='xl'
        fullWidth={true}
        open={props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <EditUser
            selectedUser={props.selectedUser}
            handleChange={props.handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={validateData}
            autoFocus
          >
            ບັນທຶກ
          </Button>
          <Button
            variant="contained"
            style={{ backgroundColor: "#FF0000", color: "#FFFFFF" }}
            onClick={handleClose}
          >
            ຍົກເລີກ
          </Button>
        </DialogActions>
        <Backdrop open={openBackdrop} />
      </Dialog>
    </div>
  );
}