import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
//import BorderColorIcon from '@material-ui/icons/BorderColor';
import CheckIcon from '@material-ui/icons/Check';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { Button, Typography } from '@material-ui/core';
import Axios from 'axios';
// import VisibilityIcon from '@material-ui/icons/Visibility';
// import DeleteIcon from '@material-ui/icons/Delete';
//import NumberFormat from 'react-number-format'
//import DialogWarning from '../Dialog/DialogWarning';
// import PrintIcon from '@material-ui/icons/Print';
// import ReactToPrint from 'react-to-print';
// import OrderPrint from 'Bt_modules/Order/OrderPrint';
// import Axios from 'axios';

const StyledTableCell = withStyles((theme) => ({
    head: {
        //backgroundColor: theme.palette.background.dark,
        backgroundColor: "#ADD8E6",
        color: theme.palette.background.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);


const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});

export default function TableOrderAllBillcode(props) {
    const classes = useStyles();
    // const componentRef = useRef();
    // const [tableData, setTableData] = React.useState([])

    const handleCompleted = (bill, rowId) => {
        const options = {
            url: window.$api + "/completeOrder",
            method: "POST",
            headers: {
                "Accept": "application/json",
            },
            data: {
                "billcode": bill,
                token: props.accessToken
            },
        }
        Axios(options).then(res => {
            if(res.data.status === 200){
                alert(res.data.mesg);
                props.updateOrderStatus(rowId, "ສໍາເລັດ");
            }
        });
    }


    // const GetOrder = (bill, status) => {
    //     const options = {
    //         url: window.$api + "/getOrderByBillcodePrint",
    //         method: "POST",
    //         withCredentials: true,
    //         headers: {
    //             "Accept": "application/json",
    //             //"content-Type": "application/json;charset-UTF-8"
    //         },
    //         data: {
    //             "billcode": bill,
    //             "status" : status
    //         },
    //     };
    //     Axios(options).then(Response => {
    //         let arr = Response.data;
    //         arr.forEach((obj) => {
    //             if (obj.status === 'New') {
    //                 obj.color = "red";
    //             } else if (obj.status === 'Pending') {
    //                 obj.color = "orange";
    //             } else if (obj.status === 'Delivery') {
    //                 obj.color = "green";
    //             }
    //             return obj;
    //         })
    //         setTableData(arr)
    //         //Print(arr);
    //     });
    // }

    // const Print = (arr) => {

    //     if (arr) {
    //         console.log('data', arr)
    //         return (
    //             <div>
    //                 <ReactToPrint
    //                     trigger={() => { return <div onClick={() => { console.log('Print'); }}><PrintIcon /></div> }}
    //                     content={() => componentRef.current}
    //                     onAfterPrint={() => { console.log('Print'); }}
    //                 />
    //                 <div hidden={true} onClick={() => { console.log('Print'); }}>
    //                     <OrderPrint tableData={arr} componentRef={componentRef} />
    //                 </div>
    //             </div>
    //         )
    //     }
    // }


    // const PrintOrder = (billcode, barcode, status) => {
    //     const options = {
    //         url: window.$api + "/saleOrder",
    //         method: "POST",
    //         withCredentials: true,
    //         headers: {
    //             "Accept": "application/json",
    //             //"content-Type": "application/json;charset-UTF-8"
    //         },
    //         data: {
    //             "billcode": billcode,
    //             "barcode": barcode,
    //             "status": status
    //         },
    //     };
    //     Axios(options).then(Response => {
    //         console.log(Response.data)
    //     });
    // }


    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ວັນທີສັ່ງ</StyledTableCell>
                        <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ເລກບິນ</StyledTableCell>
                        <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ຊື່ຮ້ານ</StyledTableCell>
                        <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ຊື່ລູກຄ້າ</StyledTableCell>
                        <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ເບີໂທ</StyledTableCell>
                        <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ສະຖານະ</StyledTableCell>
                        <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ພະນັກງານ</StyledTableCell>
                        <StyledTableCell align="center" style={{ fontWeight: 'bold' }}></StyledTableCell>
                        <StyledTableCell align="center" style={{ fontWeight: 'bold' }}></StyledTableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {props.order.map((row, index) => (
                        row.order_status !== "ສໍາເລັດ" &&
                        <StyledTableRow key={row.id}>
                            <StyledTableCell align="center">{row.date} {row.time}</StyledTableCell>
                            <StyledTableCell align="center">{row.billcode}</StyledTableCell>
                            <StyledTableCell align="center">{row.name}</StyledTableCell>
                            <StyledTableCell align="center">{row.firstname}</StyledTableCell>
                            <StyledTableCell align="center">{row.phone}</StyledTableCell>
                            {row.order_status ? 
                                <StyledTableCell align="center">{row.order_status}</StyledTableCell>
                                :
                                <StyledTableCell align="center"><Typography style={{color: "red"}}>ໃໝ່</Typography></StyledTableCell>
                            }
                            {/* {row.staff_id && <StyledTableCell align="center">{row.staff_id}</StyledTableCell>} */}
                            <StyledTableCell align="center">{row.staff_id}</StyledTableCell>
                            {row.order_status ?
                                <>
                                    <StyledTableCell align="center"><Button variant='contained' color='primary' onClick={() => { handleCompleted(row.billcode, row.id)}}>ສໍາເລັດ</Button></StyledTableCell>
                                    <StyledTableCell align="center"></StyledTableCell>
                                </>
                                :
                                <>
                                    <StyledTableCell align="center"><Button variant='contained' color='primary' onClick={() => { props.handleClickOpen(row.billcode, row.id)}}>ດໍາເນີນການ</Button></StyledTableCell>
                                    <StyledTableCell align="center"><Button variant='contained' color='secondary'>ຍົກເລີກ</Button></StyledTableCell>
                                </>
                            }
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer >
    );
}

//TableOrders