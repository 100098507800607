import { Grid, TextField, Typography } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { Box } from '@mui/material'
//import TableOrders from 'Bt_modules/Tables/TableOrders'
//import { makeStyles } from '@material-ui/core/styles';
//import NumberFormat from 'react-number-format'
import Axios from 'axios';
import TableOrderAllBillcode from 'Bt_modules/Tables/TableOrderAllBillcode'
import DialogOrderByBillcode from 'Bt_modules/Dialog/DialogOrderByBillcode'
import { Redirect } from "react-router-dom";

export default function Orders(props) {
  const [order, setOrder] = useState([]);
  const [open, setOpen] = React.useState(false);
  // const [open2, setOpen2] = React.useState(false);
  // const [status, setStatus] = React.useState();
  const [barcode, setBarcode] = React.useState();
  const [billcode, setBillcode] = React.useState();
  const [selectedRowId, setSelectedRowId] = React.useState();
  const [qty, setQty] = React.useState();
  const [status, setStatus] = React.useState();
  //const [printStatus, setPrintStatus] = React.useState();

  useEffect(() => {
    let isMounted = true;
    const options = {
      url: window.$api + "/getOrder",
      method: "GET",
      withCredentials: true,
      headers: {
        "Accept": "application/json",
        //"content-Type": "application/json;charset-UTF-8"
      },
    };
    Axios(options).then(Response => {

      if (isMounted) {
        let arr = Response.data;
        let newArr = [];

        arr.forEach(function (newData) {
          newArr.push(newData.status)
        });
        setOrder(arr)

        // let result = [];

        // arr.forEach(function (a) {
        //   if (!this[a.billcode]) {
        //     this[a.billcode] = { id: a.id, date: a.date, time: a.time, billcode: a.billcode, username: a.username, color: a.color, barcode: a.barcode, status: a.status };
        //     result.push(this[a.billcode]);
        //   }
        // }, Object.create(null));

        // let newArr2 = [];

        // result.forEach((status) => {
        //   const checkBill = arr.filter(billcodes => billcodes.billcode === status.billcode);

        //   checkBill.forEach(function (newData) {
        //     newArr2.push(newData.status)
        //   });

        //   if (newArr2.includes('Pending')) {
        //     status.orderStatus = 'Pending';
        //     status.color = "orange";
        //   } else if (newArr2.includes('Delivery')) {
        //     if (newArr2.includes('New')) {
        //       status.orderStatus = 'Pending';
        //       status.color = "orange";
        //     } else {
        //       status.orderStatus = 'Delivery';
        //       status.color = "green";
        //     }
        //   } else {
        //     status.orderStatus = 'New';
        //     status.color = "red";
        //   }
        //   newArr2 = [];

        // })
        // setOrder(result)
      }
    });
    return () => { isMounted = false };
  }, [])

  const handleClickOpen = (bill, rowId) => {
    setOpen(true);
    setBillcode(bill)
    setSelectedRowId(rowId)
    //setQty(qty)
    //setBarcode(barcode)
    //setStatus(status)
  };

  const handleClose = () => {
    setOpen(false);
    //window.location.reload();

  };

  const updateOrderStatus = (id, status) => {
    let updated = [...order];
    updated.map((row) => {
      if(row.id === id) {
        row.order_status = status;
      }
    })
    setOrder(updated);
  };


  if (props.authenticated) {
    return (
      <div style={{ padding: '1% 0.5%' }}>
        <br />

        <Grid container spacing={2} >
          {/* <Grid item xl={5} lg={5} md={5} sm={6} xs={12} style={{ backgroundColor: '', }}>
            <Box sx={{ display: { xl: 'flex', lg: 'flex', md: 'flex', sm: 'flex', xs: 'flex' } }}>
              <TextField
                id="date"
                label="ວັນທິເລີ່ມຕົ້ນ"
                type="date"
                InputLabelProps={{
                  shrink: true,
                  required: true
                }}
                variant="outlined"
                fullWidth
              />
              &nbsp;&nbsp;&nbsp;
              <TextField
                id="date"
                label="ວັນທິສິ້ນສຸດ"
                type="date"
                InputLabelProps={{
                  shrink: true,
                  required: true,
                }}
                variant="outlined"
                fullWidth
              />
            </Box>
          </Grid> */}
          <Grid item xl={7} lg={7} md={7} sm={6} xs={12}>
            {/* <Box
              sx={{
                display: { xl: 'flex', lg: 'flex', md: 'flex', sm: 'flex', xs: 'flex' },
                justifyContent: { xl: 'flex-end', lg: 'flex-end', md: 'flex-end', sm: '', xs: '' },
              }}
            >
              <Box
                sx={{
                  padding: { xl: '1.3% 2%', lg: '2.2% 2%', md: '2.5% 2%', sm: '4% 2%', xs: '1.3% 2%' },
                }}
              >
                <Typography>ຍອດມູນຄ່າສັ່ງຊື້ທັງໝົດ:</Typography>
              </Box>
              <Box
                sx={{
                  padding: { xl: '1.3% 6%', lg: '2.2% 6%', md: '2.5% 6%', sm: '4% 6%', xs: '1.3% 6%' },
                  backgroundColor: '#F5F0F0',
                  borderRadius: 3,
                }}>
                <Typography>
                  <NumberFormat value="1857260638" displayType={'text'} thousandSeparator={true} suffix={' ກີບ'} />
                </Typography>
              </Box>
            </Box> */}
          </Grid>
        </Grid>
        <br /><br />
        <Grid container style={{ padding: '' }}>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={6} style={{ backgroundColor: '', padding: '0.3%' }}>
            <Box
              sx={{
                padding: { xl: '1.3% 0', lg: '2.2% 0', md: '2.5% 0', sm: '4% 0', xs: '1.3% 0' },
              }}>

              <Typography>ລາຍການສັ່ງຊື້ທັງໝົດ</Typography>

            </Box>
          </Grid>
          {/* <Grid item xl={6} lg={6} md={6} sm={6} xs={6} style={{ backgroundColor: '', }}>
            <Box
              sx={{
                display: { xl: 'flex', lg: 'flex', md: 'flex', sm: 'flex', xs: 'flex' },
                justifyContent: { xl: 'flex-end', lg: 'flex-end', md: 'flex-end', sm: 'flex-end', xs: 'flex-end' },
              }}
            >
              <Box
                sx={{
                  padding: { xl: '1.3% 6%', lg: '2.2% 6%', md: '2.5% 6%', sm: '4% 6%', xs: '1.3% 6%' },
                  //backgroundColor: '#3E80EE',
                  backgroundColor: '#ADD8E6',
                  borderRadius: 1,
                  //color: '#fff',
                  cursor: 'pointer',
                  ":active": {
                    backgroundColor: '#BDBBB9',
                  },

                }}>
                <Typography style={{ fontWeight: 'bold'}}>
                  ດຶງຂໍ້ມູນ
                </Typography>
              </Box>

            </Box>
          </Grid> */}
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ backgroundColor: 'yellow', }}>
            <TableOrderAllBillcode order={order} handleClickOpen={handleClickOpen} updateOrderStatus={updateOrderStatus} accessToken={props.accessToken}/>
          </Grid>
        </Grid>
        <DialogOrderByBillcode open={open} handleClose={handleClose} billcode={billcode} qty={qty} barcode={barcode} status={status} accessToken={props.accessToken} updateOrderStatus={updateOrderStatus} selectedRowId={selectedRowId}/>
      </div>
    )
  } else {
    return (
      <div>
        <Redirect
          to={{
            pathname: "/admin/sign-in",
          }}
        />
      </div>
    )
  }
}

