import React from 'react'
import MoveProduct from 'Bt_modules/productcase/MoveProdPackToSell/MoveProdPackToSell'
import { Redirect } from "react-router-dom";

export default function MoveProdPackToSell(props) {
    if (props.authenticated) {
        return (
            <div>
                <MoveProduct accessToken={props.accessToken} />
            </div>
        )
    } else {
        return (
            <div>
                <Redirect
                    to={{
                        pathname: "/admin/sign-in",
                    }}
                />
            </div>
        )
    }
}
