import React, { useState } from 'react'
import { Grid, Container, TextField, Button, Fab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PrintIcon from '@material-ui/icons/Print';
import Axios from 'axios'
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PrintComponents from "react-print-components";
import Page from './PrintPricePage';

const columns = [
    { id: 'no', label: 'ລຳດັບ', minWidth: 100 },
    { id: 'barcode', label: 'ບາໂຄດ', minWidth: 120 },
    { id: 'name', label: 'ຊື່ສິນຄ້າ', minWidth: 120 },
    { id: 'price', label: 'ລາຄາ', minWidth: 120 },
    { id: 'amount', label: 'ຈຳນວນ', minWidth: 120 },
    { id: 'edit', label: 'ແກ້ໄຂ', minWidth: 120 },  
];

const useStyles = makeStyles((theme) => ({
    absolute: {
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 20,
        left: 'auto',
        position: 'fixed',
    },
}));

export default function PrintPrice() {
    const classes = useStyles();
    const [id, setId] = useState(1);
    const [barcode, setBarcode] = useState();
    const [tableData, setTableData] = useState([]);
    const [isEditable, setEditable] = useState();
    const [qty, setQty] = useState();

    const handleBarcode = (e) => {
        setBarcode(e.target.value)
    }

    const hasBarcode = (arr, barcode) => arr.some(el => el.barcode === barcode);

    const handleQty = (e) =>{
        setQty(e.target.value);
    }

    const handleEdit = (id) => {
        setEditable(id);
    }

    const handleInput = (e, f) =>{
        if(e.key === 'Enter'){
            let newArr = tableData.map((obj) => {
                if (obj.id === f) {
                    obj.qty = Number(qty);
                }
                return obj;                           
            })
            setTableData(newArr);
            setEditable("");
        }
    }

    const HandleEnter = (e) =>{
        if (e.key === 'Enter' || 'click'){
            const options = {
                url: window.$api + "/selectbarcode"   ,
                method: "POST",
                headers:{
                    "Accept": "application/json",
                    //"content-Type": "application/json;charset-UTF-8"
                },             
                data:{
                    "barcode": barcode,
                },
            }
            Axios(options).then(Response =>{
                if(Response.data.print){
                    let x = Response.data.print.product_barcode;
                    let y = Response.data.print.product_name;
                    let z= Response.data.print.price_kip;
                    if(hasBarcode(tableData, x) === true){
                        let newArr = tableData.map((obj) => {
                            if (obj.barcode && obj.barcode === x) {
                                obj.qty = obj.qty + 1;
                            }
                            return obj;                           
                        })
                        setTableData(newArr);
                        setBarcode("");
                    }else{
                        let newArray = [...tableData];
                        var tempdata = {
                            id: id,
                            barcode: barcode,
                            name: y,
                            price: z,
                            qty: 1,
                        }
                        newArray.push(tempdata);
                        setTableData(newArray);
                        setId(id + 1);
                        setBarcode("");
                    }
                }
                else{
                    alert('ບໍ່ມີບາໂຄດໃນຖານຂໍ້ມູນ');
                }
            });
        }
    }

    const handleDelete = (e, f, g) => {
        const del = tableData.filter(update => update.id !== e);
        setTableData(del);
    }

    return (
        <div>
            <Container maxWidth='xl'>
                <Grid container spacing={2} justifyContent='center'>
                    <Grid item sm={3} xs={12}>
                        <TextField 
                            id="outlined-basic" 
                            label="ລາຄາເປັນກີບ"  
                            variant="outlined" 
                            fullWidth
                            onKeyPress={HandleEnter}
                            onChange = {handleBarcode}
                            value={barcode || ""}
                        />
                    </Grid>
                    <Grid item sm={2} xs={12}>
                        <Button variant="contained" color="primary" style={{height: "100%",  }} onClick={HandleEnter}>ຕົກລົງ</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper style={{height: "68vh"}}>
                            <TableContainer style={{maxHeight: "68vh", borderRadius: 5}}>
                                <Table aria-label="customized table" stickyHeader>
                                    <TableHead>
                                            <TableRow>
                                                {columns.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{ minWidth: column.minWidth, color: "white", backgroundColor: "#15023a" }}
                                                    >
                                                            {column.label}
                                                    </TableCell>  
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                    <TableBody>
                                    {tableData.map((row) => (
                                        <TableRow key={row.id}>
                                            <TableCell>{row.id}</TableCell>
                                            <TableCell>{row.barcode}</TableCell>
                                            <TableCell>{row.name}</TableCell>
                                            <TableCell>{row.price}</TableCell>
                                            { isEditable === row.id? 
                                                <TableCell>
                                                    <TextField id="outlined-basic" size="small" variant="outlined" onChange={handleQty}
                                                    onKeyPress={(e)=> {handleInput(e, row.id)}}/>
                                                </TableCell> :
                                                <TableCell>{row.qty}</TableCell> 
                                            }
                                            <TableCell>
                                                <Button onClick={()=> handleEdit(row.id)}>
                                                    <EditIcon/>
                                                </Button>
                                                <Button onClick={()=> handleDelete(row.id)}>
                                                    <DeleteIcon/>
                                                </Button>
                                            </TableCell>                                          
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>                        
                        </Paper>
                    </Grid>
                </Grid>
                <PrintComponents 
                    trigger={
                        <Fab color="secondary" className={classes.absolute}>
                            <PrintIcon />
                        </Fab> 
                    }
                >
                    <Page tableData={tableData}/>
                </PrintComponents>
            </Container>
        </div>
    )
}
