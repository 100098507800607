import React, { useState } from 'react';
import { Button, TextField, Typography, makeStyles, Container } from '@material-ui/core';
import Axios from 'axios';
//mport Logo from '../../Img/onewater_logo.png'
import {useHistory} from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: "10%",
        backgroundColor: theme.palette.primary.dark,
        borderRadius: 15,
        width: '100%'
    },
    paper: {
        marginTop: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%',
        marginTop: '1%'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        borderRadius: 8,
        padding: '2%',
        width: '100%',
        backgroundColor: theme.palette.primary.main
    },
    registor: {
        margin: theme.spacing(3, 0, 2),
        marginLeft: '2%',
        borderRadius: 8,
        padding: '2%',
        width: '30%',
        backgroundColor: theme.palette.primary.main,
    },
    center: {
        justifyContent: 'center',
        marginTop: '2%',
    },
    textinput: {
        //color: '#3D3D3E', 
        marginLeft: '5%',
        marginBottom: '4%'
    },
    inputLable: {
        //color: '#3D3D3E', 
        marginLeft: '5%',
    },
    TextField: {
        backgroundColor: theme.palette.background.light,
        borderRadius: 8
    }
}));

export default function SignIn(props) {
    const classes = useStyles();
    const history = useHistory()
    const [user, setUser] = useState("");
    const [pass, setPass] = useState("");

    const handleUser = (e) => {
        setUser(e.target.value);
    }

    const handlePass = (e) => {
        setPass(e.target.value);
    }

    const handleLogin = () => {
        const options = {
            url: window.$api + "/login",
            method: "POST",
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                ////"content-Type": "application/json;charset-UTF-8"
            },
            data: {
                "username": user,
                "password": pass,
            }
        };
        Axios(options).then(Response => {
            if (Response.data.JWT) {
                props.setAuthenticated(true);
                props.setAccessToken(Response.data.JWT)
                props.setUserInfo(Response.data.userInfo)
                history.push('/admin/')
            } else {
                alert("Invalid username or Password");
            }
        }).catch(e => {
            console.log(e)
        })
    }

    return (
        <Container maxWidth="sm" className={classes.container}>
            <div className={classes.paper}>
                <div className={classes.center} style={{color: 'white', fontSize: '70px'}}>
                    {/* <img src={Logo} alt="logo" /> */}
                    One Water
                </div>
                <Typography variant="h4" style={{ fontWeight: 'bold', color: "white" }}>
                    ເຂົ້າສູ່ລະບົບ
                </Typography>
            </div>
            <div className={classes.form}>
                <TextField
                    margin="normal"
                    //required
                    fullWidth
                    multiline
                    id="username"
                    label="ຊື່ຜູ້ໃຊ້"
                    name="username"
                    autoFocus
                    onChange={handleUser}
                    InputProps={{ disableUnderline: true, className: classes.textinput, }}
                    InputLabelProps={{ className: classes.inputLable }}
                    className={classes.TextField}
                />
                <TextField
                    margin="normal"
                    //required
                    fullWidth
                    name="password"
                    label="ລະຫັດຜ່ານ"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onChange={handlePass}
                    InputProps={{ disableUnderline: true, className: classes.textinput }}
                    InputLabelProps={{ className: classes.inputLable }}
                    className={classes.TextField}
                />
                <div style={{ textAlign: 'center', display: 'flex' }}>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        className={classes.submit}
                        onClick={handleLogin}
                    >
                        ເຂົ້າສູ່ລະບົບ
                    </Button>

                    {/* <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        //color="primary"
                        onClick={()=> history.push('/admin/sign-up')}
                        className={classes.registor}
                    >
                        ລົງທະບຽນ
                    </Button> */}
                </div>
            </div>
        </Container>
    );
}

