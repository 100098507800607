import React from 'react'
import AddBusinessPartnerPage from 'Bt_modules/Buy/AddBusinessPartner'
import { Redirect } from "react-router-dom";

export default function AddBusinessPartner(props) {
    if (props.authenticated) {
        return (
            <div>
                <AddBusinessPartnerPage />
            </div>
        )
    } else {
        return (
            <div>
                <Redirect
                    to={{
                        pathname: "/admin/sign-in",
                    }}
                />
            </div>
        )
    }
}
