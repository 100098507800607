import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { IconButton } from '@material-ui/core';
import NumberFormat from 'react-number-format'

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.background.dark,
        color: theme.palette.background.default,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);


const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});

export default function TableReport(props) {
    const classes = useStyles();

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
                <TableHead>
                    <TableRow >
                        <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ວັນທີ</StyledTableCell>
                        <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ລາຍຮັບ</StyledTableCell>
                        <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ກຳໄລ</StyledTableCell>
                        <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ລາຍລະອຽດ</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.saleList.map((row, index) => (
                        <StyledTableRow key={row.id}>
                            <StyledTableCell align="center">{row.date}</StyledTableCell>
                            <StyledTableCell align="center">
                                <NumberFormat value={row.total} displayType={'text'} thousandSeparator={true} suffix={' ກີບ'} />
                            </StyledTableCell>
                            <StyledTableCell align="center" style={{ color: row.color }}>
                                <NumberFormat value={row.profit} displayType={'text'} thousandSeparator={true} suffix={' ກີບ'} />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <IconButton onClick={()=> props.handleViewSaleDetail(row.date)}>
                                    <VisibilityIcon color="secondary" style={{ cursor: 'pointer' }} />
                                </IconButton>
                                &nbsp;&nbsp;
                                {/* <IconButton>
                                    <DeleteIcon color="error" style={{ cursor: 'pointer' }} />
                                </IconButton> */}
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

//TableOrders