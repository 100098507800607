import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Grid, TextField, Button, Typography, DialogContent, DialogActions } from '@material-ui/core';
import Axios from 'axios';

export default function DialogAddCategory(props) {

    const [category, setCategory] = useState("");
    const handleClose = () => {
        props.setOpen(false);
    };
    const handleCategory = (e) => {
        setCategory(e.target.value);
    };
    const Submit = () =>{
        const options = {
            url: window.$api + "/addcategory"   ,
            method: "POST",
            headers:{
                "Accept": "application/json",
                ////"content-Type": "application/json;charset-UTF-8"
            },
            data: {
                "category" : category
            },
        };
        Axios(options).then(Response =>{
            alert(Response.data);
            const options = {
                url: window.$api + "/selectedcategories"   ,
                method: "GET",
                headers:{
                    "Accept": "application/json",
                    //"content-Type": "application/json;charset-UTF-8"
                },
            };
            Axios(options).then(Response =>{
                props.setCategory(Response.data.cate);      
                setCategory("");
            });
        });
    };

    return (
        <Dialog       
            maxWidth = 'xl'
            open={props.open}
        >
             <DialogContent>
                <Grid container spacing={2} >
                    <Grid item xs={12} >
                        <Typography>
                            ເພີ່ມປະເພດສິນຄ້າໃຫມ່
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="ຊື່ປະເພດ"
                            variant="outlined"
                            onChange={handleCategory }
                            fullWidth
                        />
                    </Grid>
                </Grid>
             </DialogContent>
             <DialogActions>
                <Button onClick={ () => {Submit(); handleClose()}}>
                    ບັນທຶກ
                </Button>
                <Button onClick={handleClose}>ຍົກເລີກ</Button>
            </DialogActions>
        </Dialog>
    )
}