import { Grid, TextField, Typography } from '@material-ui/core'
import React from 'react'
import { Box } from '@mui/material'
import TableStockProducts from 'Bt_modules/Tables/TableStockProducts'
import AddIcon from '@material-ui/icons/Add';
import { Redirect } from "react-router-dom";

export default function ProductStock(props) {
    if (props.authenticated) {
        return (
            <div style={{ padding: '1% 0.5%' }}>
                <Grid container spacing={2} >
                    <Grid item xl={2} lg={2} md={3} sm={3} xs={12} style={{ backgroundColor: '', }}>
                        <Box sx={{ display: { xl: 'flex', lg: 'flex', md: 'flex', sm: 'flex', xs: 'flex' }, padding: { xl: '6% 0', lg: '5% 0', md: '4% 0', sm: '3% 0', xs: '' } }}>
                            <Typography>ຜະລິດຕະພັນທັງໝົດ</Typography>
                        </Box>
                    </Grid>
                    <Grid item xl={5} lg={5} md={7} sm={7} xs={12} style={{ backgroundColor: '', }}>
                        <Box sx={{ display: { xl: 'flex', lg: 'flex', md: 'flex', sm: 'flex', xs: 'flex' } }}>
                            <TextField
                                id="add-new-code"
                                //select
                                label="ເພີ່ມໃໝ່ໂດຍໃຊ້ລະຫັດບາໂຄດ"
                                variant="outlined"
                                fullWidth
                            />
                            &nbsp;&nbsp;&nbsp;
                            <TextField
                                id="search"
                                //select
                                label="ຄົ້ນຫາ"
                                variant="outlined"
                                fullWidth
                            />
                        </Box>
                    </Grid>
                    <Grid item xl={5} lg={5} md={2} sm={2} xs={12} style={{ backgroundColor: '', }}>
                        <Box
                            sx={{
                                display: { xl: 'flex', lg: 'flex', md: 'flex', sm: 'flex', xs: 'flex' },
                                justifyContent: { xl: 'flex-end', lg: 'flex-end', md: 'flex-end', sm: '', xs: '' },
                            }}
                        >
                            <Box
                                sx={{
                                    display: { xl: 'flex', lg: 'flex', md: 'flex', sm: 'flex', xs: 'flex' },
                                    justifyContent: { xl: 'space-around', lg: 'flex-end', md: 'flex-end', sm: '', xs: '' },
                                    padding: { xl: '1.8% 8%', lg: '2.2% 8%', md: '2.5% 6%', sm: '4% 6%', xs: '1.3% 6%' },
                                    backgroundColor: '#57D65B',
                                    borderRadius: 3,
                                    color: '#FFFFFF',
                                    cursor: 'pointer',
                                    ":active": {
                                        backgroundColor: '#BDBBB9',
                                    },
                                }}>
                                <AddIcon />
                                <Typography style={{ fontSize: '17px', fontWeight: 'bold' }}>

                                    ເພີ່ມໃໝ່
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <br />
                <TableStockProducts />
            </div>
        )
    } else {
        return (
            <div>
                <Redirect
                    to={{
                        pathname: "/admin/sign-in",
                    }}
                />
            </div>
        )
    }
}
