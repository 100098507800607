import React from 'react'
import { TableContainer, Table, TableHead, TableRow, Paper, TableCell, TableBody, IconButton } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';
import VisibilityIcon from '@material-ui/icons/Visibility';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.background.dark,
        color: theme.palette.background.default,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

export default function ProductInReportTable(props) {
    return (
        <div>
            <TableContainer component={Paper}>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow >
                        <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ເລກບິນ</StyledTableCell>
                            
                            <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ຈຳນວນ</StyledTableCell>
                            <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ຜູ້ນຳເຂົ້າ</StyledTableCell>
                            <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ວັນທີ</StyledTableCell>
                            <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ເວລາ</StyledTableCell>
                            <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ລາຍລະອຽດ</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.productInList.map((row, index) =>
                            <StyledTableRow key={row.id}>
                                <StyledTableCell align="center" component="th" scope="row">
                                    {row.billcode}
                                </StyledTableCell>
                                <StyledTableCell align="center" component="th" scope="row">
                                    {row.totalQty}
                                </StyledTableCell>
                                <StyledTableCell align="center" component="th" scope="row">
                                    {row.username || "ບໍ່ມີຂໍ້ມູນ"}
                                </StyledTableCell>
                                <StyledTableCell align="center" component="th" scope="row">
                                    {row.date}
                                </StyledTableCell>
                                <StyledTableCell align="center" component="th" scope="row">
                                {row.time}
                                </StyledTableCell>
                                <StyledTableCell align="center" component="th" scope="row">
                                    <IconButton onClick={()=> props.handleViewProductInDetail(row.billcode)}>
                                        <VisibilityIcon color="secondary" style={{ cursor: 'pointer' }} />
                                    </IconButton>
                                </StyledTableCell>
                            </StyledTableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
