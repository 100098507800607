import React, { useState, useEffect } from 'react';
import { Route, useLocation } from "react-router-dom";
import Axios from 'axios';

import AdminAppbar from 'Bt_modules/Appbar/AdminAppbar'

import Login from 'Apps/Admin/SignIn/SignIn'
//import SignUp from './SignUp/SignUp'

import Home from 'Apps/Admin/Home/Home'
import Sale from 'Apps/Admin/Sale/Sale';
import ProductOut from 'Apps/Admin/Sale/ProductOut';
import CreateOrder from 'Apps/Admin/Sale/CreateOrder';
import Test from 'Apps/Admin/Test/Test';
import MoveProdPackToSell from 'Apps/Admin/ProductCase/MoveProdPackToSell';
import AddBusinessPartner from 'Apps/Admin/BuyManagement/AddBusinessPartner';
// import AddNewProduct from 'Apps/Admin/Storage/AddNewProduct';
import CreateBarcode from 'Apps/Admin/Storage/CreateBarcode';
import ProductPage from 'Apps/Admin/Storage/ProductPage';
import MaterialPage from 'Apps/Admin/Storage/MaterialPage';
// import CashDeposit from 'Apps/Admin/CashManagement/CashDeposit';
// import CashReceipt from 'Apps/Admin/CashManagement/CashReceipt';
import Orders from 'Apps/Admin/Orders/Orders';
import Returns from 'Apps/Admin/Orders/Returns';
import AllStock from 'Apps/Admin/Stock/AllStock';
import ProductStock from 'Apps/Admin/Stock/ProductStock';
import ImportedRawMaterial from 'Apps/Admin/RawMaterial/ImportedRawMaterial';
import ImportedProduct from 'Apps/Admin/RawMaterial/ImportedProduct';
import ExportedRawMaterial from 'Apps/Admin/RawMaterial/ExportedRawMaterial';
import ReturnProductPage from 'Apps/Admin/ReturnProduct/ReturnProductPage';


import Loading from 'Bt_modules/Loading/Loading';
import ReportRawMaterialpage from 'Apps/Admin/Report/ReportRawMaterialpage';
import ReportProductlpage from 'Apps/Admin/Report/ReportProductpage';
import ReportOrderPage from 'Apps/Admin/Report/ReportOrder';
import CutNumbMaterial from 'Apps/Admin/CutNumbMaterial/CutNumbMaterial';
import ProductInReport from 'Apps/Admin/Report/ProductInReport'
import Retailer from './Settings/Retailer'
import User from './Settings/User'
import Promotion from 'Apps/Admin/Settings/Promo'
import StockReport from './Stock/StockDetailerReport';
import BrokenProductReport from './Report/BrokenProductReport';

//import StockInfo from './Stock/StockInfo';

export default function Index() {
    const location = useLocation();
    const [authenticated, setAuthenticated] = useState(false);
    const [accessToken, setAccessToken] = useState()
    const [loading, setloading] = useState(false)
    const [userInfo, setUserInfo] = useState({})

    useEffect(() => {
        const options = {
            url: window.$api + "/reftoken",
            method: "POST",
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                ////"content-Type": "application/json;charset-UTF-8"
            },
        };
        Axios(options).then(Response => {
            if (Response.data.status) {
                setAccessToken(Response.data.jid)
                setUserInfo(Response.data.userInfo)
                setAuthenticated(true);
                setloading(true)
            } else {
                setloading(true)
            }
        })
    }, []);

    const handleLogout = () => {
        const options = {
            url: window.$api + "/logout",
            method: "POST",
            withCredentials: true,
            headers: {
                "Accept": "application/json"
            },
        }
        Axios(options).then(Response => {
            if (Response.data) {
                setAuthenticated(false);
                setAccessToken("")
            }
        });
    }

    if (loading) {
        return (
            <div>
                {location.pathname !== '/admin/sign-in' && location.pathname !== '/admin/sign-up' && authenticated ? <AdminAppbar userInfo={userInfo} authenticated={authenticated} handleLogout={handleLogout} /> : null}
                <div>
                    {/* <Route path="/admin/promotion" exact component={() => <Promotion accessToken={accessToken} userInfo={userInfo} authenticated={authenticated} />} /> */}
                    <Route path="/admin/promotion" exact component={() => <Promotion accessToken={accessToken} userInfo={userInfo} authenticated={authenticated} />} />

                    {/* <Route path="/admin/return-product" exact component={() => <ReturnProductPage accessToken={accessToken} userInfo={userInfo} authenticated={authenticated} />} /> */}
                    <Route path="/admin/return-product" exact component={() => <Returns authenticated={authenticated} accessToken={accessToken}/>} />
                    <Route path="/admin/broken-product-report" exact component={() => <BrokenProductReport accessToken={accessToken} userInfo={userInfo} authenticated={authenticated} />} />

                    <Route path="/admin/retailer" exact component={() => <Retailer accessToken={accessToken} userInfo={userInfo} authenticated={authenticated} />} />
                    <Route path="/admin/user" exact component={() => <User accessToken={accessToken} userInfo={userInfo} authenticated={authenticated} />} />

                    <Route path="/admin/report-detailer" exact component={() => <StockReport accessToken={accessToken} userInfo={userInfo} authenticated={authenticated} />} />

                    <Route path="/admin/material2" exact component={() => <CutNumbMaterial accessToken={accessToken} userInfo={userInfo} authenticated={authenticated} />} />
                    <Route path="/admin/product-in-report" exact component={() => <ProductInReport authenticated={authenticated} />} />
                    <Route path="/admin/report-order" exact component={() => <ReportOrderPage authenticated={authenticated} />} />
                    <Route path="/admin/report-product" exact component={() => <ReportProductlpage authenticated={authenticated} />} />
                    <Route path="/admin/report-rawmaterial" exact component={() => <ReportRawMaterialpage authenticated={authenticated} />} />
                    <Route path="/admin/export-raw-material" exact component={() => <ExportedRawMaterial authenticated={authenticated} accessToken={accessToken} />} />
                    <Route path="/admin/import-product-material" exact component={() => <ImportedRawMaterial authenticated={authenticated} accessToken={accessToken}/>} />
                    <Route path="/admin/import-product-product" exact component={() => <ImportedProduct authenticated={authenticated} accessToken={accessToken}/>} />
                    <Route path="/admin/product-stock" exact component={() => <ProductStock authenticated={authenticated} />} />
                    <Route path="/admin/all-stock" exact component={() => <AllStock authenticated={authenticated} />} />
                    <Route path="/admin/order" exact component={() => <Orders authenticated={authenticated} accessToken={accessToken}/>} />
                    {/* <Route path="/admin/cash-deposit" exact component={() => <CashDeposit accessToken={accessToken} authenticated={authenticated} />} />
                    <Route path="/admin/cash-receipt" exact component={() => <CashReceipt accessToken={accessToken} authenticated={authenticated} />} /> */}
                    {/* <Route path="/admin/add-new-product" exact component={() => <AddNewProduct authenticated={authenticated} />} /> */}
                    <Route path="/admin/create-barcode" exact component={() => <CreateBarcode authenticated={authenticated} />} />
                    <Route path="/admin/product" exact component={() => <ProductPage authenticated={authenticated} />} />
                    <Route path="/admin/material" exact component={() => <MaterialPage authenticated={authenticated} />} />
                    <Route path="/admin/add-business-partner" exact component={() => <AddBusinessPartner authenticated={authenticated} />} />
                    {/* <Route path="/admin/buy-to-stock" exact component={() => <BuyToStock />} /> */}
                    <Route path="/admin/move-product-pack-to-sell" exact component={() => <MoveProdPackToSell authenticated={authenticated} accessToken={accessToken} />} />
                    {/* <Route path="/admin/receive-balance-card" exact component={() => <ReceiveBalanceCard accessToken={accessToken} />} />
                    <Route path="/admin/add-balance-card" exact component={() => <AddBalanceCard accessToken={accessToken} />} />
                    <Route path="/admin/send-balance-card" exact component={() => <SendBalanceCard accessToken={accessToken} />} /> */}
                    <Route path="/admin/test" exact component={() => <Test/>} />
                    <Route path="/admin/create-order" exact component={() => <CreateOrder accessToken={accessToken} authenticated={authenticated} userInfo={userInfo} />} />
                    <Route path="/admin/product-out" exact component={() => <ProductOut accessToken={accessToken} authenticated={authenticated} userInfo={userInfo} />} />
                    <Route path="/admin/sale" exact component={() => <Sale accessToken={accessToken} authenticated={authenticated} userInfo={userInfo} />} />
                    <Route path="/admin" exact component={() => <Home authenticated={authenticated} />} />
                    <Route path="/admin/sign-in" exact component={() => <Login authenticated={authenticated} setAuthenticated={setAuthenticated} setAccessToken={setAccessToken} setUserInfo={setUserInfo} />} />
                    {/* <Route path="/admin/sign-up" exact component={() => <SignUp />} /> */}

                </div>
            </div>
        )
    } else {
        return (
            <div>
                <Loading />
            </div>
        )
    }
}

