import React from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    Paper,
    TableCell
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.background.dark,
        color: theme.palette.background.default,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

export default function ProductInReportDiag(props) {
    return (
        <div>
            <Dialog 
                open={props.open} 
                onClose={props.handleClose}
                maxWidth='xl'
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">
                    ປະຫວັດການນຳເຂົ້າເລກບິນທີ {props.billcode}
                </DialogTitle>
                <DialogContent>
                    <TableContainer component={Paper}>
                        <Table size='medium' aria-label="customized table">
                            <TableHead>
                                <TableRow >
                                    <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ລຳດັບ</StyledTableCell>
                                    <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ບາໂຄດ</StyledTableCell>
                                    <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ຊື່ສິນຄ້າ</StyledTableCell>
                                    <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ຈຳນວນ</StyledTableCell>
                                    <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ປະເພດ</StyledTableCell>
                                    <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ການຈ່າຍເງິນ</StyledTableCell>
                                    <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ຜູ້ສະໜອງ</StyledTableCell>
                                    <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ຜູ້ນຳເຂົ້າ</StyledTableCell>
                                    <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ວັນທີ</StyledTableCell>
                                    <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ເວລາ</StyledTableCell>
                                    
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.productInByDateList.map((row, index) =>
                                    <StyledTableRow key={row.id}>
                                        <StyledTableCell align="center" component="th" scope="row">
                                            {index + 1}
                                        </StyledTableCell>
                                        <StyledTableCell align="center" component="th" scope="row">
                                            {row.product_barcode}
                                        </StyledTableCell>
                                        <StyledTableCell align="center" component="th" scope="row">
                                            {row.product_name}
                                        </StyledTableCell>
                                        <StyledTableCell align="center" component="th" scope="row">
                                            {row.qty}
                                        </StyledTableCell>
                                        <StyledTableCell align="center" component="th" scope="row">
                                            {row.name_cat}
                                        </StyledTableCell>
                                        <StyledTableCell align="center" component="th" scope="row">
                                            {row.payment_type || 'ບໍ່ມີຂໍ້ມູນ'}
                                        </StyledTableCell>
                                        <StyledTableCell align="center" component="th" scope="row">
                                            {row.companyname || 'ບໍ່ມີຂໍ້ມູນ'}
                                        </StyledTableCell>
                                        <StyledTableCell align="center" component="th" scope="row">
                                            {row.username || 'ບໍ່ມີຂໍ້ມູນ'}
                                        </StyledTableCell>
                                        <StyledTableCell align="center" component="th" scope="row">
                                            {row.date}
                                        </StyledTableCell>
                                        <StyledTableCell align="center" component="th" scope="row">
                                            {row.time}
                                        </StyledTableCell>
                                        
                                    </StyledTableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose} autoFocus>
                        ປິດ
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
