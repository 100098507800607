import React, { useEffect, useState } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Axios from 'axios';
import AddNewProduct from '../../Bt_modules/AddNewProduct/AddnewProduct';
import Backdrop from '../Backdrop/Backdrop'

export default function EditProductDialog(props) {
    const [category, setCategory] = useState([]);
    const [unit, setUnit] = useState([]);
    const [productInfo, setproductInfo] = useState({})
    const [addedCusPriceList, setaddedCusPriceList] = useState([])
    const [selectedPriceCatId, setselectedPriceCatId] = useState('')
    const [selectedPriceCatName, setselectedPriceCatName] = useState("")
    const [customerPriceList, setcustomerPriceList] = useState([])
    const [img, setimg] = useState("")
    const [openAddCusPriceCatDiag, setopenAddCusPriceCatDiag] = useState(false)
    const [openBackdrop, setopenBackdrop] = useState(false)
    const [imgSrc, setimgSrc] = useState("")
    const [newImg, setnewImg] = useState("")
    const [priceCat, setpriceCat] = useState()

    useEffect(() => {
        if (props.product_barcode) {
            const options = {
                url: window.$api + "/",
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    //"content-Type": "application/json;charset-UTF-8"
                },
                data: {
                    "product_barcode": props.product_barcode
                }
            }
            Axios(options).then(res => {
                console.log(res.data)
                setUnit(res.data.unit)
                setCategory(res.data.cat)

                var newArr = [...res.data.productCusPrice]
                newArr.forEach(el => {
                    el.pri_id = el.id
                    el.id = el.customer_price_categories_id
                    el.value = el.price
                    delete el.price
                });
                setaddedCusPriceList(newArr)
                var data = {
                    id: res.data.product.id,
                    barcode: res.data.product.product_barcode || "",
                    productName: res.data.product.product_name || "",
                    selectCateName: res.data.product.name_cat || "",
                    selectCateId: res.data.product.category_id || "",
                    selectUnitName: res.data.product.unit_name || "",
                    selectUnitId: res.data.product.unit_id || "",
                    price: res.data.product.price_kip || "",
                    subBarcode: res.data.sub_product.product_barcode || "",
                    subNameProduct: res.data.sub_product.product_name || "",
                    subQty: res.data.product.sub_qty_per_one || "",
                    subPrice: res.data.sub_product.price_kip || "",
                    cost: res.data.product.cost || ""
                }
                setimg(res.data.product.img_id)
                setimgSrc(window.$api + '/getProductImg/' + res.data.product.img_id)
                setproductInfo(data)
                setcustomerPriceList(res.data.priceCat)
            });
        }
    }, [props.product_barcode])

    const handleChangePriceCat = (e) => {
        setpriceCat(e.target.value)
      }

    const handleCloseCusPrceCatDiag = () => {
        setpriceCat('')
        setopenAddCusPriceCatDiag(false)
    }

    const handleChange = (e) => {
        setproductInfo({
            ...productInfo,
            [e.target.name]: e.target.value
        })
    }

    const handleClose = () => {
        setproductInfo({})
        props.setProductBarcode("")
        setimg("")
        setimgSrc('')
        setnewImg('')
        setCategory([])
        setUnit([])
        setaddedCusPriceList([])
        setcustomerPriceList([])
        props.setOpen(false);

    };

    const handleEdit = () => {
        setopenBackdrop(true)
        const options = {
            url: window.$api + "/editproduct",
            method: "POST",
            headers: {
                "Accept": "application/json",
                //"content-Type": "application/json;charset-UTF-8"
            },
            data: {
                "product_barcode": productInfo.barcode || null,
                "product_name": productInfo.productName || null,
                "price": productInfo.price || null,
                "category_id": productInfo.selectCateId || null,
                "unit_id": productInfo.selectUnitId || null,
                "sub_qty_per_one": productInfo.subQty || null,
                "subBarcode": productInfo.subBarcode || null,
                "subNameProduct": productInfo.subNameProduct || null,
                "subPrice": productInfo.subPrice || 0,
                "addedCusPriceList": addedCusPriceList,
                "productId": props.productId,
                "newImg": newImg || null,
                "cost": productInfo.cost || 0
            }
        }
        Axios(options).then(res => {
            var newArr = [...props.data]
            newArr.forEach(el => {
                if (productInfo.id === el.id) {
                    el.product_barcode = productInfo.barcode
                    el.product_name = productInfo.productName
                    el.unit_name = productInfo.selectUnitName
                    el.price_kip = productInfo.price
                }
            });
            props.setData(newArr)
            props.setProductBarcode("")
            setimg("")
            setimgSrc('')
            setnewImg('')
            setproductInfo({})
            setCategory([])
            setUnit([])
            setaddedCusPriceList([])
            setcustomerPriceList([])
            props.setOpen(false)
            setopenBackdrop(false)
        })
    }

    const handleSelectCate = (id, catename) => {
        setproductInfo({
            ...productInfo,
            selectCateId: id,
            selectCateName: catename,
            selectSubCateId: "",
            selectSubCateName: ""
        })
    }

    const handleAddCusPriceList = () => {
        var arr = [...addedCusPriceList]

        const checkExist = (arr, id) => arr.some(el => el.id === id)

        if (checkExist(arr, selectedPriceCatId)) {
            arr.forEach((el, index) => {
                if (el.id === selectedPriceCatId) {
                    el.value = priceCat
                }
            });
        } else {
            var data = {
                id: selectedPriceCatId,
                value: priceCat,
                name: selectedPriceCatName
            }

            arr.push(data)
        }

        setaddedCusPriceList(arr)
        setselectedPriceCatId('')
        setselectedPriceCatName("")
        setopenAddCusPriceCatDiag(false)
        // var arr = [...addedCusPriceList]
        // var newArr = [...customerPriceList]

        // const checkExist = (arr, id) => arr.some(el => el.id === id)

        // newArr.forEach((el, index) => {
        //     if (checkExist(arr, el.id)) {
        //         arr[index].value = el.value
        //     } else {
        //         arr.push(el)
        //     }
        // });

        // setaddedCusPriceList(arr)
        // setcustomerPriceList([])
        // setselectedPriceCatName("")
        // setopenAddCusPriceCatDiag(false)

    }

    const handleSelectUnit = (unitId, unitName) => {
        setproductInfo({
            ...productInfo,
            selectUnitId: unitId,
            selectUnitName: unitName
        })
    }

    const handleOpenCusPriceCatDiag = () => setopenAddCusPriceCatDiag(true)

    const handleChangeAddedCusPriceList = (e) => {
        var arr = [...addedCusPriceList]

        arr.forEach(el => {
            if (el.id === Number(e.target.id)) {
                el.value = e.target.value
            }
        });

        setaddedCusPriceList(arr)
    }

    const handleSelectCustomerCat = (id, name) => {
        setselectedPriceCatId(id)
        setselectedPriceCatName(name)
    }

    const handleChangeNewCusPriceList = (e) => {
        var arr = [...customerPriceList]

        arr.forEach(el => {
            if (el.id === Number(e.target.id)) {
                el.value = e.target.value
            }
        });

        setcustomerPriceList(arr)
    }

    const onChangeImg = (e) => {
        if (e.target.files[0]) {
            var fileReader = new FileReader();
            fileReader.onload = function (fileLoadedEvent) {
                var srcData = fileLoadedEvent.target.result; // <--- data: base64
                setimg(srcData)
                setimgSrc(srcData)
                setnewImg(srcData)
            }
            fileReader.readAsDataURL(e.target.files[0]);
        }
    }

    return (
        <div>
            <Dialog
                maxWidth='xl'
                fullWidth={true}
                open={props.open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <AddNewProduct
                        category={category}
                        setCategory={setCategory}
                        unit={unit}
                        setUnit={setUnit}
                        productInfo={productInfo}
                        handleChange={handleChange}
                        autoBarcode={false}
                        handleSelectCate={handleSelectCate}
                        handleSelectUnit={handleSelectUnit}
                        addedCusPriceList={addedCusPriceList}
                        selectedPriceCatName={selectedPriceCatName}
                        handleAddCusPriceList={handleAddCusPriceList}
                        openAddCusPriceCatDiag={openAddCusPriceCatDiag}
                        handleCloseCusPrceCatDiag={handleCloseCusPrceCatDiag}
                        handleOpenCusPriceCatDiag={handleOpenCusPriceCatDiag}
                        handleChangeAddedCusPriceList={handleChangeAddedCusPriceList}
                        handleSelectCustomerCat={handleSelectCustomerCat}
                        customerPriceList={customerPriceList}
                        handleChangeNewCusPriceList={handleChangeNewCusPriceList}
                        onChangeImg={onChangeImg}
                        img={img}
                        imgSrc={imgSrc}
                        editPage={true}
                        priceCat={priceCat}
                        handleChangePriceCat={handleChangePriceCat}
                    />
                </DialogContent>

                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleEdit}
                        autoFocus
                    >
                        ບັນທຶກ
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleClose}
                    >
                        ຍົກເລີກ
                    </Button>

                </DialogActions>
                <Backdrop open={openBackdrop} />
            </Dialog>
        </div>
    )
}
