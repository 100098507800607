import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Axios from 'axios';
import AddNewChannel from '../AddNewProduct/AddnewChannel';
import Backdrop from '../Backdrop/Backdrop'

export default function AddNewProductDiag(props) {
  // const [channelType, setChannelType] = useState([]);
  // const [channelInfo, setChannelInfo] = useState({})
  const [openBackdrop, setopenBackdrop] = useState(false)
  // const [village, setVillage] = useState([]);
  // const [district, setDistrict] = useState([]);
  // const [province, setProvince] = useState([]);


  useEffect(() => {
    let isMounted = true;
    const options = {
      url: window.$api + "/getChannelsVDP",
      method: "GET",
      withCredentials: true,
      headers: {
        "Accept": "application/json",
        ////"content-Type": "application/json;charset-UTF-8"
      },
    };
    Axios(options).then(Response => {
      if (isMounted) {
      props.setVillage(Response.data.village)
      props.setDistrict(Response.data.district)
      props.setProvince(Response.data.province)
      }
    })
    return () => { isMounted = false };
  }, [props.setVillage, props.setDistrict, props.setProvince ]);

  useEffect(() => {
    let isMounted = true;
    const options = {
      url: window.$api + "/getChannelInfo",
      method: "POST",
      headers: {
        "Accept": "application/json",
        ////"content-Type": "application/json;charset-UTF-8"
      }
    }
    Axios(options).then(Response => {
      if (isMounted) {
      //setChannelInfo(Response.data.channelInfo);
      props.setChannelType(Response.data.channelTypes);
      props.setCustomerPrice(Response.data.customer)
      }
    });
    return () => { isMounted = false };
  }, [props.setChannelType, props.setCustomerPrice]);

  const handleChange = (e) => {
    props.setChannelInfo({
      ...props.channelInfo,
      [e.target.name]: e.target.value
    })
  }

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleSelectChannelCustomerPrice = (id, name) => {
    props.setChannelInfo({
      ...props.channelInfo,
      selectChannelCustomerPriceId: id,
      selectChannelCustomerPriceName: name
    })
  }

  const handleSelectChannelType = (id, name) => {
    props.setChannelInfo({
      ...props.channelInfo,
      selectChannelTypeId: id,
      selectChannelTypeName: name
    })
  }

  const handleSelectChannelVillage = (id, name) => {
    props.setChannelInfo({
      ...props.channelInfo,
      selectChannelVillageId: id,
      selectChannelVillageName: name
    })
  }

  const handleSelectChannelDistrict = (id, name) => {
    props.setChannelInfo({
      ...props.channelInfo,
      selectChannelDistrictId: id,
      selectChannelDistrictName: name
    })
  }

  const handleSelectChannelProvince = (id, name) => {
    props.setChannelInfo({
      ...props.channelInfo,
      selectChannelProvinceId: id,
      selectChannelProvinceName: name
    })
  }

  const Submit = () => {
    setopenBackdrop(true)
    const options = {
      url: window.$api + "/insertchannel",
      method: "POST",
      headers: {
        "Accept": "application/json",
      },
      data: {
        "type": props.channelInfo.selectChannelTypeId || null,
        "username": props.channelInfo.username || null,
        "password": props.channelInfo.password || null,
        "name": props.channelInfo.name || null,
        "firstname": props.channelInfo.firstname || null,
        "lastname": props.channelInfo.lastname || null,
        "custPrice": props.channelInfo.selectChannelCustomerPriceId || null,
        "phone": props.channelInfo.phone || null,
        "village": props.channelInfo.selectChannelVillageId || null,
        "district": props.channelInfo.selectChannelDistrictId || null,
        "province": props.channelInfo.selectChannelProvinceId || null,
      },
    };
    Axios(options).then(Response => {
      if (Response.data) {
        alert(Response.data)
        //console.log(Response.data)
        setopenBackdrop(false)
      }


      // if (Response.data.status === 1) {
      //   var arr = [...props.data]
      //   var data = {
      //     id: Response.data.product_id,
      //     product_barcode: channelInfo.barcode,
      //     product_name: channelInfo.productName,
      //     price_kip: channelInfo.price,
      //     quantity: 0,
      //     unit_name: channelInfo.selectUnitName
      //   }
      //   arr.push(data)
      //   props.setData(arr)
      //   setChannelInfo({})
      //   props.setOpen(false);
      //   setopenBackdrop(false)
      // } else {
      //   alert(Response.data.mesg)
      //   setopenBackdrop(false)
      // }
    });
    props.setChannelInfo({})
    //
  };

  // const validateData = () => {
  //   if (!channelInfo.selectChannelTypeName) {
  //     alert("ກະລຸນາຕື່ມຂໍ້ມູນປະເພດ");
  //   } else {
  //     if (!channelInfo.name) {
  //       alert("ກະລຸນາຕື່ມຂໍ້ມູນຊື່");
  //     } else {
  //       if (!channelInfo.village) {
  //         alert("ກະລຸນາຕື່ມຂໍ້ມູນບ້ານ");
  //       } else {
  //         if (!channelInfo.district) {
  //           alert("ກະລຸນາຕື່ມຂໍ້ມູນເມືອງ");
  //         } else {
  //           if (!channelInfo.province) {
  //             alert("ກະລຸນາຕື່ມຂໍ້ມູນແຂວງ");
  //           } else {
  //               // Submit()
  //               alert('ok')
  //           }
  //         }
  //       }
  //     }
  //   }
  // }

  const validateData = () => {
    if (!props.channelInfo.selectChannelTypeName) {
      alert("ກະລຸນາຕື່ມຂໍ້ມູນປະເພດ");
    } else {
      if (!props.channelInfo.username) {
        alert("ກະລຸນາຕື່ມຂໍ້ມູນຊື່ເຂົ້າໃຊ້ລະບົບ");
      } else {
        if (!props.channelInfo.password) {
          alert("ກະລຸນາຕື່ມຂໍ້ມູນລະຫັດ");
        } else {
          if (!props.channelInfo.name) {
            alert("ກະລຸນາຕື່ມຂໍ້ມູນຊື່");
          } else {
            if (!props.channelInfo.selectChannelCustomerPriceName) {
              alert("ກະລຸນາຕື່ມຂໍ້ມູນປະເພດລາຄາ");
            } else {
              if (!props.channelInfo.phone) {
                alert("ກະລຸນາຕື່ມຂໍ້ມູນເບີໂທລະສັບ");
              } else {
                if (!props.channelInfo.selectChannelProvinceName) {
                  alert("ກະລຸນາຕື່ມຂໍ້ມູນແຂວງ");
                } else {
                  if (!props.channelInfo.selectChannelDistrictName) {
                    alert("ກະລຸນາຕື່ມຂໍ້ມູນເມືອງ");
                  } else {
                    if (!props.channelInfo.selectChannelVillageName) {
                      alert("ກະລຸນາຕື່ມຂໍ້ມູນບ້ານ");
                    } else {
                      Submit()
                    }
                  }
                }
              }
            }
          }
        }
      }

    }
  }

  return (
    <div>
      <Dialog
        maxWidth='xl'
        fullWidth={true}
        open={props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <AddNewChannel
            text="test"
            channelType={props.channelType}
            setChannelType={props.setChannelType}
            autoBarcode={true}
            channelInfo={props.channelInfo}
            customerPrice={props.customerPrice}
            handleChange={handleChange}
            handleSelectChannelType={handleSelectChannelType}
            handleSelectChannelVillage={handleSelectChannelVillage}
            handleSelectChannelDistrict={handleSelectChannelDistrict}
            handleSelectChannelProvince={handleSelectChannelProvince}
            handleSelectChannelCustomerPrice={handleSelectChannelCustomerPrice}
            village={props.village}
            district={props.district}
            province={props.province}
            setVillage={props.setVillage}
            setDistrict={props.setDistrict}
            setProvince={props.setProvince}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={validateData}
            autoFocus
          >
            ບັນທຶກ
          </Button>
          <Button
            variant="contained"
            style={{ backgroundColor: "#FF0000", color: "#FFFFFF" }}
            onClick={handleClose}
          >
            ຍົກເລີກ
          </Button>
        </DialogActions>
        <Backdrop open={openBackdrop} />
      </Dialog>
    </div>
  );
}