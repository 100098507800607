import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Grid, TextField, Button, Typography, DialogContent, DialogActions } from '@material-ui/core';
import Axios from 'axios';

export default function DialogAddChannelProvince(props) {

    const [channelProvince, setChannelProvince] = useState("");
    const handleClose = () => {
        props.setOpenProvince(false);
    };
    const handleChannelProvince = (e) => {
        setChannelProvince(e.target.value);
    };
    const Submit = () =>{
        const options = {
            url: window.$api + "/addChannelProvince",
            method: "POST",
            headers:{
                "Accept": "application/json",
                ////"content-Type": "application/json;charset-UTF-8"
            },
            data: {
                "channelProvince" : channelProvince
            },
        };
        Axios(options).then(Response =>{
            alert(Response.data.mesg)
            props.setProvince(Response.data.province)
            //alert(Response.data.mesg);
            //props.setProvince(Response.data.province)
        //     const options = {
        //         url: window.$api + "/getchannelType",
        //         method: "POST",
        //         headers:{
        //             "Accept": "application/json",
        //             //"content-Type": "application/json;charset-UTF-8"
        //         },
        //     };
        //     Axios(options).then(Response =>{
        //         props.setChannelType(Response.data);
        //         setChannelType("");
        //     });
        });
    };

    return (
        <Dialog       
            maxWidth = 'xl'
            open={props.openProvince}
        >
             <DialogContent>
                <Grid container spacing={2} >
                    <Grid item xs={12} >
                        <Typography>
                            ເພີ່ມແຂວງ
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="ຊື່ແຂວງ"
                            variant="outlined"
                            onChange={handleChannelProvince}
                            fullWidth
                        />
                    </Grid>
                </Grid>
             </DialogContent>
             <DialogActions>
                <Button onClick={ () => {Submit(); handleClose()}}>
                    ບັນທຶກ
                </Button>
                <Button onClick={handleClose}>ຍົກເລີກ</Button>
            </DialogActions>
        </Dialog>
    )
}