import React from 'react';
//import Paper from '@material-ui/core/Paper';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import NumberFormat from 'react-number-format';
import EditIcon from '@material-ui/icons/Edit';
// import Axios from 'axios'

const columns = [
  { id: 'no', label: 'ລຳດັບ'},
  { id: 'name', label: 'ຊື່ສິນຄ້າ'},
  { id: 'productqty', label: 'ຈນ'},
  { id: 'price', label: 'ລາຄາ'},
  { id: 'add_product', label: 'ສິນຄ້າແຖມ'},
  { id: 'add', label: 'ຈນແຖມ'},
  { id: 'discount_rate', label: 'ຫຼຸດ%'},
  { id: 'discount', label: 'ຫຼຸດ'},
  { id: 'total', label: 'ລວມ'},
  // { id: 'edit', label: ''},
];

export default function StickyHeadTable(props) {
  const handleNewQty = (e) => {
    props.setNewQty(e.target.value);
  }

  const handleEdit = (id) => {
    props.setEditable(id);
  }

  return (
    <div>
      <TableContainer style={{ maxHeight: 600, borderRadius: 5, }}>
        <Table stickyHeader >
          <TableHead>
            <TableRow >
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  //style={{ minWidth: column.minWidth, color: "white", backgroundColor: "#15023a" }}
                  //style={{ color: "white", backgroundColor: "#15023a" }}
                  style={{ backgroundColor: "#ADD8E6" }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody style={{ backgroundColor: "white" }}>
            {props.tableData.map((row, index) =>
              <TableRow key={index} >
                <TableCell>{index + 1}</TableCell>
                {/* <TableCell>{row.barcode || "ບໍ່ມີ"}</TableCell> */}
                {/* <TableCell>{row.barcode}</TableCell> */}
                <TableCell>{row.name || "-"}</TableCell>
                {props.isEditable === row.id ?
                  <TableCell>
                    {/* <TextField 
                      id="outlined-basic" 
                      size="small" 
                      variant="outlined" 
                      onChange={handleNewQty}
                      onKeyPress={(e)=> {handleInput(e, row.id, row.barcode)}}
                    /> */}
                    <FormControl variant="outlined">
                      <InputLabel htmlFor="outlined-adornment-password">ຈຳນວນ</InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        onChange={handleNewQty}
                        onKeyPress={(e) => { props.handleInput(e, index) }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              edge="end"
                              onClick={() => props.setEditable("")}
                            >
                              <CancelIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                        labelWidth={70}
                      />
                    </FormControl>
                  </TableCell>
                  :
                  <TableCell>{row.qty}</TableCell>
                }
                <TableCell><NumberFormat value={row.price} displayType={'text'} thousandSeparator={true} /></TableCell>
                <TableCell>{row.added_qty > 0 ? row.add_product_name : "-"}</TableCell>
                <TableCell>{row.added_qty > 0 ? row.added_qty : 0}</TableCell>
                <TableCell><NumberFormat value={row.discount_rate || 0} displayType={'text'} thousandSeparator={true} /></TableCell>
                <TableCell><NumberFormat value={row.discount || 0} displayType={'text'} thousandSeparator={true} /></TableCell>
                <TableCell><NumberFormat value={row.total} displayType={'text'} thousandSeparator={true} /></TableCell>
                {/* <TableCell>
                  {row.barcode &&
                    <>
                      <IconButton onClick={() => handleEdit(row.id)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => props.handleDelete(row.id, row.qty)}>
                        <DeleteIcon />
                      </IconButton>
                    </>
                  }
                </TableCell> */}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}