import React from 'react'
// import Typography from '@material-ui/core/Typography';
import NumberFormat from 'react-number-format';
import Logo from 'Img/onewater_logo.png'
import { Container, TableContainer, Table, TableHead, TableRow, TableCell, Typography, Grid, TableBody} from '@mui/material'


export default function SalePrint(props) {
    const timestamp = new Date();
    const dd = String(timestamp.getDate()).padStart(2, '0');
    const mm = String(timestamp.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = timestamp.getFullYear();

    const today = dd + '/' + mm + '/' + yyyy;
    const time = timestamp.getHours() + ":" + timestamp.getMinutes() + ":" + timestamp.getSeconds();

    const columns = [
        { id: 'no', label: 'ລຳດັບ', minWidth: 2 },
        { id: 'name', label: 'ຊື່ສິນຄ້າ', minWidth: 150 },
        { id: 'productqty', label: 'ຈນ', minWidth: 50 },
        { id: 'price', label: 'ລາຄາ', minWidth: 60 },
        { id: 'add_product', label: 'ສິນຄ້າແຖມ', minWidth: 150 },
        { id: 'add', label: 'ຈນແຖມ', minWidth: 20 },
        { id: 'discount_rate', label: 'ຫຼຸດ%', minWidth: 50 },
        { id: 'discount', label: 'ຫຼຸດ', minWidth: 50 },
        { id: 'total', label: 'ລວມ', minWidth: 50 },
    ];
    return (
        <div ref={props.componentRef}>
            <Container sx={{ mt: 5 }} >
                <Grid container spacing={1}>
                    <Grid item xs={3} display='flex' justifyContent={'center'} alignItems={'center'} >
                        <img src={Logo} alt='' width={60} />
                    </Grid>
                    <Grid item xs={9} display='flex' flexDirection={'column'} justifyContent={'center'}>
                        <Typography variant='h6' >ໂຮງງານ ນ້ຳດື່ມແສງແກ້ວ</Typography>
                        <Typography variant='h6' >Saengkeo Drinking Water Factory</Typography>
                        <Typography variant='h6'>ຕັ້ງຢູ່ຖະໜົນ 40, ບ້ານ ທົ່ງນ້ອຍ, ເມືອງ ປາກຊັນ, ແຂວງບໍລິຄຳໄຊ</Typography>
                    </Grid>
                    <Grid item xs={4} display='flex' flexDirection={'column'} justifyContent={'flex-end'} >
                        <Typography  >ເລກທີ {props.billcode}</Typography>
                        <Typography variant='body2'>ໂທ: 030 5799 777, 020 5999 9757</Typography>
                    </Grid>
                    <Grid item xs={4} display='flex' flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                        <Typography variant='h5'>ໃບສົ່ງເຄື່ອງ/ຮັບເງິນ</Typography>
                        <Typography variant='h5'>Invoice/Receipt</Typography>
                    </Grid>
                    <Grid item xs={4} display='flex' flexDirection={'column'} justifyContent='flex-end' alignItems={'flex-end'}>
                        <Typography  >ວັນທີ: {today}</Typography>
                        <Typography  >ເວລາ: {time}</Typography>
                    </Grid>
                </Grid>
                <Grid container mt={2}>
                    <Grid item xs={6}>
                        {props.selectedRetailer && <Typography variant='h6' >ຊື່ລູກຄ້າ: {props.selectedRetailer.name || ""}</Typography>}
                    </Grid>
                    <Grid item xs={6}>
                    {props.selectedRetailer && <Typography variant='h6' >ເບີໂທ: {props.selectedRetailer.phone || ""}</Typography>}
                    </Grid>
                </Grid>
                <TableContainer>
                    <Table >
                        <TableHead>
                            <TableRow >
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        // style={{ minWidth: column.minWidth, color: "black" }}
                                        style={{color: "black"}}
                                    >
                                        <div>
                                            {column.label}
                                        </div>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.tableData.map((row, index) =>
                                <TableRow key={index} >
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell align='center'>{row.name || '-'}</TableCell>
                                    <TableCell>{row.qty}</TableCell>
                                    <TableCell><NumberFormat value={row.price} displayType={'text'} thousandSeparator={true} /></TableCell>
                                    <TableCell align='center'>{row.add_product_name || '-'}</TableCell>
                                    <TableCell>{row.added_qty}</TableCell>
                                    <TableCell><NumberFormat value={row.discount_rate} displayType={'text'} thousandSeparator={true} /></TableCell>
                                    <TableCell><NumberFormat value={row.discount} displayType={'text'} thousandSeparator={true} /></TableCell>
                                    <TableCell><NumberFormat value={row.total} displayType={'text'} thousandSeparator={true} /></TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid container mt={2}>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={4} display='flex' flexDirection={'column'} alignItems='flex-end'>
                        <Typography  >ຍອດບິນ</Typography>
                        {props.billDiscRate > 0 &&
                            <Typography  >ສ່ວນຫຼຸດ%</Typography>
                        }
                        {props.discount > 0 &&
                            <Typography  >ສ່ວນຫຼຸດ</Typography>
                        }
                        {props.billAddQty > 0 &&
                            <Typography  >ແຖມ</Typography>
                        }
                        
                        <Typography  >ລາຄາລວມ</Typography>
                        <Typography  >ຮັບເງິນ</Typography>
                        <Typography  >ເງິນທອນ</Typography>
                    </Grid>
                    <Grid item xs={4} display='flex' flexDirection={'column'} alignItems='flex-end'>
                        <Typography><NumberFormat value={props.total} displayType={'text'} thousandSeparator={true} suffix={' ກີບ'}/></Typography>
                        {props.billDiscRate > 0 &&
                            <Typography> ({props.billDiscRate}%) <NumberFormat value={(props.total * props.billDiscRate)/100} displayType={'text'} thousandSeparator={true} suffix={' ກີບ'}/></Typography>
                        }
                        {props.discount > 0 &&
                            <Typography><NumberFormat value={props.discount} displayType={'text'} thousandSeparator={true} suffix={' ກີບ'}/></Typography>
                        }
                        {props.billAddQty > 0 &&
                            <Typography>{props.billAddProduct + " x " + props.billAddQty}</Typography>
                        }
                        
                        <Typography><NumberFormat value={props.total - (props.discount + ((props.total * props.billDiscRate)/100))} displayType={'text'} thousandSeparator={true} suffix={' ກີບ'}/></Typography>
                        <Typography><NumberFormat value={props.cash} displayType={'text'} thousandSeparator={true} suffix={' ກີບ'}/></Typography>
                        <Typography><NumberFormat value={props.change} displayType={'text'} thousandSeparator={true} suffix={' ກີບ'}/></Typography>
                        
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}
