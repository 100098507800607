import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import NumberFormat from 'react-number-format'

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.background.dark,
        color: theme.palette.background.default,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);


// const titles = [
//     { id: '1', name: ['ລຳດັບ', 'ຊື່ຜູ້ສົ່ງ', 'ຮູບແບບຊຳລະ', 'ລາຄາລວມ', 'ສະຖານະ', 'ວັນທີສົ່ງ', 'Action'] },
// ];

// const datas = [
//     { id: '1', name: 'Nam pa', unit: '1', user: 'koun', barcode: '8851907264888', date: '22.11.2022' },
//     { id: '2', name: 'Soft 2021', unit: '1', user: 'jack', barcode: '8851907264888', date: '22.11.2022' },
//     { id: '3', name: 'Soft 2021', unit: '1', user: 'mai', barcode: '8851907264888', color: '#329035', date: '22.11.2022' },
//     { id: '4', name: 'Soft 2021', unit: '10', user: 'billy', barcode: '8851907264888', date: '22.11.2022' },
//     { id: '5', name: 'Box 1500 ml', unit: '10', user: 'ai seua', barcode: '8851907264888', date: '22.11.2022' },
//     { id: '6', name: 'Soft 2021', unit: '1', user: 'ai tueng', barcode: '8851907264888', date: '22.11.2022' },
//     { id: '7', name: 'Soft 2021', unit: '1', user: 'najah', barcode: '8851907264888', date: '22.11.2022' },
// ];


const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});

export default function TableExpMaterial(props) {
    const classes = useStyles();

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
                <TableHead>
                    <TableRow >
                        <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ລຳດັບ</StyledTableCell>
                        <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ຊື່ວັດຖຸດິບ</StyledTableCell>
                        <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ຈຳນວນ</StyledTableCell>
                        <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ຜູ້ໃຊ້</StyledTableCell>
                        <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ບາໂຄດ</StyledTableCell>
                        <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ວັນທີນຳອອກ</StyledTableCell>
                        <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ເລກທີບິນ</StyledTableCell>
                        <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>Actions</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.material.map((row, index) => (
                        <StyledTableRow key={row.id}>
                            <StyledTableCell align="center" component="th" scope="row">
                                {index + 1}
                            </StyledTableCell>
                            <StyledTableCell align="center">{row.name_material}</StyledTableCell>
                            <StyledTableCell align="center">
                                <NumberFormat value={row.qty} displayType={'text'} thousandSeparator={true} suffix={' '} />
                            </StyledTableCell>
                            <StyledTableCell align="center">{row.user}</StyledTableCell>
                            <StyledTableCell align="center">{row.barcode}</StyledTableCell>
                            <StyledTableCell align="center">{row.date}&nbsp;{row.time}</StyledTableCell>
                            <StyledTableCell align="center">{row.billcode}</StyledTableCell>
                            <StyledTableCell align="center">
                                <VisibilityIcon color="secondary" style={{ cursor: 'pointer' }} />
                                &nbsp;&nbsp;
                                <DeleteIcon color="error" style={{ cursor: 'pointer' }} />
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

//TableOrders