import React, { useEffect, useState } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Axios from 'axios';
//import AddnewChannel from '../../Bt_modules/AddNewProduct/AddnewChannel';
import Backdrop from '../Backdrop/Backdrop'
import EditChannel from '../AddNewProduct/EditChannel';

export default function EditChannelDialog(props) {
    //const [category, setCategory] = useState([]);
    //const [unit, setUnit] = useState([]);
    //const [productInfo, setproductInfo] = useState({})
    //const [addedCusPriceList, setaddedCusPriceList] = useState([])
    //const [selectedPriceCatId, setselectedPriceCatId] = useState('')
    //const [selectedPriceCatName, setselectedPriceCatName] = useState("")
    //const [customerPriceList, setcustomerPriceList] = useState([])
    //const [img, setimg] = useState("")
    //const [openAddCusPriceCatDiag, setopenAddCusPriceCatDiag] = useState(false)
    const [openBackdrop, setopenBackdrop] = useState(false)
    // const [channelType, setChannelType] = useState([]);
    // const [channelInfo, setChannelInfo] = useState({})
    // const [village, setVillage] = useState([]);
    // const [district, setDistrict] = useState([]);
    // const [province, setProvince] = useState([]);

    useEffect(() => {
        let isMounted = true;
        const options = {
            url: window.$api + "/getChannelsVDP",
            method: "GET",
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                ////"content-Type": "application/json;charset-UTF-8"
            },
        };
        Axios(options).then(Response => {
            if (isMounted) {
            props.setVillage(Response.data.village)
            props.setDistrict(Response.data.district)
            props.setProvince(Response.data.province)
            }
        })
        return () => { isMounted = false };
    }, [props]);

    useEffect(() => {
        let isMounted = true;
        const options = {
            url: window.$api + "/getChannelInfo",
            method: "POST",
            headers: {
                "Accept": "application/json",
                ////"content-Type": "application/json;charset-UTF-8"
            }
        }
        Axios(options).then(Response => {
            if (isMounted) {
            //setChannelInfo(Response.data.channelInfo);
            props.setChannelType(Response.data.channelTypes);
            props.setCustomerPrice(Response.data.customer)
            }
        });
        return () => { isMounted = false };
    }, [props]);

    const handleChange = (e) => {
        props.setChannelInfo({
            ...props.channelInfo,
            [e.target.name]: e.target.value
        })
    }

    const handleSelectChannelType = (id, name) => {
        props.setChannelInfo({
            ...props.channelInfo,
            selectChannelTypeId: id,
            selectChannelTypeName: name
        })
    }

    const handleSelectChannelCustomerPrice = (id, name) => {
        props.setChannelInfo({
          ...props.channelInfo,
          selectChannelCustomerPriceId: id,
          selectChannelCustomerPriceName: name
        })
      }

    const handleSelectChannelVillage = (id, name) => {
        props.setChannelInfo({
            ...props.channelInfo,
            selectChannelVillageId: id,
            selectChannelVillageName: name
        })
    }

    const handleSelectChannelDistrict = (id, name) => {
        props.setChannelInfo({
            ...props.channelInfo,
            selectChannelDistrictId: id,
            selectChannelDistrictName: name
        })
    }

    const handleSelectChannelProvince = (id, name) => {
        props.setChannelInfo({
            ...props.channelInfo,
            selectChannelProvinceId: id,
            selectChannelProvinceName: name
        })
    }

    useEffect(() => {
        if (props.channelID) {
            setopenBackdrop(true)
            let isMounted = true;
            const options = {
                url: window.$api + "/getEditChannelInfo",
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    //"content-Type": "application/json;charset-UTF-8"
                },
                data: {
                    "channel_id": props.channelID
                }
            }
            Axios(options).then(res => {
                if (isMounted) {
                    props.setChannelInfo({
                        id: res.data.channel.id,
                        selectChannelTypeId: res.data.channel.type,
                        selectChannelCustomerPriceId: res.data.cust_price_cat_id,
                        selectChannelVillageId: res.data.channel.village,
                        selectChannelDistrictId: res.data.channel.district,
                        selectChannelProvinceId: res.data.channel.province,
                        password: res.data.channel.password,
                        name: res.data.channel.name,
                        username: res.data.channel.username,
                        selectChannelTypeName: res.data.channel.typename,
                        firstname: res.data.channel.firstname,
                        lastname: res.data.channel.lastname,
                        phone: res.data.channel.phone,
                        selectChannelCustomerPriceName: res.data.channel.customerpricename,
                        selectChannelProvinceName: res.data.channel.provincename,
                        selectChannelDistrictName: res.data.channel.districtname,
                        selectChannelVillageName: res.data.channel.villname,
                    })
                    setopenBackdrop(false)
                }
            });
            return () => { isMounted = false };
        }
    }, [props.channelID])


    const handleClose = () => {
        //setproductInfo({})
        props.setOpenEdit(false);
        props.setChannelInfo({})
        Refresh()
    };

    const Refresh = () => {
        Axios
            .get(window.$api + "/getChannelsData")
            .then((res) => {
                props.setData(res.data.allChannel)
            });
    }


const handleEdit = () => {
    setopenBackdrop(true)
    const options = {
        url: window.$api + "/updatechannel",
        method: "POST",
        headers: {
            "Accept": "application/json",
        },
        data: {
            "id": props.channelID || null,
            "type": props.channelInfo.selectChannelTypeId || null,
            "username": props.channelInfo.username || null,
            "password": props.channelInfo.password || null,
            "name": props.channelInfo.name || null,
            "custPrice": props.channelInfo.selectChannelCustomerPriceId || null,
            "firstname": props.channelInfo.firstname || null,
            "lastname": props.channelInfo.lastname || null,
            "phone": props.channelInfo.phone || null,
            "village": props.channelInfo.selectChannelVillageId || null,
            "district": props.channelInfo.selectChannelDistrictId || null,
            "province": props.channelInfo.selectChannelProvinceId || null,
        },
    };
    Axios(options).then(Response => {
        //console.log(Response.data)
        if (Response.data) {
            alert(Response.data)
            //console.log(Response.data)
            setopenBackdrop(false)
        }

    });
    props.setChannelInfo({})
    //
};


return (
    <div>
        <Dialog
            maxWidth='xl'
            fullWidth={true}
            open={props.openEdit}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <EditChannel
                    setData={props.setData}
                    channelType={props.channelType}
                    setChannelType={props.setChannelType}
                    autoBarcode={true}
                    channelInfo={props.channelInfo}
                    handleChange={handleChange}
                    handleSelectChannelType={handleSelectChannelType}
                    handleSelectChannelVillage={handleSelectChannelVillage}
                    handleSelectChannelDistrict={handleSelectChannelDistrict}
                    handleSelectChannelProvince={handleSelectChannelProvince}
                    selectChannelVillageName={props.channelInfo.selectChannelVillageName}
                    customerPrice={props.customerPrice}
                    handleSelectChannelCustomerPrice={handleSelectChannelCustomerPrice}
                    village={props.village}
                    district={props.district}
                    province={props.province}
                    setVillage={props.setVillage}
                    setDistrict={props.setDistrict}
                    setProvince={props.setProvince}
                    submit={handleEdit}
                />
            </DialogContent>

            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleEdit}
                    autoFocus
                >
                    ບັນທຶກ
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleClose}
                >
                    ຍົກເລີກ
                </Button>

            </DialogActions>
            <Backdrop open={openBackdrop} />
        </Dialog>
    </div>
)
}
