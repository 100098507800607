import React from 'react';
import PrintIcon from '@material-ui/icons/Print';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import PrintBarcode from './PrintBarcode'
import PrintPrice from './PrintPrice'
import { Container, Paper, Tabs, Tab, Grid } from '@material-ui/core';

export default function Barcode() {
    const [value, setValue] = React.useState(0);
    const [barcodeComp, setBarcodeComp] = React.useState([{"name":<PrintBarcode/>}]);

    const RenderComp = () => {
        return barcodeComp.map((component, index) => <div key={index}>{component.name}</div>)
    } 
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    return (
        <div>
          <Container maxWidth='xl'>
            <Paper style={{padding: "2%", marginTop: "2vh", marginBottom: "2vh"}}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="fullWidth"
                    indicatorColor="secondary"
                    textColor="secondary"
                    aria-label="icon label tabs example"
                  >
                    <Tab icon={<PrintIcon />} label="ພິມບາໂຄດ" onClick={() => setBarcodeComp([{"name":<PrintBarcode/>}])}/>
                    <Tab icon={<LocalOfferIcon />} label="ພິມປ້າຍລາຄາ" onClick={() => setBarcodeComp([{"name":<PrintPrice/>}])}/>
                  </Tabs>
                </Grid>
                <Grid item xs={12}>
                  {RenderComp()}
                </Grid>
              </Grid>
            </Paper>
          </Container>
        </div>
    );
}
