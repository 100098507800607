import React from 'react'

export default function Test() {
    //const arr = [{"firstname":"Souliya", "lastname":"Chindavong"}, {"firstname":"Kounnapha", "lastname":"Inthavong"}];
    const numbersOne = [1, 2, 3];
    const numbersTwo = [4, 5, 6];
    const numbersCombined = [...numbersOne, ...numbersTwo];
  return (
    <div>
        <button>Add</button>
        {JSON.stringify(numbersCombined)}
    </div>
  )
}
