import React from 'react';
import {Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, IconButton, Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
//import EditChannelDialog from './EditChannelDialog';

const columns = [
    { id: '1', label: 'ຊື່', minWidth: 10 },
    { id: '2', label: 'ນາມສະກຸນ', minWidth: 120 },
    { id: '3', label: 'ຕໍາແໜ່ງ', minWidth: 250 },
    { id: '4', label: 'ລະດັບສິດ', minWidth: 100 },
    { id: '5', label: '', minWidth: 100 },
];

export default function ChannelManageTable(props) {

    return (
        <div style={{padding: '2% 1%'}}>
            <Container maxWidth='xl'>
                <Paper style={{padding: "2%"}} elevation={3}>
                    <Grid container spacing={2}>
                        <Grid item sm={4} xs={12}>
                            <Button variant='contained' color='secondary' onClick={()=> props.setopenAddNewProductDiag(true)}>ເພີ່ມຜູ້ໃຊ້</Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper style={{height: "75vh"}}>
                                <TableContainer style={{maxHeight: "75vh", borderRadius: 5}}>
                                    <Table aria-label="customized table" stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                {columns.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        align="center"
                                                        style={{ color: "white", backgroundColor: "#15023a" }}
                                                    >
                                                        {column.label}
                                                    </TableCell>  
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {props.data.map((row) => (
                                            <TableRow key={row.id}>
                                                <TableCell align="center">{row.firstname}</TableCell>
                                                <TableCell align="center">{row.lastname}</TableCell>
                                                <TableCell align="center">{row.position}</TableCell>    
                                                <TableCell align="center">{row.permission}</TableCell>    
                                                <TableCell align="center">
                                                    <IconButton onClick={()=> props.handleEditUser(row.id)}>
                                                        <EditIcon/>
                                                    </IconButton>
                                                    <IconButton onClick={()=> props.handleDelete(row.id)}>
                                                        <DeleteIcon/>
                                                    </IconButton>    
                                                </TableCell>                                               
                                            </TableRow>
                                        ))} 
                                        </TableBody> 
                                    </Table>
                                </TableContainer>                       
                            </Paper>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
            {/* <EditChannelDialog 
                open={props.openEdit} 
                setOpen={props.setOpenEdit}
                data={props.data}
                setData={props.setData}
                channel_id={props.channel_id}
            /> */}
        </div>
    )
}
