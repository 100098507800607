import Typography from '@material-ui/core/Typography';
import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import CardProducts from 'Bt_modules/Card/CardProducts';

const useStyles = makeStyles((theme) => ({

  topic: {
    fontWeight: 'bold',
    //color: theme.palette.secondary.dark,
    fontSize: '18px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '25px',

    },
    textIndent: '40px'
  },
}));

export default function Ourproducts(props) {
  const classes = useStyles();

  const des1 = "ນໍ້າດື່ມວັນມີທັງໝົດ 4 ຂະໜາດຄື : 230ມລ, 350ມລ, 600ມລ ແລະ 1500ມລ. ໃນໄລຍະຜ່ານມາກໍໄດ້ຮັບຄວາມສົນໃຈຈາກຜູ້ບໍລິໂພກ ນັບມື້ນັບເພີ້ມຂຶ້ນ ໂດຍສະເພາະພາຍໃນແຂວງບໍລິຄຳໄຊ, ເຊິ່ງປັດຈຸບັນ ພວກເຮົາໄດ້ວາງຈໍາໜ່າຍຢູ່ 06 ຕົວເມືອງພາຍໃນແຂວງບໍລິຄຳໄຊ ເຊັ່ນ: ເມືອງປາກຊັນ, ເມືອງທ່າພະບາດ, ເມືອງປາກກະດິງ, ເມືອງບໍລິຄັນ, ເມືອງຄຳເກີດ ແລະ ເມືອງວຽງທອງ ນອກຈາກນັ້ນ ຍັງໄດ້ຂະຫຍາຍຕະຫລາດຈໍາໜ່າຍຢູ່ບາງເຂດໃນບັນດາແຂວງໃກ້ຄຽງເຊັ່ນ: ແຂວງ ຊຽງຂວາງ, ແຂວງ ຄຳມ່ວນ, ແຂວງ ສະຫວັນນະເຂດ, ແຂວງ ໄຊສົມບູນ ແລະ ນະຄອນຫຼວງວຽງຈັນ."

  const detailsLa = [
    { id: '1', des: des1 }
  ]
  return (
    <div>
      <br /> ​<br />
      <Typography component={'div'}>
        <div className={classes.topic}>{props.t('title.1')}</div>
      </Typography>
      <br />

        <CardProducts />

      <br />
      {detailsLa.map((row) => (
        <div key={row.id} style={{ padding: '0 2.3%' }}>
          <Typography component={'div'} style={{ whiteSpace: "pre-wrap", textAlign: 'justify', textIndent: '40px', lineHeight: '180%' }}>
            <div style={{ fontSize: '16px' }}>{row.des}</div>
          </Typography>
        </div>
      ))}
      <br /> ​<br />
    </div>
  )
}
