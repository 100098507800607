import React from 'react'
import Sales from 'Bt_modules/Sale/Sale'

import { Redirect } from "react-router-dom";

export default function Sale(props) {
    return (
        <div >
            {props.authenticated ?
                <Sales accessToken={props.accessToken} userInfo={props.userInfo}/>
                :
                <Redirect
                    to={{
                        pathname: "/admin/sign-in",
                    }}
                />
            }
        </div>
    )
}

