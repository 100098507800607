import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.background.dark,
        color: theme.palette.background.default,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);


// const titles = [
//     { id: '1', name: ['ລຳດັບ', 'ຊື່ຜູ້ສົ່ງ', 'ຮູບແບບຊຳລະ', 'ລາຄາລວມ', 'ສະຖານະ', 'ວັນທີສົ່ງ', 'Action'] },
// ];

const datas = [
    { id: '1', name: 'Soft 2021', quantity: '1', unit: 'ກ້ອນ', supplier: 'ບໍລິສັດ ເບດເທັກ ຈຳກັດຜູ້ດຽວ', barcode: '8851907264888', date: '22.11.2022' },
    { id: '2', name: 'ແກ໋ດເຈ້ຍ 1500 ml', quantity: '1', unit: 'ກ້ອນ', supplier: 'ເບດມາກ', barcode: '8851907264888', date: '22.11.2022' },
    { id: '3', name: 'ແກ໋ດເຈ້ຍ 600 ml', quantity: '10', unit: 'ໜ່ວຍ', supplier: 'ເບດມາກ', barcode: '8851907264888', date: '22.11.2022' },
    { id: '4', name: 'ແກ໋ດເຈ້ຍ 230 ml', quantity: '2000', unit: 'ແກັດ', supplier: 'ບໍລິສັດ ເບດເທັກ ຈຳກັດຜູ້ດຽວ', barcode: '8851907264888', date: '22.11.2022' },
    { id: '5', name: 'Scott', quantity: '110', unit: 'ແກັດ', supplier: 'ບໍລິສັດ ເບດເທັກ ຈຳກັດຜູ້ດຽວ', barcode: '8851907264888', date: '22.11.2022' },
    { id: '6', name: 'ແກ໋ດເຈ້ຍ 1500 ml', quantity: '10', unit: 'ກິໂລ', supplier: 'ບໍລິສັດ ເບດເທັກ ຈຳກັດຜູ້ດຽວ', barcode: '8851907264888', date: '22.11.2022' },
    { id: '7', name: 'ພິມແພັກ', quantity: '10', unit: 'ກ້ອນ', supplier: 'ບໍລິສັດ ເບດເທັກ ຈຳກັດຜູ້ດຽວ', barcode: '8851907264888', date: '22.11.2022' },
];


const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});

export default function TableAllStock() {
    const classes = useStyles();

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
                <TableHead>
                    <TableRow >
                        <StyledTableCell align="center" style={{fontWeight: 'bold'}}>ລຳດັບ</StyledTableCell>
                        <StyledTableCell align="center" style={{fontWeight: 'bold'}}>ຊື່ວັດຖຸດິບ</StyledTableCell>
                        <StyledTableCell align="center" style={{fontWeight: 'bold'}}>ຈຳນວນ</StyledTableCell>
                        <StyledTableCell align="center" style={{fontWeight: 'bold'}}>ຫົວໜ່ວຍ</StyledTableCell>
                        <StyledTableCell align="center" style={{fontWeight: 'bold'}}>ຜູ້ສະໜອງ</StyledTableCell>
                        <StyledTableCell align="center" style={{fontWeight: 'bold'}}>ບາໂຄດ</StyledTableCell>
                        <StyledTableCell align="center" style={{fontWeight: 'bold'}}>Actions</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {datas.map((row) => (
                        <StyledTableRow key={row.id}>
                            <StyledTableCell align="center" component="th" scope="row">
                                {row.id}
                            </StyledTableCell>
                            <StyledTableCell align="center">{row.name}</StyledTableCell>
                            <StyledTableCell align="center">{row.quantity}</StyledTableCell>
                            <StyledTableCell align="center">{row.unit}</StyledTableCell>
                            <StyledTableCell align="center">{row.supplier}</StyledTableCell>
                            <StyledTableCell align="center">{row.barcode}</StyledTableCell>
                            <StyledTableCell align="center">
                                <VisibilityIcon color="secondary" style={{cursor: 'pointer'}}/>
                                &nbsp;&nbsp;
                                <DeleteIcon color="error" style={{cursor: 'pointer'}}/>
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

//TableOrders