import Typography from '@material-ui/core/Typography';
import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import CardNewsEvent from 'Bt_modules/Card/CardNewsEvent';

const useStyles = makeStyles((theme) => ({

  topic: {
    fontWeight: 'bold',
    //color: theme.palette.secondary.dark,
    fontSize: '18px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '25px',

    },
    textIndent: '40px'
  },
}));

export default function NewsandEvents(props) {
  const classes = useStyles();

  // const des1 = "ໃນວັນທີ 29 ທັນວາ 2021 ນໍ້າດື່ມວັນ ຮູ້ສຶກພາກພູມໃຈ ແລະ ເປັນກຽດຢ່າງສູງທີ່ໄດ້ຮັບລາງວັນ “ ນັກທຸລະກິດຊັ້ນນຳດີເດັ່ນ ຈາກສະພາການຄ້າ ແລະ ອຸດສາຫະກຳແຫ່ງຊາດ (ສຄອຊ) ໃນໄລຍະໂຄວິດ-19“  ປະຈໍາປີ 2021 ມອບໂດຍ ທ່ານ ປອ ຄຳແພງ ໄຊສົມແພງ ລັດຖະມົນຕີ ກະຊວງອຸດສາຫະກຳແລະການຄ້າ ລາງວັນນີ້ ແມ່ນເປັນລາງວັນທີ່ມີຄວາມໝາຍ ແລະ ຄວາມສຳຄັນເປັນຢ່າງຍິ່ງ ສຳລັບໂຮງງານຂອງພວກເຮົາ ເປັນລາງວັນທີ່ສ້າງຄວາມເຊື່ອໝັ້ນໃຫ້ແກ່ຜູ້ບໍລິໂພກຕໍ່ກັບຜະລິດຕະພັນ ແລະ ການບໍລິການຂອງພວກເຮົາ; ຍັງເປັນລາງວັນທີ່ເປັນແຮງຜັກດັນໃຫ້ໂຮງງານຄົງຮັກສາມາດຕະຖານການຜະລິດ, ຄຸນນະພາບຂອງສິນຄ້າ ແລະ ມີພັດທະນາໃຫ້ມີຄວາມກ້າວໜ້າຍິ່ງໆຂຶ້ນໄປ."

  // const detailsLa = [
  //   { id: '1', des: des1 }
  // ]
  return (
    <div>
      ​<br/> ​<br/>
      <Typography component={'div'}>
        <div className={classes.topic}>{props.t('title.4')}</div>
      </Typography>
      <br/>
      <CardNewsEvent/>
      {/* {detailsLa.map((row) => (
        <div key={row.id}>
          <Typography component={'div'} style={{ whiteSpace: "pre-wrap", textAlign: 'justify', textIndent: '40px', lineHeight: '180%' }}>
            <div style={{ fontSize: '16px' }}>{row.des}</div>
          </Typography>
        </div>
      ))} */}
      ​<br/> ​<br/>
    </div>
  )
}
