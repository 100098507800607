import React from 'react'

export default function GoogleMap() {
  //var map = new google.maps.Map(document.getElementById("googleMap"),mapProp);
  return (
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3739.2808178092164!2d104.04485231553166!3d20.412521986335356!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf6ebfb343d6b871f!2zMjDCsDI0JzQ1LjEiTiAxMDTCsDAyJzQ5LjQiRQ!5e0!3m2!1sth!2sla!4v1662718532849!5m2!1sth!2sla"
        width="100%"
        height="100%"
        style={{ border: '0' }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="myFrame"
        >

      </iframe>
  )
}

