import React from 'react'
import CreateOrder from 'Bt_modules/Sale/CreateOrder'

import { Redirect } from "react-router-dom";

export default function CreateOrders(props) {
    return (
        <div >
            {props.authenticated ?
                <CreateOrder accessToken={props.accessToken} userInfo={props.userInfo}/>
                :
                <Redirect
                    to={{
                        pathname: "/admin/sign-in",
                    }}
                />
            }
        </div>
    )
}

