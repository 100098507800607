import React, {useState, useEffect} from 'react'
import Typography from '@material-ui/core/Typography';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Container, Grid, IconButton } from '@material-ui/core';
import AddPartnerDialog from 'Bt_modules/Buy/AddPartnerDialog';
import EditPartnerDialog from 'Bt_modules/Buy/EditPartnerDialog';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Axios from 'axios';

const columns = [
    { id: '1', label: 'ລຳດັບ', minWidth: 100 },
    { id: '2', label: 'ຊື່ບໍລິສັດ', minWidth: 120 },
    { id: '3', label: 'ເບີໂທ', minWidth: 120 },
    { id: '5', label: 'ຊື່ຜູ້ຕິດຕໍ່', minWidth: 120 },
    { id: '6', label: 'ເບີໂທຜູ້ຕິດຕໍ່', minWidth: 120 },
    { id: '7', label: 'ແກ້ໄຂ', minWidth: 120 },
];

export default function AddBusinessPartner() {
    const [open, setOpen] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [partInfo, setPartInfo] = useState([])
    const [selectedPartner, setSelectedPartner] = useState()

    useEffect(() => {
        const options = {
            url: window.$api + "/getBuyPageInfo",
            method: "GET",
            headers:{
                "Accept": "application/json",
                //"content-Type": "application/json;charset-UTF-8"
            },
        };
        Axios(options).then(res =>{
            setPartInfo(res.data.partner)
        });
    }, [])

    const AddPartner = (id, companyName, phone, staffName, staffPhone) => {
        let newPartner = {"id":id, "companyname":companyName, "companyphone":phone, "contactperson":staffName, "phoneperson":staffPhone};
        let updatedPartnerInfo = [...partInfo];
        updatedPartnerInfo.push(newPartner);
        setPartInfo(updatedPartnerInfo);
    }

    const DeletePartner = (id) => {
        let updatedPartnerInfo = [...partInfo];
        let data = updatedPartnerInfo.filter(row => row.id !== id);
        setPartInfo(data);
    }

    const handleDelete = (rowId) => {
        const options = {
            url: window.$api + "/deletePartner",
            method: "POST",
            headers:{
                "Accept": "application/json",
            },
            data:{
                "id":rowId
            }
        };
        Axios(options).then(res =>{
            if(res.data) {
                alert(res.data);
                DeletePartner(rowId)
            }
        });
    }
    const handleEdit = (rowId) => {
        let partnerData = [...partInfo];
        let rowData = partnerData.filter(row => row.id === rowId);
        setSelectedPartner(rowData);
        setOpenEdit(true);
    }

    const EditPartner = (companyName, phone, staffName, staffPhone, partnerId) => {
        const options = {
            url: window.$api + "/editPartner",
            method: "POST",
            headers:{
                "Accept": "application/json",
            },
            data:{
                "companyName":companyName,
                "phone":phone,
                "staffName":staffName,
                "staffPhone":staffPhone,
                "partnerId":partnerId
            }
        };
        Axios(options).then(res =>{
            if(res.data) {
                alert(res.data);
                updateEditedPartner(companyName, phone, staffName, staffPhone, partnerId);
                setOpenEdit(false);
            }
        });
    }

    const updateEditedPartner = (companyName, phone, staffName, staffPhone, partnerId) => {
        let updateData = [...partInfo];
        updateData.forEach(row => {
            if(row.id === partnerId) {
            row.companyname = companyName;
            row.companyphone = phone;
            row.contactperson = staffName;
            row.phoneperson = staffPhone;
            }
        });
        setPartInfo(updateData);
    }
    

    return (
        <div>
            <Container maxWidth="xl">
                <Paper style={{padding: "2%", marginTop: "3vh",}} elevation={3}>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12}>
                            <Typography variant="h5" align='center'>
                                ຜູ້ສະໜອງ
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{textAlign: 'end'}}>
                            <Button 
                                variant="contained" 
                                color="secondary" 
                                onClick={()=> setOpen(true)}
                            >
                                ເພີ່ມ
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper style={{height: "70vh"}}>
                                <TableContainer style={{maxHeight: "69vh", borderRadius: 5}}>
                                    <Table aria-label="customized table" stickyHeader>
                                        <TableHead>
                                                <TableRow>
                                                    {columns.map((column) => (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            style={{ minWidth: column.minWidth, color: "white", backgroundColor: "#15023a" }}
                                                            >
                                                                {column.label}
                                                        </TableCell>  
                                                        ))}
                                                </TableRow>
                                            </TableHead>
                                        <TableBody>
                                            {partInfo && partInfo.map((row, index) => 
                                            <TableRow key={index}>
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell>{row.companyname}</TableCell>
                                                <TableCell>{row.companyphone}</TableCell>
                                                <TableCell>{row.contactperson}</TableCell>
                                                <TableCell>{row.phoneperson}</TableCell>   
                                                <TableCell>
                                                    <IconButton onClick={() => handleEdit(row.id)}>
                                                        <EditIcon/>
                                                    </IconButton>
                                                    <IconButton onClick={() => handleDelete(row.id)}>
                                                        <DeleteIcon/>
                                                    </IconButton>    
                                                </TableCell>                                         
                                            </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>    
                        </Grid>
                    </Grid>
                </Paper>
                <AddPartnerDialog open={open} setOpen={setOpen} AddPartner={AddPartner}/>
                <EditPartnerDialog open={openEdit} setOpen={setOpenEdit} selectedPartner={selectedPartner} EditPartner={EditPartner}/>
            </Container>
        </div>
    )
}
