import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import PublicApp from 'Apps/Public/index'
import AdminApp from 'Apps/Admin/index'
import './i18n'
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import { ThemeProvider } from '@material-ui/styles';
import { CssBaseline , createTheme} from '@material-ui/core';

const theme = createTheme({
  palette: {
    background: {
      default: "#FFFFFF",
      light: '#82E6FE',
      //dark: '#6F7992',
      dark: '#3E80EE'
    },
    primary: {
        main: '#E9FBFF',
        dark: '#00AED9',
    },
    secondary: {
        main: '#007B9A',
        light: '#BDBBB9'
    },
  },
  typography:{
    fontFamily: [
      'Noto Sans Lao',
      'Helvetica Neue',
    ].join('.'),
    // "fontFamily": `"Noto Sans Lao", "Helvetica Neue"`
  }
});

window.$api = 'https://srv.onewaterlaos.com';
window.$img = 'https://srv.onewaterlaos.com/img/';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline/>
    <Suspense fallback=''>
      <Router>
        <Switch>
          <Route path="/admin" component={AdminApp}/>
          <Route path="/" component={PublicApp}/>
        </Switch>
      </Router>
      {/*<App />*/}
    </Suspense>
  </ThemeProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
