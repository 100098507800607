import React, { useState, useEffect } from 'react'
import {
  Grid,
  Typography,
  Container,
  TextField,
  MenuItem,
  Button
} from '@mui/material';
import { Redirect } from "react-router-dom";
import BrokenProductReportTable from 'Bt_modules/Tables/BrokenProductReportTable'
import axios from 'axios';

export default function BrokenProductReport(props) {
  const [brokenProductList, setbrokenProductList] = useState([])
  const [selectedMonth, setselectedMonth] = useState("")
  const [selectedYear, setselectedYear] = useState("")

  useEffect(() => {
    axios({
      url: window.$api + '/getBrokenProductReport',
      method: 'get'
    }).then(res => {
      setbrokenProductList(res.data)
    })
  }, [])


  const startedYear = 2010
  const year = (new Date()).getFullYear();
  const years = []
  const months = []

  for (var i = year; i >= startedYear; i--) {
    years.push(i)
  }

  for (var j = 1; j <= 12; j++) {
    months.push(j)
  }

  const handleSelectMonth = (month) => {
    setselectedMonth(month)
  }

  const handleSelectYear = (year) => {
    setselectedYear(year)
  }

  const handleGetDataByMonAndYear = () => {
    if (selectedMonth && selectedYear) {
      axios({
        url: window.$api + '/getBrokenProductReportByMonAndYear',
        method: 'post',
        data: {
          month: selectedMonth,
          year: selectedYear
        }
      }).then(res => {
        setbrokenProductList(res.data)
      })
    } else {
      alert("ກະລຸນາເລືອກເດືອນ ແລະ ປີ")
    }
  }

  return (
    <Container maxWidth='lg'>
      {props.authenticated ?
        <div>
          <br />
          <Grid container>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ textAlign: 'center', padding: '1% 2%' }}>
              <Typography variant='h2'>
                ລາຍງານສິນຄ້າເສຍຫາຍ
              </Typography>
            </Grid>
          </Grid>

          <div>
            <div style={{ display: 'flex' }}>
              <TextField
                label="ເດຶອນ"
                variant='outlined'
                select
                value={selectedMonth || ""}
                style={{ width: "30%" }}
              >
                {months.map(row =>
                  <MenuItem key={row} value={row || ""} onClick={() => handleSelectMonth(row)}>
                    {row}
                  </MenuItem>
                )}
              </TextField>
              <TextField
                variant="outlined"
                label='ປີ'
                select
                value={selectedYear || ""}
                style={{ width: "30%" }}
              >
                {years.map(row =>
                  <MenuItem key={row} value={row || ""} onClick={() => handleSelectYear(row)}>
                    {row}
                  </MenuItem>
                )}
              </TextField>
              <Button variant='contained' onClick={handleGetDataByMonAndYear}>
                ເບິ່ງຂໍ້ມູນ
              </Button>
            </div>
          </div>
          <br />
          <BrokenProductReportTable dataList={brokenProductList} />
        </div>
        :

        <Redirect
          to={{
            pathname: "/admin/sign-in",
          }}
        />
      }
    </Container>
  )

}
