import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Grid, TextField, Button, Typography, DialogContent, DialogActions, MenuItem } from '@material-ui/core';

export default function AddCustomerPriceDiag(props) {
    return (
        <Dialog
            open={props.open}
        >
            <DialogContent>
                <Grid container spacing={2} >
                    <Grid item xs={12} >
                        <Typography>
                            ເພີ່ມປະເພດລາຄາສິນຄ້າໃຫມ່
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            label="ປະເພດລາຄາ"
                            select
                            fullWidth
                            value={props.selectedPriceCatName || ""}
                        >
                            {props.customerPriceList.map((row, index) => (
                                <MenuItem key={index} value={row.name} onClick={() => props.handleSelectCustomerCat(row.id, row.name)}>
                                    {row.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    {props.selectedPriceCatName &&
                        <Grid item xs={12}>
                            <TextField
                                label={'ລາຄາ'}
                                variant="outlined"
                                value={props.priceCat || ""}
                                onChange={props.handleChangePriceCat}
                                fullWidth
                            />
                        </Grid>
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleAddCusPriceList}>
                    ເພີ່ມ
                </Button>
                <Button onClick={props.handleClose}>ຍົກເລີກ</Button>
            </DialogActions>
        </Dialog>
    )
}