import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Table, IconButton } from '@material-ui/core/';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#4E5099",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function CustomizedTables(props) {
  return (
    <div>
    <TableContainer component={Paper} style={{maxHeight: "36vh"}}>
      <Table aria-label="customized table" stickyHeader>
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">ລຳດັບ</StyledTableCell>
            <StyledTableCell align="center">ບາໂຄດສິນຄ້າແມ່</StyledTableCell>
            <StyledTableCell align="center">ຈຳນວນທີຍ້າຍ</StyledTableCell>
            <StyledTableCell align="center">ຈຳນວນຫຼັງຍ້າຍ</StyledTableCell>
            <StyledTableCell align="center">ບາໂຄດສິນຄ້າຍ່ອຍ</StyledTableCell>
            <StyledTableCell align="center">ຈຳນວນຫຼັງຍ້າຍ</StyledTableCell>
            <StyledTableCell align="center">ລຶບ</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {props.tableData.map((( row, index )=>
          <StyledTableRow key={index}>
            <StyledTableCell align="center">{index + 1}</StyledTableCell>
            <StyledTableCell align="center">{row.barcode}</StyledTableCell>
            <StyledTableCell align="center">{row.qty_to_move}</StyledTableCell>
            <StyledTableCell align="center">{row.qtyAfterMove}</StyledTableCell>
            <StyledTableCell align="center">{row.sub_product_barcode}</StyledTableCell>
            <StyledTableCell align="center">{Number(row.subQtyAfterMove).toLocaleString()}</StyledTableCell>
            <StyledTableCell align="center">
              <IconButton onClick={()=> props.handleDelete(row.barcode)}>
                <DeleteIcon/>
              </IconButton>
            </StyledTableCell>
          </StyledTableRow>
        ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
  );
}
