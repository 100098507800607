import React from 'react'
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Card from '../../../Bt_modules/Card/Card'

const useStyles = makeStyles((theme) => ({

  topic: {
      fontWeight: 'bold',
      //color: theme.palette.secondary.dark,
      fontSize: '18px',
      [theme.breakpoints.up('sm')]: {
          fontSize: '25px',

      },
      textIndent: '40px'
  },
}));

export default function Homepage(props) {
  const classes = useStyles();

  return (
    <div>
      ​<br/> ​<br/>
      <Typography component={'div'}>
        <div className={classes.topic}>{props.t('home.1')}</div>
      </Typography>
      <br/>
        <Card />
    </div>
  )
}
