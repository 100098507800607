import React from 'react'
import { Box } from '@mui/material'

export default function CircleOrder() {
    let Green = '#0C69CB'
    return (
        <Box sx={{ padding: '0 0', backgroundColor: '', width: '100%', display: 'flex', justifyContent: 'center'}}>
            <Box
                sx={{
                    borderRadius: '50%',
                    borderLeft: '40px solod transparent',
                    borderRight: '40px solod transparent',
                    borderBottom: '80px solod transparent',
                    border: '6px solid',
                    position: 'relative',
                    alignContent: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: Green,
                    height: { xl: '200px', lg: '180px', md: '180px', sm: '180', xs: '180px' },
                    width: { xl: '200px', lg: '180px', md: '180px', sm: '180', xs: '180px' },
                    
                }}>

                <div style={{ color: Green, fontSize: '20px' }}>
                    10,000,000,000,000
                </div>
            </Box>
        </Box>
    )
}
