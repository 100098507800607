import React, { useState, useEffect } from 'react'
import { Grid, TextField, Typography, MenuItem, Button } from '@material-ui/core'
import { Box } from '@mui/material'
import TableReportStock from 'Bt_modules/Tables/TableReportStock'
import Axios from 'axios'

export default function StockDetailerReport() {

  const [selectedprovince, setSelectedprovince] = useState()
  const [provinces, setProvinces] = useState([])
  const [selectedDistrict, setSelectedDistrict] = useState()
  const [districts, setDistricts] = useState([])
  const [selectedVillage, setSelectedVillage] = useState()
  const [villages, setVillages] = useState([])
  const [selectedDetailer, setSelectedDetailer] = useState()
  const [detailers, setDetailers] = useState([])
  const [getIdProv, setGetIdProv] = useState()
  const [getIdDist, setGetIdDist] = useState()
  const [getIdVill, setGetIdVill] = useState()
  const [getIdDetailer, setGetIdDetailer] = useState()
  const [dataInfo, setDataInfo] = useState([])

  const handleProv = (id, value) => {
    setSelectedprovince(value);
    setGetIdProv(id)
  };

  const handleDist = (id, value) => {
    setSelectedDistrict(value);
    setGetIdDist(id)
  };

  const handleVill = (id, value) => {
    setSelectedVillage(value);
    setGetIdVill(id)
  };

  const handleDetailer = (id, value) => {
    setSelectedDetailer(value);
    setGetIdDetailer(id)
  };

  useEffect(() => {
    let isMounted = true;
    const options = {
      url: window.$api + "/getStockReport",
      method: "POST",
      withCredentials: true,
      headers: {
        "Accept": "application/json",
        //"content-Type": "application/json;charset-UTF-8"
      },
    };
    Axios(options).then(Response => {
      if (isMounted) {
        setDataInfo(Response.data.stockinfo)
        setProvinces(Response.data.province)
      }
    })
    return () => { isMounted = false };
  }, [])

  useEffect(() => {
    let isMounted = true;
    const options = {
      url: window.$api + "/getdistricts",
      method: "POST",
      withCredentials: true,
      headers: {
        "Accept": "application/json",
        //"content-Type": "application/json;charset-UTF-8"
      },
    };
    Axios(options).then(Response => {
      if (isMounted) {
        setDistricts(Response.data)
        //console.log(Response.data)
      }
    })
    return () => { isMounted = false };
  }, [])

  useEffect(() => {
    let isMounted = true;
    const options = {
      url: window.$api + "/getvillages",
      method: "POST",
      withCredentials: true,
      headers: {
        "Accept": "application/json",
        //"content-Type": "application/json;charset-UTF-8"
      },
    };
    Axios(options).then(Response => {
      if (isMounted) {
        setVillages(Response.data)
        //console.log(Response.data)
      }
    })
    return () => { isMounted = false };
  }, [])

  useEffect(() => {
    let isMounted = true;
    const options = {
      url: window.$api + "/getdetailer",
      method: "POST",
      withCredentials: true,
      headers: {
        "Accept": "application/json",
        //"content-Type": "application/json;charset-UTF-8"
      },
    };
    Axios(options).then(Response => {
      if (isMounted) {
        setDetailers(Response.data)
        //console.log(Response.data)
      }
    })
    return () => { isMounted = false };
  }, [])

  const Submit = () => {
    if(selectedprovince){
      if(selectedDistrict){
        if(selectedVillage){
          if(selectedDetailer){
            setDataInfo([])
            const options = {
              url: window.$api + "/getStockReportById",
              method: "POST",
              headers: {
                "Accept": "application/json",
                //"content-Type": "application/json;charset-UTF-8"
              },
              data: { "id": getIdDetailer },
            };
            Axios(options).then(res => {
              setDataInfo(res.data)
            })
          }else{
            alert('ກະລຸນາເລືອກສາຂາ')
          }
        }else{
          alert('ກະລຸນາເລືອກບ້ານ')
        }
      }else{
        alert('ກະລຸນາເລືອກເມືອງ')
      }
    }else{
      alert('ກະລຸນາເລືອກແຂວງ')
    }
  }

  const SeeAll = () => {
    setDataInfo([])
    const options = {
      url: window.$api + "/getStockReport",
      method: "POST",
      headers: {
        "Accept": "application/json",
        //"content-Type": "application/json;charset-UTF-8"
      },
    };
    Axios(options).then(Response => {
      setDataInfo(Response.data.stockinfo)
      setSelectedprovince();
      setSelectedDistrict();
      setSelectedVillage();
      setSelectedDetailer();
    })
  }


  return (
    <div >
      <br />
      <br />
      <div >
        <Typography variant="h5" align='center'>
          ລາຍງານສຕ໋ອກ ສາຂາ
        </Typography>
      </div>
      <Grid container spacing={2} style={{ padding: '1% 5%' }}>
        <Grid item xl={10} lg={10} md={9} sm={12} xs={12} style={{ backgroundColor: '', }}>
          <Box sx={{ display: { xl: 'flex', lg: 'flex', md: 'flex', sm: 'flex', xs: 'flex' } }}>
            <TextField
              id="standard-select-province"
              label="ແຂວງ"
              variant="outlined"
              value={selectedprovince || ""}
              //onChange={handleProv}
              fullWidth
              select
            >
              {provinces.map((row) => (
                <MenuItem key={row.id} value={row.province || ""} onClick={() => handleProv(row.id, row.province)}>
                  {row.province}
                </MenuItem>
              ))}
            </TextField>
            &nbsp;&nbsp;&nbsp;
            <TextField
              id="standard-select-distric"
              label="ເມືອງ"
              variant="outlined"
              value={selectedDistrict || ""}
              //onChange={handleDist}
              fullWidth
              select
            >
              {districts.map((row) => (
                <MenuItem key={row.id} value={row.district || ""} onClick={() => handleDist(row.id, row.district)}>
                  {getIdProv === row.id_province &&
                    <>
                      {row.district}
                    </>
                  }
                </MenuItem>
              ))}
            </TextField>
            &nbsp;&nbsp;&nbsp;
            <TextField
              id="standard-select-village"
              label="ບ້ານ"
              variant="outlined"
              value={selectedVillage || ""}
              //onChange={handleVill}
              fullWidth
              select
            >
              {villages.map((row) => (
                <MenuItem key={row.id} value={row.village || ""} onClick={() => handleVill(row.id, row.village)}>
                  {getIdDist === row.id_district &&
                    <>
                      {row.village}
                    </>
                  }
                </MenuItem>
              ))}
            </TextField>
            &nbsp;&nbsp;&nbsp;
            <TextField
              id="dstandard-select-etailer"
              label="detailer"
              variant="outlined"
              value={selectedDetailer || ""}
              fullWidth
              select
            >
              {detailers.map((row) => (
                <MenuItem key={row.id} value={row.name || ""} onClick={() => handleDetailer(row.id, row.name)}>
                  {getIdVill === row.village &&
                    <>
                      {row.name}
                    </>
                  }
                </MenuItem>
              ))}
            </TextField>

          </Box>
        </Grid>
        <Grid item xl={2} lg={2} md={3} sm={12} xs={12} style={{ backgroundColor: '', }}>
          &nbsp;&nbsp;&nbsp;
          <Button variant='outlined'  onClick={() => { Submit(); }}>ຄົ້ນຫາ</Button>
          &nbsp;&nbsp;&nbsp;
          <Button variant='outlined'  onClick={() => { SeeAll(); }}> ເບິ່ງທັງໝົດ</Button>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ backgroundColor: '', }}>
          <TableReportStock data={dataInfo} />
        </Grid>
      </Grid>
    </div>
  )
}
