import React, { useState } from 'react';
import { IconButton, Container, Paper, MenuItem, Typography, Grid, TextField, Button } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DialogAddCategory from 'Bt_modules/AddNewProduct/DialogAddCategory';
import DialogAddUnit from 'Bt_modules/AddNewProduct/DialogAddUnit';
import NoImg from 'Img/no-image.png'
import AddCustomerPriceDiag from 'Bt_modules/AddNewProduct/AddCustomerPriceDiag';
import Skeleton from '@material-ui/lab/Skeleton';

export default function AddnewMaterial(props) {
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [loaded, setloaded] = useState(false)
  const [loading, setloading] = useState(true)
  const [showNoImg, setshowNoImg] = useState(false)

  // React.useEffect(() => {
  //   if(props.handleSelectCate) {
  //     props.handleSelectCate("37", "ວັດຖຸດິບ")
  //   }
  // }, [props]);

  //dialog
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClickOpenDialogAddUnit = () => {
    setOpen1(true);
  };

  const handleOnLoad = () => {
    setloaded(true)
    setshowNoImg(false)
    setloading(false)
  }

  const handleOnError = () => {
    setshowNoImg(true)
    setloaded(false)
    setloading(false)
  }

  return (
    <div>
      <Container maxWidth='xl'>
        <Paper style={{ padding: "2%", marginTop: "2vh", marginBottom: "2vh" }}>
          <Grid container spacing={2}>
            <Grid item md={6} sm={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6" component="h6">ລາຍລະອຽດວັດຖຸດິບ</Typography>
                </Grid>
                {props.autoBarcode ?
                  <>
                    <Grid item sm={10} xs={12}>
                      <TextField
                        id="outlined-basic"
                        name='barcode'
                        label="ລະຫັດວັດຖຸດິບ"
                        variant="outlined"
                        fullWidth
                        disabled
                        value={props.productInfo.barcode || ""}
                        onChange={props.handleChange}
                      />
                    </Grid>
                    <Grid item sm={2} xs={12}>
                      <Button variant="contained" color="secondary" onClick={props.handleResetAuto} style={{ width: "100%" }}>reset</Button>
                    </Grid>
                  </>
                  :
                  <>
                    <Grid item sm={10} xs={12}>
                      <TextField
                        id="outlined-basic"
                        name='barcode'
                        label="ລະຫັດວັດຖຸດິບ"
                        variant="outlined"
                        fullWidth
                        value={props.productInfo.barcode || ""}
                        onChange={props.handleChange}
                      />
                    </Grid>
                    <Grid item sm={2} xs={12}>
                      <Button variant="contained" color="secondary" onClick={props.handleAuto} style={{ width: "100%" }}>auto</Button>
                    </Grid>
                  </>
                }
                {/* <Grid item sm={10} xs={12}>
                  <TextField
                    id="outlined-basic"
                    name='barcode'
                    label="ລະຫັດວັດຖຸດິບ"
                    variant="outlined"
                    fullWidth
                    value={props.productInfo.barcode || ""}
                    onChange={props.handleChange}
                  />
                </Grid>
                <Grid item sm={2} xs={12}>
                  {props.autoBarcode ? 
                    <Button variant="contained" color="secondary" onClick={props.handleResetAuto} style={{ width: "100%" }}>reset</Button>
                    :
                    <Button variant="contained" color="secondary" onClick={props.handleAuto} style={{ width: "100%" }}>auto</Button>
                  }
                </Grid> */}
                <Grid item sm={10} xs={12}>
                  <TextField
                    id="outlined-basic"
                    name='productName'
                    label="ຊື່ວັດຖຸດິບ"
                    variant="outlined"
                    fullWidth
                    value={props.productInfo.productName || ""}
                    onChange={props.handleChange}
                  />
                </Grid>
                {/* <Grid item sm={10} xs={10}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    label="ປະເພດວັດຖຸດິບ"
                    select
                    fullWidth
                    value={props.productInfo.selectCateName || ""}
                  >
                    {props.category.map((row, index) => (
                      <MenuItem
                        key={index}
                        value={row.name_cat || ""}
                        onClick={() => props.handleSelectCate(row.id, row.name_cat)}
                      >
                        {row.name_cat}
                      </MenuItem>
                    ))}
                  </TextField>

                </Grid> */}
                {/* <Grid item sm={2} xs={2}>
                  <IconButton
                    variant="contained"
                    color="primary"
                    onClick={handleClickOpen}
                  >
                    <AddCircleIcon style={{ color: '#009900' }} />
                  </IconButton>
                </Grid> */}
                <Grid item sm={10} xs={10}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    label="ຫົວໜ່ວຍນັບ"
                    select
                    fullWidth
                    value={props.productInfo.selectUnitName || ""}
                  >
                    {props.unit.map((row, index) => (
                      <MenuItem key={index} value={row.unit_name} onClick={() => props.handleSelectUnit(row.id, row.unit_name)}>
                        {row.unit_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item sm={2} xs={2}>
                  <IconButton
                    variant="contained"
                    color="primary"
                    onClick={handleClickOpenDialogAddUnit}
                  >
                    <AddCircleIcon style={{ color: '#009900' }} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={6} sm={12} xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h6" component="h6">ຮູບພາບ</Typography>
                </Grid>
                <Grid item xs={12} style={{ marginTop: "2%", marginBottom: "2%" }}>
                  {props.img ?
                    <div>
                      {props.editPage ?
                        <div>
                          <img
                            style={loaded ? {} : { display: "none" }}
                            src={props.imgSrc}
                            alt=''
                            width={"100%"}
                            onLoad={handleOnLoad}
                            onError={handleOnError}
                          />
                          {loading && <Skeleton variant="rect" width={"100%"} height={200} />}
                          {showNoImg && <img src={NoImg} alt='' width={"100%"} />}
                        </div>
                        :
                        <img src={props.img} alt='' width={"100%"} />
                      }
                    </div>

                    :
                    <img src={NoImg} alt='' width={"100%"} />
                  }
                </Grid>
                <Grid item sm={2} xs={12}>
                  <Button variant="contained" color="secondary" style={{ width: "90%" }} component="label">
                    ເພີ່ມ
                    <input id="img" hidden accept="image/*" type="file" onChange={props.onChangeImg} />
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={6} sm={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} >
                  <Typography variant="h6" component="h6">
                    ລາຄາວັດຖຸດິບ
                  </Typography>
                </Grid>
                <Grid item xs={12} >
                  <TextField
                    id="outlined-basic"
                    name='cost'
                    label="ລາຄາຕົ້ນທຶນ"
                    variant="outlined"
                    fullWidth
                    value={props.productInfo.cost || ""}
                    onChange={props.handleChange}
                  />
                </Grid>
                <Grid item xs={12} >
                  <TextField
                    id="outlined-basic"
                    name='price'
                    label="ລາຄາຂາຍທົ່ວໄປ"
                    variant="outlined"
                    fullWidth
                    value={props.productInfo.price || ""}
                    onChange={props.handleChange}
                  />
                </Grid>
                {/* {JSON.stringify(props.category)} */}
                {/* {props.addedCusPriceList.map(row =>
                  <Grid item xs={12} key={row.id}>
                    <TextField
                      id={String(row.id)}
                      label={row.name}
                      onChange={props.handleChangeAddedCusPriceList}
                      value={row.value || ""}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                )} */}
                {/* <Grid item xs={12} >
                  <Button variant="contained" color="secondary" onClick={props.handleOpenCusPriceCatDiag}>
                    ເພີ່ມປະເພດລາຄາ
                  </Button>
                </Grid> */}
              </Grid>
            </Grid>

            {/* <Grid item md={6} sm={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6" component="h6">
                    ລາຍລະອຽດສິນຄ້າຍ່ອຍ
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-basic"
                    name="subBarcode"
                    label="ບາໂຄດສິນຄ້າຍ່ອຍ"
                    variant="outlined"
                    fullWidth
                    value={props.productInfo.subBarcode || ""}
                    onChange={props.handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-basic"
                    name='subNameProduct'
                    label="ຊື່ສິນຄ້າຍ່ອຍ"
                    variant="outlined"
                    fullWidth
                    value={props.productInfo.subNameProduct || ""}
                    onChange={props.handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-basic"
                    name='subQty'
                    label="ຈຳນວນສິນຄ້າຍ່ອຍ ຕໍ່ 1 ສິນຄ້າແມ່"
                    variant="outlined"
                    fullWidth
                    value={props.productInfo.subQty || ""}
                    onChange={props.handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-basic"
                    name='subPrice'
                    label="ລາຄາຕົ້ນທຶນສິນຄ້າຍ່ອຍ"
                    variant="outlined"
                    fullWidth
                    value={props.productInfo.subPrice || ""}
                    onChange={props.handleChange}
                  />
                </Grid>
              </Grid>
            </Grid> */}
          </Grid>
          {props.showSubmitButton &&
            <Grid item xs={12} style={{ textAlign: 'center', marginTop: "2%" }}>
              <Button variant="contained" color="secondary" onClick={props.Submit}>
                ບັນທຶກ
              </Button>
            </Grid>
          }
        </Paper>
      </Container>
      <DialogAddCategory open={open} setOpen={setOpen} setCategory={props.setCategory} category={props.category} />
      <DialogAddUnit open={open1} setOpen1={setOpen1} setUnit={props.setUnit} />
      <AddCustomerPriceDiag
        open={props.openAddCusPriceCatDiag}
        handleClose={props.handleCloseCusPrceCatDiag}
        selectedPriceCatName={props.selectedPriceCatName}
        handleAddCusPriceList={props.handleAddCusPriceList}
        handleSelectCustomerCat={props.handleSelectCustomerCat}
        customerPriceList={props.customerPriceList}
        priceCat={props.priceCat}
        handleChangePriceCat={props.handleChangePriceCat}
      />
    </div>
  );
}