import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Grid, TextField, Typography, Paper } from '@material-ui/core';
import { Box } from '@mui/material'
import CircleOrder from 'Bt_modules/Circle/CircleOrder';
import { Redirect } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '1%',
    },
    textColor: {
        color: theme.palette.background.light
    },
    text: {
        fontSize: '30px',
        [theme.breakpoints.up('md')]: {
            fontSize: '50px',
        },
    },
    paper: {
        padding: theme.spacing(2),
        //textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

export default function ReportOrder(props) {
    const classes = useStyles();

    if (props.authenticated) {
        return (
            <div>
                <br />
                <Grid container>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ backgroundColor: '', textAlign: 'center', padding: '1% 2%' }}>
                        <Typography component='div'>
                            <div>ລາຍງານຂໍ້ມູນສັ່ງຊື້</div>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={1} style={{ padding: '0.1% 2%' }}>
                    <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                        <Paper className={classes.paper}>
                            <Typography component='div'>
                                <div>ລາຍງານຕາມລະຫັດບາໂຄດ</div>
                            </Typography>
                            <br />
                            <div>
                                <TextField
                                    label="ລະຫັດບາໂຄດ"
                                    variant='outlined'
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                        required: true
                                    }}
                                />
                            </div>
                            <br />
                        </Paper>
                    </Grid>
                    <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                        <Paper className={classes.paper}>
                            <Typography component='div'>
                                <div>ລາຍງານຕາມວັນທິ</div>
                            </Typography>
                            <br />
                            <Grid container spacing={2}>
                                <Grid item xl={5} lg={5} md={5} sm={5} xs={12}>
                                    <TextField
                                        label="ວັນທີເລີ່ມຕົ້ນ"
                                        variant='outlined'
                                        fullWidth
                                        type='date'
                                        InputLabelProps={{
                                            shrink: true,
                                            required: true
                                        }}
                                    />
                                </Grid>
                                <Grid item xl={5} lg={5} md={5} sm={5} xs={12}>
                                    <TextField
                                        label="ວັນທີສິ້ນສຸດ"
                                        variant='outlined'
                                        fullWidth
                                        type='date'
                                        InputLabelProps={{
                                            shrink: true,
                                            required: true
                                        }}
                                    />
                                </Grid>
                                <Grid item xl={2} lg={2} md={2} sm={2} xs={12}>
                                    <Box
                                        sx={{
                                            textAlign: 'center',
                                            padding: { xl: '9% 10%', lg: '4% 7.5%', md: '5% 4%', sm: '2% 1%', xs: '0 0' },
                                            backgroundColor: '#57D65B',
                                            borderRadius: 2,
                                            color: '#FFFFFF',
                                            cursor: 'pointer',
                                            ":active": {
                                                backgroundColor: '#BDBBB9',
                                            },
                                            //backgroundColor: { xl: 'red', lg: 'green', md: 'pink', sm: 'gray', xs: 'yellow' },
                                        }}
                                    >
                                        <Typography component='div' noWrap>
                                            <div>ເບິ່ງລາຍລະອຽດ</div>
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                            <br />
                        </Paper>
                    </Grid>
                </Grid>
                <Grid container spacing={1} style={{ padding: '0 2%', backgroundColor: '', }}>
                    <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                        <Paper className={classes.paper}>
                            <Typography component='div'>
                                <div>ຍອດມູນຄ່າສັ່ງຊື້ທັງໝົດ</div>
                            </Typography>
                            <div style={{ display: 'flex', justifyContent: 'center', padding: '2%' }}>
                                <div style={{ alignSelf: 'center' }}>
                                    <CircleOrder />
                                </div>
                            </div>

                        </Paper>
                    </Grid>
                    <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
                        <Paper className={classes.paper}>
                            <br />
                            <Grid container spacing={2}>

                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <Box
                                        sx={{
                                            display: { xl: 'flex', lg: 'flex', md: 'flex', sm: 'flex', xs: 'flex' },
                                            justifyContent: { xl: 'center', lg: 'center', md: 'center', sm: 'center', xs: 'center' },
                                            padding: { xl: '1% 1%', lg: '1% 1%', md: '1% 1%', sm: '1% 1%', xs: '0 0' },
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                padding: { xl: '1.9% 40%', lg: '1.9% 35%', md: '2.5% 35%', sm: '1.9% 35%', xs: '1.9% 35%' },
                                                backgroundColor: 'blue',
                                                borderRadius: 1,
                                                color: '#fff'
                                            }}>
                                            <Typography component='div' noWrap>
                                                <div>
                                                    0 ກີບ
                                                </div>
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                            <br />
                        </Paper>
                    </Grid>
                </Grid>
            </div >
        )
    } else {
        return (
            <div>
                <Redirect
                    to={{
                        pathname: "/admin/sign-in",
                    }}
                />
            </div>
        )
    }
}
