import React, { useState, useRef, useEffect } from 'react'
import { TextField, Button, Container, Typography, Paper } from '@material-ui/core/';
import Grid from '@material-ui/core/Grid';
import MaterialList from 'Apps/Admin/CutNumbMaterial/MaterialList'
import Axios from 'axios'
import { makeStyles } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import { Redirect } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        position: "relative",
        height: "100vh"
    },
    fab: {
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 20,
        left: 'auto',
        position: 'fixed',
    },
    textinput: {
        marginLeft: '16%',
        marginBottom: '4%',
        color: "#15023a"
    },
    inputLable: {
        marginLeft: '16%',
        //color: "#15023a"
        color: theme.palette.primary.dark,
        marginTop: '-2%'
    },
    img: {
        width: "40%",
        height: "auto",
        marginLeft: "30%",
        marginTop: '3%',
        [theme.breakpoints.down("sm")]: {
            display: "none"
        }
    },
    buttonColor: {
        backgroundColor: theme.palette.primary.dark,
        width: "100%",
        color: theme.palette.primary.main
    },
    buttonColor2: {
        backgroundColor: theme.palette.primary.light,
        width: "100%",
        color: theme.palette.error.main
    },
    bg: {
        backgroundColor: theme.palette.primary.dark,
        textAlign: 'center',
        color: theme.palette.primary.main,
        padding: '1% 0',
        fontSize: '25px'
    },
    textColor: {
        color: theme.palette.primary.dark,
    }
}));

export default function CutNumbMaterial(props) {
    const classes = useStyles();
    const [qty, setQty] = useState(0);
    const [price, setPrice] = useState(0);
    const [name, setName] = useState();
    const [id, setId] = useState(1);
    const [barcode, setBarcode] = useState();;
    const [total, setTotal] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [allQty, setAllQty] = useState(0);
    //const [sellerName, setSellerName] = useState("")
    const [billcode, setBillcode] = useState("")
    const barcodeAutoFocus = useRef();
    const receiveAutoFocus = useRef();

    useEffect(() => {
        if(props.accessToken){
            barcodeAutoFocus.current.focus()
            const options = {
                url: window.$api + "/getLatestBillcodeMaterial",
                method: "GET",
                headers: {
                    "Accept": "application/json",
                    "content-Type": "application/json;charset-UTF-8"
                },
            }
            Axios(options).then(res => {
                setBillcode(res.data.billcode)
            })
        }
    }, [props.accessToken])

    // const handleBarcode = (e) => {
    //     setBarcode(e.target.value)
    // }

    const handleBarcode = (e) => {
        setBarcode(e.target.value)
    }

    // const handleCash = (e) => {
    //     setCash(e.target.value);
    // }

    const hasBarcode = (arr, barcode) => arr.some(el => el.barcode === barcode);

    const HandleEnter = (e) => {
        if (e.key === 'Enter') {
            const options = {
                url: window.$api + "/getRawmaterialInfo",
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    //"content-Type": "application/json;charset-UTF-8"
                },
                data: {
                    "barcode": barcode,
                },
            }
            Axios(options).then(Response => {
                if (Response.data.material) {
                    if (Response.data.material.category_id === "37") {
                        let cost = Number(Response.data.material.cost);
                        let product_barcode = Response.data.material.product_barcode;
                        let price = Number(Response.data.material.price_kip);
                        let current_qty = Number(Response.data.material.quantity);
                        if (hasBarcode(tableData, product_barcode)) {
                            let newArr = tableData.map((obj) => {
                                if (obj.barcode && obj.barcode === product_barcode) {
                                    if (obj.qty < current_qty) {
                                        obj.qty = obj.qty + 1;
                                        obj.total = price * obj.qty;
                                        obj.profit = obj.total - (obj.qty * cost);
                                        setQty(obj.qty);

                                    }
                                    else {
                                        alert('ສິນຄ້າບໍ່ພໍ');
                                    }
                                }
                                return obj;
                            })
                            setTableData(newArr);
                            setBarcode("");
                            setAllQty(tableData.reduce((prev, next) => prev + next.qty, 0));
                            setTotal(tableData.reduce((prev, next) => prev + next.total, 0));
                            setName(Response.data.material.product_name);
                        }
                        else {
                            if (current_qty > 0) {
                                let newArray = [...tableData];
                                let price = Number(Response.data.material.price_kip);
                                var tempdata = {
                                    id: id,
                                    barcode: barcode,
                                    name: Response.data.material.product_name,
                                    qty: 1,
                                    price: price,
                                    total: price,
                                    profit: price - Number(Response.data.material.cost),
                                    username: props.userInfo.username,
                                }
                                newArray.push(tempdata);
                                setTableData(newArray);
                                setId(id + 1);
                                setBarcode("");
                                setPrice(price);
                                setQty(1);
                                setAllQty(newArray.reduce((prev, next) => prev + next.qty, 0))
                                setTotal(newArray.reduce((prev, next) => prev + next.total, 0));
                                setName(Response.data.material.product_name);
                            } else {
                                alert('ສິນຄ້າບໍ່ພໍ');
                                setBarcode("");
                            }
                        }
                    } else {
                        alert('ລະຫັດບໍ່ຖືກຕ້ອງ (ວັດສະດຸເທົ່ານັ້ນ)');
                        setBarcode("");
                    }
                } else {
                    alert('ລະຫັດບໍ່ຖືກຕ້ອງ');
                    setBarcode("");
                }
            });
        }

    }


    const handleSubmit = () => {
        const options = {
            url: window.$api + "/pickUpMaterial",
            method: "POST",
            headers: {
                "Accept": "application/json",
                "content-Type": "application/json;charset-UTF-8"
            },
            data: tableData,
        }
        Axios(options).then(Response => {
            //alert(props.userInfo.username)
            const options = {
                url: window.$api + "/getLatestBillcodeMaterial",
                method: "GET",
                headers: {
                    "Accept": "application/json",
                    "content-Type": "application/json;charset-UTF-8"
                },
            }
            Axios(options).then(res => {
                setBillcode(res.data.billcode)
            })
            alert(Response.data)
            setTableData([]);
            setTotal("");
            setQty("");
            setAllQty("");
            setPrice("");
            setName("");
        });
    }

    const handleCancel = () => {
        setTableData([]);
        setTotal("");
        setQty("");
        setAllQty("");
        setPrice("");
        setName("");
    }

    const handleKeyDown = (e) => {
        switch (e.key) {
            case "p":
                if (tableData.length > 0) {
                    receiveAutoFocus.current.focus()
                }
                break;
            default:
                break;
        }
    }

    const handleSaleKeyPress = (e) => {
        if (e.key === "Enter") {
            barcodeAutoFocus.current.focus()
        }
    }

    if (props.authenticated) {
        return (
            <div className={classes.root} onKeyDown={handleKeyDown} onKeyPress={handleSaleKeyPress} tabIndex={0}>
                <Container maxWidth="xl" style={{}}>
                    <br />
                    <Grid
                        container
                        direction="row"
                        spacing={3}
                    >
                        <Grid item lg={9} md={3} sm={12} xs={12} >
                            <Typography className={classes.bg}>
                                ວັດຖຸດິບອອກສາງ
                            </Typography>
                            <br />
                            <Grid container
                                direction="row"
                                spacing={1}
                            >
                                <Grid item lg={5} md={3} sm={12} xs={12}>
                                    <Paper style={{ borderRadius: 5, }}>
                                        <TextField
                                            InputProps={{ disableUnderline: true, className: classes.textinput }}
                                            InputLabelProps={{ className: classes.inputLable, }}
                                            //style={{ borderRadius: 10, }}
                                            label="ກະລຸນາປ້ອນບາໂຄດ"
                                            onKeyPress={HandleEnter}
                                            onChange={handleBarcode}
                                            value={barcode || ""}
                                            inputRef={barcodeAutoFocus}

                                        />
                                    </Paper>
                                </Grid>
                                <Grid item lg={7} md={7} sm={12} xs={12}>
                                    <Paper style={{ borderRadius: 5, padding: 16 }}>
                                        <Grid container wrap="nowrap">
                                            <Grid item xs={6}>
                                                <Typography className={classes.textColor} >ຊື່ສິນວັດຖຸດິບ: {name}</Typography>
                                            </Grid>
                                            {name &&
                                                <>
                                                    <Grid item xs={1}>
                                                        <Typography className={classes.textColor}>{qty}</Typography>
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <Typography className={classes.textColor}>x</Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography color='secondary'>
                                                            <NumberFormat value={price} displayType={'text'} thousandSeparator={true} suffix={' ກີບ'} />
                                                        </Typography>
                                                    </Grid>
                                                </>
                                            }
                                        </Grid>
                                    </Paper>
                                </Grid>
                                {tableData.length > 0 ?
                                    <Grid container direction="row" style={{ marginTop: "1%" }} spacing={3} >
                                        <Grid item lg={12} md={12} xs={12} sm={12}>
                                            <MaterialList
                                                tableData={tableData}
                                                setTableData={setTableData}
                                                setTotal={setTotal}
                                                total={total}
                                                setName={setName}
                                                setQty={setQty}
                                                setPrice={setPrice}
                                                setAllQty={setAllQty}
                                                allQty={allQty}
                                            />
                                        </Grid>
                                    </Grid>
                                    :
                                    null
                                }

                            </Grid>
                        </Grid>
                        <br />
                        <Grid item lg={3} md={1} sm={12} xs={12} style={{ textAlign: 'center' }}>
                            <Typography className={classes.bg}>ລວມຍອດການເບິກວັດຖຸດິບ</Typography>
                            <Grid container
                                direction="row"
                                spacing={1}
                            >
                                {tableData.length > 0 ?
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <br></br>
                                        <Grid item container style={{ padding: '0 2%' }}>
                                            <Grid item xs={6} container justifyContent="flex-start">
                                                <Typography>
                                                    ເລກທີບິນ
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} container justifyContent="flex-end">
                                                <Typography>
                                                    {billcode}
                                                </Typography>
                                            </Grid>
                                        </Grid>


                                        {/* <Grid item container style={{padding: '0 2%'}}>
                                        <Grid item xs={6} container justifyContent="flex-start">
                                            <Typography>
                                                ຈຳນວນວັດຖຸດິບທັງໝົດ
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} container justifyContent="flex-end">
                                            <Typography >
                                                0000
                                            </Typography>
                                        </Grid>
                                    </Grid> */}

                                        <br /><br />
                                        <Button
                                            fullWidth
                                            //style={{ backgroundColor: '', width: '100%' }}
                                            onClick={handleSubmit}
                                            className={classes.buttonColor}
                                        >
                                            ເບີກວັດຖຸດິບ
                                        </Button>
                                        <br /><br />
                                        <Button
                                            fullWidth
                                            //style={{ backgroundColor: '', width: '100%' }}
                                            onClick={handleCancel}
                                            className={classes.buttonColor2}
                                        >
                                            ຍົກເລີກ
                                        </Button>
                                    </Grid>
                                    : null
                                }
                            </Grid>
                        </Grid>

                    </Grid>
                </Container>
            </div>
        )
    } else {
        return (
            <div>
                <Redirect
                    to={{
                        pathname: "/admin/sign-in",
                    }}
                />
            </div>
        )
    }
}
