import { Typography } from '@material-ui/core'
import { Box } from '@mui/material'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

  root: {
    backgroundColor: theme.palette.background.light
  }

}));

export default function Footer(props) {
  const classes = useStyles();


  return (
    <Box className={classes.root} display='flex' justifyContent='center' sx={{ padding: '2% 5%' }}>
      <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography>{props.t('footer.1')}</Typography>
        <Typography>{props.t('footer.2')}</Typography>
        <Typography>{props.t('footer.3')}</Typography>
        <br/><br/>
        <Typography>{props.t('footer.4')}</Typography>
      </Box>
    </Box>
  )
}
