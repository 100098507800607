import React from 'react'
import { Box } from '@mui/material'

export default function Circle(props) {
    let Green = '#0C69CB'
    return (
        <Box sx={{ padding: '0 0', backgroundColor: '', width: '100%', display: 'flex', justifyContent: 'center'}}>
            <Box
                sx={{
                    borderRadius: '50%',
                    borderLeft: '40px solod transparent',
                    borderRight: '40px solod transparent',
                    borderBottom: '80px solod transparent',
                    border: '6px solid',
                    position: 'relative',
                    alignContent: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: Green,
                    height: { xl: '490px', lg: '445px', md: '350px', sm: '220px', xs: '220px' },
                    width: { xl: '100%', lg: '100%', md: '100%', sm: '100%', xs: '220px' },
                }}>

                <div style={{ color: Green, fontSize: '100px' }}>
                    {props.number}
                </div>
            </Box>
        </Box>
    )
}