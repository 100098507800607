import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Grid, TextField, Button, Typography, DialogContent, DialogActions, MenuItem } from '@material-ui/core';
import Axios from 'axios';

export default function DialogAddChannelDistrict(props) {
    //props.setDistrict
    const [channelDistrict, setChannelDistrict] = useState("");
    const [selectChannelProvinceID, setSelectChannelProvinceID] = useState("");
    const [selectChannelDistrictName, setSelectChannelDistrictName] = useState("");
    
    const handleClose = () => {
        props.setOpenDistrict(false);
    };
    const handleSelectChannelDistrict = (id, name) => {
        setSelectChannelProvinceID(id);
        setSelectChannelDistrictName(name)
    };
    const handleChannelDistrict = (e) => {
        setChannelDistrict(e.target.value);
    };
    const Submit = () => {
        const options = {
            url: window.$api + "/addChannelDistrict",
            method: "POST",
            headers: {
                "Accept": "application/json",
                ////"content-Type": "application/json;charset-UTF-8"
            },
            data: {
                "channelDistrict": channelDistrict,
                "selectChannelProvinceID" : selectChannelProvinceID
            },
        };
        Axios(options).then(Response => {
            alert(Response.data.mesg);
            props.setDistrict(Response.data.district)
            setChannelDistrict("")
            setSelectChannelDistrictName("")
            setSelectChannelProvinceID("")
            //console.log(Response.data)
        });
    };

    return (
        <Dialog
            maxWidth='xl'
            open={props.openDistrict}
        >
            <DialogContent>
                <Grid container spacing={2} >
                    <Grid item xs={12} >
                        <Typography>
                            ເພີ່ມເມືອງ
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="ຊື່ແຂວງ"
                            variant="outlined"
                            //onChange={handleChannelDistrict}
                            fullWidth
                            select
                            value={selectChannelDistrictName || ""}
                        >
                            {props.province.map((row, index) => (
                                <MenuItem
                                    key={index}
                                    value={row.province || ""}
                                    onClick={() => handleSelectChannelDistrict(row.id, row.province)}
                                >
                                    {row.province}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="ຊື່ເມືອງ"
                            variant="outlined"
                            onChange={handleChannelDistrict}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { Submit(); handleClose() }}>
                    ບັນທຶກ
                </Button>
                <Button onClick={handleClose}>ຍົກເລີກ</Button>
            </DialogActions>
        </Dialog>
    )
}