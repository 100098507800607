import React from 'react'
import { NavLink, useHistory } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { AppBar, Toolbar, ListItemText, IconButton, Drawer, ListItemIcon, List, Divider, ListItem, Button, Collapse, Tooltip, Icon, Box } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
//import SimCardIcon from '@material-ui/icons/SimCard';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
//import { Loyalty, Storage, Report, Settings, Home, HomeWork } from '@material-ui/icons';
import { Loyalty, Report, Settings, Home, HomeWork } from '@material-ui/icons';
//import AllInboxIcon from '@material-ui/icons/AllInbox';
//import Logo from '../../Img/BestMart_web_banner_logo.png'
const useStyles = makeStyles((theme) => ({
    contain: {
        backgroundColor: theme.palette.background.dark,
    },
    menuColor: {
        color: theme.palette.primary.light
    },
    menuListColor: {
        color: theme.palette.primary.dark
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    menuButton: {
        flexGrow: 1,
    },
    icon: {
        flexGrow: 0.012,
    },
    icon2: {
        flexGrow: 0.002,
    },
    iconMenu: {
        color: theme.palette.background.light
    },
    drawerPaper: {
        backgroundColor: theme.palette.primary.light,
        //width: '21%',
        [theme.breakpoints.down('lg')]: {
            display: 'block',
            width: '21%',
        },
        [theme.breakpoints.down('xl')]: {
            display: 'block',
            width: '21%',
        },
        [theme.breakpoints.down('md')]: {
            display: 'block',
            width: '30%',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            width: '40%',
        },
        [theme.breakpoints.down('xs')]: {
            display: 'block',
            width: '60%',
        },
    },
    drawerHeader: {
        backgroundColor: theme.palette.background.dark,
        height: '9.3%'
    },
    iconArrow: {
        color: theme.palette.background.light,
        marginTop: '6%'
    },
    iconLogout: {
        color: theme.palette.secondary.light
    }
}));

export default function Appbar(props) {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory()
    const [open, setOpen] = React.useState(false);
    const [menu, setmenu] = React.useState([
        {
            id: 1, name: "ການຂາຍ", icon: <Loyalty />, link: '', collapsed: false, hasCollapse: true, colList: [
                { id: 1, name: "ຂາຍໜ້າໂຮງງານ", link: "/admin/sale" },
                { id: 2, name: "ສ້າງຄໍາສັ່ງຊື້", link: "/admin/create-order" },
                { id: 3, name: "ຈັດການຄໍາສັ່ງຊື້", link: "/admin/order" },
                { id: 4, name: "ເບີກສິນຄ້າອອກຂາຍ", link: "/admin/product-out" },
                { id: 5, name: "ຄືນສິນຄ້າ", link: "/admin/return-product" },
            ]
        },

        {
            id: 2, name: "ສາງວັດຖຸດິບ", icon: <HomeWork />, link: '', collapsed: false, hasCollapse: true, colList: [
                // { id: 1, name: "ຂໍ້ມູນສ້າງ", link: "/admin/stock" },
                { id: 1, name: "ຈັດການຜູ້ສະໜອງ", link: "/admin/add-business-partner" },
                { id: 2, name: "ຈັດການວັດຖຸດິບ", link: "/admin/material" },
                { id: 3, name: "ວັດຖຸດິບເຂົ້າສາງ", link: "/admin/import-product-material" },
                { id: 4, name: "ວັດຖຸດິບອອກສາງ", link: "/admin/material2" },
                { id: 5, name: "ພິມບາໂຄດ", link: "/admin/create-barcode" }
            ]
        },
        {
            id: 3, name: "ສາງສິນຄ້າ", icon: <HomeWork />, link: '', collapsed: false, hasCollapse: true, colList: [
                // { id: 1, name: "ຂໍ້ມູນສ້າງ", link: "/admin/stock" },

                { id: 1, name: "ສິນຄ້າເຂົ້າສາງ", link: "/admin/import-product-product" },
                { id: 2, name: "ສິນຄ້າອອກສາງ", link: "/admin/product-out" },
                { id: 3, name: "ຄືນສິນຄ້າ", link: "/admin/return-product" },
                { id: 4, name: "ຈັດການສິນຄ້າ", link: "/admin/product" },
                { id: 5, name: "ພິມບາໂຄດ", link: "/admin/create-barcode" },
            ]
        },
        {
            id: 4, name: "ຕັ້ງຄ່າ", icon: <Settings />, link: '', collapsed: false, hasCollapse: true, colList: [
                { id: 1, name: "ຜູ້ໃຊ້ລະບົບ", link: "/admin/user" },
                { id: 2, name: "ຈັດການປະເພດສິນຄ້າ", link: "/admin/product" },
                { id: 3, name: "ຈັດການໂຄງສ້າງລາຄາ", link: "/admin" },
                { id: 4, name: "ຈັດການລູກຄ້າ-ຕົວແທນ-ສາຂາ", link: "/admin/retailer" },
                { id: 5, name: "ຈັດການໂປໂມຊັ້ນ", link: "/admin/promotion" },
                { id: 6, name: "ຕັ້ງຄ່າແບນເນີ", link: "/admin" },
            ]
        },

        {
            id: 5, name: "ລາຍງານ", icon: <Report />, link: '', collapsed: false, hasCollapse: true, colList: [
                { id: 1, name: "ລາຍງານການຂາຍ", link: "/admin/report-product" },
                { id: 2, name: "ລາຍງານລາຍຈ່າຍວັດຖຸດິບ", link: "/admin/product-in-report" },
                { id: 3, name: "ປະຫວັດວັດຖຸດິບເຂົ້າສາງ", link: "/admin/product-in-report" },
                { id: 4, name: "ປະຫວັດສິນຄ້າເຂົ້າສາງ", link: "/admin/product-in-report" },
                { id: 5, name: "ປະຫວັດວັດຖຸດິບອອກສາງ", link: "/admin/product-in-report" },
                { id: 6, name: "ປະຫວັດສິນຄ້າອອກສາງ", link: "/admin/product-in-report" },
                { id: 7, name: "ລາຍງານຈຳນວນສິນຄ້າ", link: "/admin/report-rawmaterial" },
                { id: 8, name: "ລາຍງານຈຳນວນວັດຖຸດິບ", link: "/admin/report-rawmaterial" },
                { id: 9, name: "ລາຍງານສິນຄ້າເສຍຫາຍ", link: "/admin/broken-product-report" },
                { id: 10, name: "ລາຍງານສະຕ໋ອກລູກຄ້າ", link: "/admin/report-detailer" },
            ]
        },
    ])

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleClickMenu = (link, index) => {
        if (link !== '') {
            history.push(link)
            setOpen(false)
        } else {
            var arr = [...menu]
            arr[index].collapsed = !arr[index].collapsed
            setmenu(arr)
        }
    }

    const GoDashboard = () => {
        history.push("/admin")
        setOpen(false)
    }

    return (
        <div>
            <AppBar position="static" className={classes.contain}>
                <Toolbar>
                    <a href='/admin' style={{ fontSize: "30px", fontWeight: "bold", cursor: "pointer", textDecoration: "none", color: "white" }}>
                        {/* <img src={Logo} alt='' style={{width: '50%', marginTop: '4%'}}/> */}One Water
                    </a>
                    <div className={classes.menuButton}></div>
                    <div className={classes.icon2}></div>
                    <div className={classes.icon}>{props.userInfo.username}</div>
                    <div>
                        <Tooltip title="ເມນູ">
                            <IconButton edge="start" onClick={handleDrawerOpen} color="inherit" aria-label="menu">
                                <MenuIcon className={classes.iconMenu} />
                            </IconButton>
                        </Tooltip>
                    </div>
                </Toolbar>
                <Drawer
                    className={classes.drawer}
                    variant="persistent"
                    anchor="right"
                    open={open}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <div className={classes.drawerHeader}>
                        <IconButton onClick={handleDrawerClose} className={classes.iconArrow}>
                            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>
                    </div>
                    <Divider />

                    <List>

                        <ListItem style={{ width: '100%' }}>
                            <Button size="small" fullWidth style={{ width: '100%', backgroundColor: '#6F7992', }} onClick={GoDashboard}>
                                <ListItemIcon>
                                    <Home className={classes.menuColor} />
                                </ListItemIcon>
                                <ListItemText primary="ໜ້າຫຼັກ/ລາຍງານສັງລວມ" style={{ marginRight: '37%' }} className={classes.menuColor} />
                            </Button>
                        </ListItem>

                        {menu.map((row, index) =>
                            <Box key={row.id}>
                                <ListItem style={{ width: '100%' }}>
                                    <Button size="small" fullWidth style={{ width: '100%', backgroundColor: '#6F7992', }} onClick={() => handleClickMenu(row.link, index)}>
                                        <ListItemIcon>
                                            <Icon className={classes.menuColor}>
                                                {row.icon}
                                            </Icon>
                                        </ListItemIcon>
                                        <ListItemText primary={row.name} style={{ marginRight: '33%' }} className={classes.menuColor} />
                                        {row.hasCollapse && (row.collapsed ? <ExpandLess className={classes.menuColor} /> : <ExpandMore className={classes.menuColor} />)}
                                    </Button>
                                </ListItem>
                                <Collapse in={row.collapsed} timeout="auto" unmountOnExit style={{ marginLeft: 20 }}>
                                    <List component="div" disablePadding>
                                        {row.colList ? (
                                            row.colList.map((col) =>
                                                <ListItem key={col.id}>
                                                    <NavLink to={col.link} style={{ color: "#fff", textDecoration: "none" }} onClick={handleDrawerClose}>
                                                        <Button>
                                                            <ListItemText primary={col.name} className={classes.menuListColor} />
                                                        </Button>
                                                    </NavLink>
                                                </ListItem>
                                            )
                                        ) : null}
                                    </List>
                                </Collapse>
                            </Box>

                        )}

                        <ListItem style={{ width: '100%' }}>
                            <Button size="small" fullWidth style={{ width: '100%', backgroundColor: '#6F7992', }} onClick={props.handleLogout}>
                                <ListItemIcon>
                                    <ExitToAppIcon className={classes.iconLogout} />
                                </ListItemIcon>
                                <ListItemText primary="ອອກຈາກລະບົບ" style={{ marginRight: '37%' }} className={classes.iconLogout} />
                            </Button>
                        </ListItem>
                    </List>
                    <Divider />
                </Drawer>
            </AppBar>
        </div>
    )
}