import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
// import DeleteIcon from '@material-ui/icons/Delete';
// import { IconButton } from '@material-ui/core';
// import NumberFormat from 'react-number-format'

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.background.dark,
        color: theme.palette.background.default,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);


const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});

export default function TableReport(props) {
    const classes = useStyles();

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
                <TableHead>
                    <TableRow >
                        <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ລຳດັບ</StyledTableCell>
                        <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ບາໂຄດ</StyledTableCell>
                        <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ຊື່ສິນຄ້າ</StyledTableCell>
                        <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ຈຳນວນເສຍຫາຍ</StyledTableCell>
                        <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ເລກບິນ</StyledTableCell>
                        <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ວັນທີ</StyledTableCell>
                        <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ເວລາ</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.dataList.map((row, index) => (
                        <StyledTableRow key={row.id}>
                            <StyledTableCell align="center">{index + 1}</StyledTableCell>
                            <StyledTableCell align="center">{row.product_barcode}</StyledTableCell>
                            <StyledTableCell align="center">{row.product_name}</StyledTableCell>
                            <StyledTableCell align="center">{row.qty}</StyledTableCell>
                            <StyledTableCell align="center">{row.billcode}</StyledTableCell>
                            <StyledTableCell align="center">{row.date}</StyledTableCell>
                            <StyledTableCell align="center">{row.time}</StyledTableCell>
                            {/* <StyledTableCell align="center">
                                <IconButton>
                                    <DeleteIcon color="error" style={{ cursor: 'pointer' }} />
                                </IconButton>
                            </StyledTableCell> */}
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

//TableOrders