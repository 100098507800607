import React from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@material-ui/core/styles';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.background.dark,
        color: theme.palette.background.default,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

export default function SaleReportDiag(props) {
    return (
        <div>
            <Dialog
                open={props.open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='xl'
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">
                    ລາຍງານການຂາຍວັນທີ {props.date}
                </DialogTitle>
                <DialogContent>
                    <TableContainer component={Paper}>
                        <Table size='medium' aria-label="customized table">
                            <TableHead>
                                <TableRow >
                                    <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ລຳດັບ</StyledTableCell>
                                    <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ຊື່ສິນຄ້າ</StyledTableCell>
                                    <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ຈຳນວນ</StyledTableCell>
                                    <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ລາຄາ</StyledTableCell>
                                    <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ລວມ</StyledTableCell>
                                    <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ກຳໄລ</StyledTableCell>
                                    <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ເລກບິນ</StyledTableCell>
                                    <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>ເວລາ</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.saleReportList.map((row, index) =>
                                    <StyledTableRow key={row.id}>
                                        <StyledTableCell align="center" component="th" scope="row">
                                            {index + 1}
                                        </StyledTableCell>
                                        <StyledTableCell align="center" component="th" scope="row">
                                            {row.product_name}
                                        </StyledTableCell>
                                        <StyledTableCell align="center" component="th" scope="row">
                                            {row.quantity}
                                        </StyledTableCell>
                                        <StyledTableCell align="center" component="th" scope="row">
                                            {row.price}
                                        </StyledTableCell>
                                        <StyledTableCell align="center" component="th" scope="row">
                                            {row.total}
                                        </StyledTableCell>
                                        <StyledTableCell align="center" component="th" scope="row">
                                            {row.profit}
                                        </StyledTableCell>
                                        <StyledTableCell align="center" component="th" scope="row">
                                            {row.billcode}
                                        </StyledTableCell>
                                        <StyledTableCell align="center" component="th" scope="row">
                                            {row.time}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose} autoFocus>
                        ປິດ
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
