import React, {useState, useEffect} from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Axios from 'axios'

const columns = [
  { id: 'no', label: 'ລຳດັບ', minWidth: 100 },
  { id: 'barcode', label: 'ບາໂຄດ', minWidth: 120 },
  { id: 'name', label: 'ຊື່ສິນຄ້າ', minWidth: 120 },
  { id: 'select', label: '', minWidth: 120},

];

export default function PrintBarcodeTable(props) {
  const [data, setData] = useState([]);

  const hasBarcode = (arr, barcode) => arr.some(el => el.barcode === barcode);

  const handleSelect = (e,f,g) => {
    if(hasBarcode(props.tableData, e) === true){
      let newArr = props.tableData.map((obj) => {
        if (obj.barcode && obj.barcode === e) {
          obj.qty = obj.qty + 1;
        }
        return obj;                           
      })
      props.setTableData(newArr);
      props.setBarcode("");
    }else{
      let newArray = [...props.tableData];
      var tempdata = {
        id: props.id,
        barcode: e,
        name: f,
        price: g,
        qty: 1,
      }
      newArray.push(tempdata);
      props.setTableData(newArray);
      props.setId(props.id + 1);
    }
  }

  useEffect(()=> {
    Axios
      .get(window.$api + "/selectspecialbarcode")
      .then((res) => setData(res.data));
  }, []);

  return (
    <div>
      <Paper>
        <TableContainer style={{maxHeight: "60vh", borderRadius: 5}}>
          <Table aria-label="customized table" stickyHeader>
            <TableHead >
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, color: "white", backgroundColor: "#15023a" }}
                  >
                    {column.label}
                  </TableCell>  
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row =>
                <TableRow key={row.id}>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{row.product_barcode}</TableCell>
                  <TableCell>{row.product_name}</TableCell>
                  <TableCell>
                    <Button color="primary" onClick={()=> handleSelect(row.product_barcode, row.product_name, row.price_kip)}>
                      ເລືອກ
                    </Button>   
                  </TableCell>                                        
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>                        
      </Paper>
    </div>
  )
}
