import React, {useState} from 'react';
//import Paper from '@material-ui/core/Paper';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import NumberFormat from 'react-number-format';
import EditIcon from '@material-ui/icons/Edit';
import Axios from 'axios'

const columns = [
  { id: 'no', label: 'ລຳດັບ', minWidth: 10 },    
  { id: 'barcode', label: 'ບາໂຄດ', minWidth: 120 },
  { id: 'name', label: 'ຊື່ສິນຄ້າ', minWidth: 120 },
  { id: 'productqty', label: 'ຈ/ນ', minWidth: 50},
  { id: 'price', label: 'ລາຄາ', minWidth: 100},
  { id: 'total',label: 'ລວມ', minWidth: 100},
  //{ id: 'billcode',label: 'ເລກທີບິນ', minWidth: 100},
  { id: 'edit', label: 'ແກ້ໄຂ', minWidth: 140},
];

export default function StickyHeadTable(props) {
  const [isEditable, setEditable] = useState();
  const [newQty, setNewQty] = useState();

  const handleNewQty = (e) =>{
      setNewQty(e.target.value);
  }

  const handleEdit = (id) => {
      setEditable(id);
  }

  const handleInput = (e, f, g) =>{
      if(e.key === 'Enter'){
        const options = {
          url: window.$api + "/getRawmaterialInfo"   ,
          method: "POST",
          headers:{
            "Accept": "application/json",
            "content-Type": "application/json;charset-UTF-8"
          },             
          data:{
              "barcode": g,
          },
        }
        Axios(options).then(Response =>{
          let x = Number(Response.data.material.cost);
          let z = Number(Response.data.material.quantity);
          let y = Number(Response.data.material.price_kip);
          if(z){
            let newArr = props.tableData.map((obj) => {
              if (obj.id === f) {
                if(Number(newQty) <= z){
                  obj.qty = Number(newQty);
                  obj.total = y * obj.qty;
                  obj.profit = obj.total - (obj.qty * x);
                  props.setQty(obj.qty);
                }else{
                  alert('ສິນຄ້າບໍ່ພໍ');
                }
              }
              return obj;                           
            })
            props.setTableData(newArr);
            setEditable("");
            props.setPrice(y);
            props.setName(Response.data.material.product_name);
            setNewQty("");
            props.setAllQty(props.tableData.reduce((prev,next) => prev + next.qty, 0))
            props.setTotal(props.tableData.reduce((prev,next) => prev + next.total, 0));
          }
        });
      }
    }

  const handleDelete = (e, f, g) => {
    const del = props.tableData.filter(update => update.id !== e);
    props.setTableData(del);
    props.setTotal(props.total - f);
    props.setName("");
    props.setQty(0);
    props.setPrice(0);
    props.setAllQty(Number(props.allQty) - Number(g));
  }
  
  return (
    <div>
      <TableContainer style={{maxHeight: 600, borderRadius: 5,}}>
        <Table stickyHeader >
          <TableHead>
            <TableRow >
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align="center"
                  style={{ minWidth: column.minWidth, color: "white", backgroundColor: "#15023a"}}
                >
                  {column.label}
                </TableCell>  
              ))}
            </TableRow>
          </TableHead>
          <TableBody style={{backgroundColor: "white"}}>
            {props.tableData.map((row ,index) => 
              <TableRow key={row.id} >
                <TableCell align="center">{index + 1}</TableCell>
                <TableCell align="center">{row.barcode}</TableCell>
                <TableCell align="center">{row.name}</TableCell>
                { isEditable === row.id? 
                  <TableCell align="center">
                    {/* <TextField 
                      id="outlined-basic" 
                      size="small" 
                      variant="outlined" 
                      onChange={handleNewQty}
                      onKeyPress={(e)=> {handleInput(e, row.id, row.barcode)}}
                    /> */}
                    <FormControl variant="outlined">
                      <InputLabel htmlFor="outlined-adornment-password">ຈຳນວນ</InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        onChange={handleNewQty}
                        onKeyPress={(e)=> {handleInput(e, row.id, row.barcode)}}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              edge="end"
                              onClick={()=> setEditable("")}
                            >
                              <CancelIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                        labelWidth={70}
                      />
                    </FormControl>
                  </TableCell> 
                  :
                  <TableCell align="center">{row.qty}</TableCell> 
                }
                <TableCell align="center"><NumberFormat value={row.price} displayType={'text'} thousandSeparator={true} /></TableCell>
                <TableCell align="center"><NumberFormat value={row.total} displayType={'text'} thousandSeparator={true} /></TableCell>
                {/* <TableCell align="center">{row.billcode}</TableCell> */}
                <TableCell align="center">
                  <Button onClick={()=> handleEdit(row.id)}>
                    <EditIcon/>
                  </Button>
                  <Button onClick={()=> handleDelete(row.id, row.total, row.qty)}>
                    <DeleteIcon/>
                  </Button>
                </TableCell>                                          
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>     
    </div>
  );
}
//billcode