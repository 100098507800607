import { Grid, TextField, Typography } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { Box } from '@mui/material'
import TableExpMaterial from 'Bt_modules/Tables/TableExpMaterial'
import AddIcon from '@material-ui/icons/Add';
import Axios from 'axios';
import { Redirect } from "react-router-dom";
//import NumberFormat from 'react-number-format';

export default function ExportedRawMaterial(props) {

  const [material, setMaterial] = useState([])
  const [barcode, setBarcode] = useState()
  const [typedText, settypedText] = useState("")
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")

  useEffect(() => {
    let isMounted = true;
    const options = {
      url: window.$api + "/getExportMaterial",
      method: "GET",
      withCredentials: true,
      headers: {
        "Accept": "application/json",
        //"content-Type": "application/json;charset-UTF-8"
      },
      // data: {
      //     token: props.accessToken
      // }
    };
    Axios(options).then(Response => {
      if (isMounted) {
        setMaterial(Response.data)
      }
    });
    return () => { isMounted = false };
  }, []);
  //|| startdate && endDate
  const handleSearch = (e) => {
    if (barcode || typedText || startDate || endDate) {
      const options = {
        url: window.$api + "/filterSearch",
        method: "POST",
        headers: {
          "Accept": "application/json",
          //"content-Type": "application/json;charset-UTF-8"
        },
        data: {
          barcode: barcode || null,
          key: typedText || null,
          startdate: startDate || null,
          enddate: endDate || null,
        }
      };
      Axios(options).then(res => {
        if (res.data) {
          setMaterial(res.data)
        }
      })
    } else {
      alert('Empty field')
    }

  }

  const handleBarcode = (e) => {
    setBarcode(e.target.value)
  }
  const handleTypeOnSearch = (e) => {
    settypedText(e.target.value)
  }
  const handleSelectStartDate = (e) => {
    setStartDate(e.target.value)
  }
  const handleSelectEndDate = (e) => {
    setEndDate(e.target.value)
  }

  if (props.authenticated) {
    return (
      <div style={{ padding: '1% 0.5%' }}>
        <Grid container spacing={2} >
          <Grid item xl={2} lg={2} md={3} sm={3} xs={12} style={{ backgroundColor: '', }}>
            <Box sx={{ display: { xl: 'flex', lg: 'flex', md: 'flex', sm: 'flex', xs: 'flex' }, padding: { xl: '6% 0', lg: '5% 0', md: '4% 0', sm: '3% 0', xs: '' } }}>
              <Typography>ວັດຖຸດິບ-ນຳອອກ</Typography>
            </Box>
          </Grid>
          <Grid item xl={5} lg={5} md={7} sm={7} xs={12} style={{ backgroundColor: '', }}>
            <Box sx={{ display: { xl: 'flex', lg: 'flex', md: 'flex', sm: 'flex', xs: 'flex' } }}>
              <TextField
                id="add-new-code"
                //select
                label="ລະຫັດບາໂຄດ"
                variant="outlined"
                fullWidth
                onChange={handleBarcode}
                value={barcode || ""}
              />
              &nbsp;&nbsp;&nbsp;
              <TextField
                id="search"
                //select
                label="ຄົ້ນຫາ"
                variant="outlined"
                fullWidth
                onChange={handleTypeOnSearch}
                value={typedText || ""}
              />
              &nbsp;&nbsp;&nbsp;
              <TextField
                id="add-new-code"

                label="ວັນທີເລີ່ມຕົ້ນ"
                variant="outlined"
                fullWidth
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleSelectStartDate}
                value={startDate || ""}
              />
              &nbsp;&nbsp;&nbsp;
              <TextField
                id="search"
                label="ວັນທີສິ້ນສຸດ"
                variant="outlined"
                fullWidth
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleSelectEndDate}
                value={endDate || ""}
              />
              &nbsp;&nbsp;&nbsp;
              <button onClick={handleSearch}>Search</button>
            </Box>
          </Grid>
          <Grid item xl={5} lg={5} md={2} sm={2} xs={12} style={{ backgroundColor: '', }}>
            <Box
              sx={{
                display: { xl: 'flex', lg: 'flex', md: 'flex', sm: 'flex', xs: 'flex' },
                justifyContent: { xl: 'flex-end', lg: 'flex-end', md: 'flex-end', sm: '', xs: '' },
              }}
            >
              <Box
                sx={{
                  display: { xl: 'flex', lg: 'flex', md: 'flex', sm: 'flex', xs: 'flex' },
                  justifyContent: { xl: 'space-around', lg: 'flex-end', md: 'flex-end', sm: '', xs: '' },
                  padding: { xl: '1.8% 8%', lg: '2.2% 8%', md: '2.5% 6%', sm: '4% 6%', xs: '1.3% 6%' },
                  backgroundColor: '#57D65B',
                  borderRadius: 3,
                  color: '#FFFFFF',
                  cursor: 'pointer',
                  ":active": {
                    backgroundColor: '#BDBBB9',
                  },
                }}>
                <AddIcon />
                <Typography style={{ fontSize: '17px', fontWeight: 'bold' }}>

                  ເພີ່ມໃໝ່
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <br />
        <TableExpMaterial material={material} />
      </div>
    )
  } else {
    return (
      <div>
        <Redirect
          to={{
            pathname: "/admin/sign-in",
          }}
        />
      </div>
    )
  }
}
